import events from '@guuru/events-web';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { useDetectIframe } from '@guuru/chat-web';

const Button = styled.button`
  cursor: pointer;
  font-size: 1.6em;
  border: none;
  background: none;
  color: ${({ theme }) => theme.onPrimaryColor};
  line-height: 1;
  padding: 0;
  margin: 0;
  position: relative;
  right: 0.5rem;

  @media (min-device-width: 371px) and (width: 370px) {
    display: none;
  }
`;

const CloseButton = function () {
  const inIframe = useDetectIframe();

  return inIframe && (
    <Button onClick={() => events.closeChat()}>
      <CloseOutlined />
    </Button>
  );
};

export default CloseButton;
