import React from 'react';
import PropTypes from 'prop-types';
import { Rate, Tooltip } from 'antd';

const Rating = function ({
  rating = 1,
  numeric = false,
  hoverable = true,
}) {
  const roundedRating = Number(rating).toFixed(1);

  if (numeric) {
    return <span>{roundedRating}</span>;
  }

  const rate = <Rate defaultValue={rating} allowHalf disabled />;

  return hoverable
    ? <Tooltip title={`Rating: ${roundedRating}`}>{rate}</Tooltip>
    : rate;
};

Rating.propTypes = {
  rating: PropTypes.number,
  numeric: PropTypes.bool,
  hoverable: PropTypes.bool,
};

export default Rating;
