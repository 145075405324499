import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form as AntForm, Row } from 'antd';
import events from '@guuru/events-web';
import { ChatAPI } from '@guuru/api-web';
import {
  ChatMessageBar,
  TermsAndConditions,
  Widget,
} from '@guuru/react-chatwidget';
import {
  createMessageForPresentation,
  flow as flowConstants,
  useChatSettings,
  useDetectIframe,
  useLocalChat,
} from '@guuru/chat-web';
import LoadingView from '@guuru/react-loading';
import { ChatRowWrapper } from '@guuru/react-chat-messages';
import { ThemeContext } from 'styled-components';
import { usePageTitle } from '@guuru/react-hooks';
import ChatAlert from '../../components/ChatAlert';
import * as chatAction from '../../actions/chatActions';
import useChatWrapper from '../../hooks/useChatWrapper';
import ChatConnectionRetryCta from '../chat/ChatConnectionRetryCta';
import { BottomBar, Welcome, WelcomeText } from './style';
import QuestionForm from './QuestionForm';
import useIsMobile from '../../hooks/useIsMobile';
import ExpanderButton from './ExpanderButton';

const {
  STEP_QUESTION,
  STEP_EMAIL,
  STEP_NAME,
  STEP_RATE,
  STEP_RATE_BAD,
  STEP_FINISHED,
  STEP_CONNECTING_TO_EXPERT,
  STEP_CHAT,
  INPUT_TYPE_QUESTION,
  INPUT_TYPE_NAME,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_CHAT,
  INPUT_TYPE_NONE,
  INPUT_TYPE_RESTART,
} = flowConstants;

const Form = function () {
  usePageTitle('Form - Guuru');
  const { t } = useTranslation();
  const { chatAlignment, isFullScreen } = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { partnerId, chatControlToken, chatId: chatIdParam } = useParams();
  const [antForm] = AntForm.useForm();
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const chatClosed = useRef(null);
  const [question, setQuestion] = useState(undefined);
  const { isMobile } = useIsMobile();
  const chat = useSelector((state) => state.chatReducer);
  const { currentChatId: chatId, hasCurrentChat, ...flow } = useLocalChat();
  const smartFormQuestion = ChatAPI.storeRetrieveQuestion(partnerId);
  const inIframe = useDetectIframe();

  const {
    loading: isLoadingSettings,
    data: {
      chatSettings: {
        chatWidgetCustomBotAvatar,
        chatWidgetCustomBotAvatarPhotoUrl,
        chatWidgetShowPoweredBy,
        isTokenValid: customTaskType,
      } = {},
    } = {},
  } = useChatSettings();

  useEffect(() => {
    if (!didMount.current) {
      dispatch(
        chatAction.chatInitAction(
          partnerId,
          chatIdParam,
          chatControlToken,
          navigate,
          location,
          { channel: 'form', payloadQuestion: question },
        ),
      );
      didMount.current = true;
    }
  }, [
    dispatch,
    navigate,
    question,
    location,
    chatControlToken,
    chatIdParam,
    partnerId,
  ]);

  const handleConnectionError = () => {
    dispatch(chatAction.chatConnectionError(partnerId));
  };

  useChatWrapper();

  const reduxMessages = useMemo(() => chat.messages?.map(
    // if it has typename we know that this msg comes from gql
    // so no need to convert it
    // eslint-disable-next-line no-underscore-dangle
    (m) => (m.__typename ? m : createMessageForPresentation(m)),
  ), [chat]);

  const displayPoweredBy = useMemo(() => {
    const stepsWherePoweredByIsVisible = [
      STEP_QUESTION,
      STEP_EMAIL,
      STEP_NAME,
    ];

    return chatWidgetShowPoweredBy
      && stepsWherePoweredByIsVisible.includes(flow.step);
  }, [chatWidgetShowPoweredBy, flow.step]);

  const handleAskNewQuestion = useCallback(() => {
    setQuestion(false);
    antForm.setFieldsValue({ question: undefined });

    events.userReloadChat();
    dispatch(
      chatAction.chatNewQuestion(
        partnerId,
        navigate,
        location,
        { channel: 'form' },
      ),
    );
  }, [
    antForm, dispatch, location, navigate, partnerId,
  ]);

  const handleSubmit = useCallback(async (eventLabel, text, type) => {
    if (text.trim().length > 0) {
      events.submit(eventLabel);
      dispatch(
        chatAction.chatNewText(
          partnerId,
          chatId,
          {
            text,
            step: flow.step,
            inputType: flow.inputType,
            textType: type || 'text',
            channel: 'smartForm',
            skipAutomation: customTaskType === 'onboarding',
          },
        ),
      );
    }
  }, [
    chatId,
    dispatch,
    flow.inputType,
    flow.step,
    partnerId,
    customTaskType,
  ]);

  const renderChatMessageBar = useCallback(({ onAttachmentSubmit }) => {
    const isInputTypeNone = (
      [INPUT_TYPE_QUESTION, INPUT_TYPE_NONE].includes(flow.inputType)
    );
    if (isInputTypeNone
      || (flow.isRunningStep && flow.step === flowConstants.STEP_AUTOMATION)
      || (!flow.inputType || !flow.step)
    ) {
      return null;
    }

    const typeAllowed = [
      INPUT_TYPE_NAME,
      INPUT_TYPE_EMAIL,
      INPUT_TYPE_CHAT,
    ].includes(flow.inputType);
    return (
      <BottomBar
        button={!typeAllowed}
        $transparent={flow.inputType === INPUT_TYPE_RESTART}
      >
        <ChatMessageBar
          isUser
          noFocus={isMobile}
          chatId={chatId}
          partnerId={partnerId}
          inputType={flow.inputType}
          onSubmit={handleSubmit}
          onAttachmentSubmit={onAttachmentSubmit}
          isClosed={flow.step === STEP_RATE}
          isReviewingRate={flow.step === STEP_RATE_BAD}
          isFinished={flow.inputType === INPUT_TYPE_RESTART
            && !flow.isRunningStep}
          onAskNewQuestion={handleAskNewQuestion}
        />
      </BottomBar>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flow, chatId, partnerId, handleAskNewQuestion, handleSubmit,
  ]);

  const onSubmitQuestion = useCallback((q) => {
    setQuestion(q);
    handleSubmit('chatInit', q);
  }, [setQuestion, handleSubmit]);

  const getMods = () => {
    let mods = 'mod--smartForm';
    if (displayPoweredBy) {
      mods = `${mods} mod--powered-by`;
    }
    if (isFullScreen) {
      mods = `${mods} mod--fullScreen`;
    }
    switch (flow.step) {
      case STEP_CHAT:
        mods = `${mods} mod--chat`;
        break;
      case STEP_RATE:
      case STEP_RATE_BAD:
        mods = `${mods} mod--rate`;
        break;
      case STEP_CONNECTING_TO_EXPERT:
        mods = `${mods} mod--connecting`;
        break;
      case STEP_FINISHED:
        mods = `${mods} mod--finished`;
        break;
      default:
        break;
    }
    return mods;
  };

  if (isLoadingSettings || chat.loading) {
    return <LoadingView />;
  }

  const renderChatHeader = () => (
    <>
      <Welcome>
        <Row justify={chatAlignment}>
          <WelcomeText>
            {t('chat:::welcomeSmartForm')}
            <ExpanderButton
              hidden={(
                (!question && !chatId && !isFullScreen)
                || !isMobile
                || !inIframe
              )}
            />
          </WelcomeText>
        </Row>
      </Welcome>
      <ChatAlert />
      <ChatRowWrapper>
        <Row justify={chatAlignment}>
          <TermsAndConditions visible />
        </Row>
        <QuestionForm
          form={antForm}
          question={smartFormQuestion}
          onSubmit={onSubmitQuestion}
          showSubmit={flow.step === STEP_QUESTION}
          isMobile={isMobile}
        />
      </ChatRowWrapper>
    </>
  );

  return (
    <Widget
      showTermsAndConditions={false}
      isUser
      chatId={chatId}
      reduxMessages={reduxMessages}
      flow={flow}
      chatClosedRef={chatClosed}
      showBotAvatar
      customBotAvatar={(chatWidgetCustomBotAvatar
        ? chatWidgetCustomBotAvatarPhotoUrl
        : ''
          )}
      baseMods={getMods()}
      renderChatMessageBar={renderChatMessageBar}
      renderChatHeader={renderChatHeader}
      dispatch={dispatch}
    >
      <ChatConnectionRetryCta
        visible={flow.isConnectionError}
        action={handleConnectionError}
      />
    </Widget>
  );
};

export default Form;
