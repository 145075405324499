import trim from 'lodash/trim';

const invitationTheme = ({ partnerFont, partnerColor }) => {
  const fontFamily = () => (
    trim(partnerFont) && partnerFont !== 'system-ui'
      ? 'PartnerFont'
      : undefined
  );

  return {
    fontFamily: fontFamily(),
    color: partnerColor,
    colorScheme: 'Clean',
  };
};

const invitationThemeConfig = {
  components: {
    Typography: {
      fontWeightStrong: 400,
      titleMarginTop: 0,
      titleMarginBottom: 0,
    },
    Layout: {
      bodyBg: '#fff',
      footerBg: '#fff',
      headerHeight: 'auto',
    },
  },
};

export {
  invitationTheme,
  invitationThemeConfig,
};
