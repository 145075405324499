import { marked } from 'marked';

export default function (content) {
  const contentWithNewLines = content.replaceAll('\\', '\\\n');
  return marked.parseInline(contentWithNewLines, {
    breaks: true,
    headerIds: false,
    headerPrefix: false,
    mangle: false,
  });
}
