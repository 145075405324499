import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ChatMessageBubble from '../../ChatMessageBubble';
import { LinkPreviewCard, LinkPreviewCover } from './style';

const ChatRenderLinkPreview = function ({ message }) {
  const { link } = message;
  const [hasError, setHasError] = useState(false);

  const orientation = useMemo(() => (
    link.imageWidth < 250 || link.imageWidth < link.imageHeight
      ? 'portrait'
      : 'landscape'
  ), [link]);

  return (
    <ChatMessageBubble borderless transparent>
      <LinkPreviewCard>
        <a href={link.url} target="_blank" rel="noreferrer">
          {link.image && !hasError && (
            <LinkPreviewCover orientation={orientation}>
              <img
                alt={link.title}
                src={link.image}
                onError={() => setHasError(true)}
              />
            </LinkPreviewCover>
          )}
          <div className="chat-message-link-preview-body bubble-background">
            <h3 className="chat-message-text-message">{link.title}</h3>
            {link.description && (
              <p className="chat-message-text-message">{link.description}</p>
            )}
          </div>
        </a>
      </LinkPreviewCard>
    </ChatMessageBubble>
  );
};

ChatRenderLinkPreview.propTypes = {
  message: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.string,
      imageWidth: PropTypes.number,
      imageHeight: PropTypes.number,
    }),
  }).isRequired,
};

export default ChatRenderLinkPreview;
