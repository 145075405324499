import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer as AntDrawer } from 'antd';
import styled from 'styled-components';
import { getBottomSheetVisible, setBottomSheetVisible } from '@guuru/chat-web';
import { useReactiveVar } from '@apollo/client';

const Drawer = styled(AntDrawer)`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .ant-drawer-header {
    border: none;
    padding: 16px;
  }
`;

const BottomSheet = function ({ children = null, ...rest }) {
  const isBottomSheetVisible = useReactiveVar(getBottomSheetVisible());

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) {
          setBottomSheetVisible(false);
        }
      },
    );
    observer.observe(document.body);
    return () => observer.disconnect();
  }, []);

  return (
    <Drawer
      {...rest}
      open={isBottomSheetVisible}
      onClose={() => setBottomSheetVisible(false)}
      destroyOnClose
      placement="bottom"
      height="87%"
    >
      {isBottomSheetVisible && children}
    </Drawer>
  );
};

BottomSheet.propTypes = {
  children: PropTypes.node,
};

export default BottomSheet;
