import styled from 'styled-components';
import React from 'react';
import { SendButtonOutlined } from '@guuru/react-icon';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocalChat } from '@guuru/chat-web';
import { ChatAPI } from '@guuru/api-web';
import { ExpertsPriority } from '@guuru/expert-common';
import { chatNewQuickQuestion } from '../../../actions/chatActions';

const Button = styled.button`
  animation: animate-question 0.6s ease-out 0s;
  background-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.onSecondaryColor};
  margin-top: 16px;
  border: 0;
  border-radius: 5px 5px 0;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.3rem;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
  box-shadow: rgba(0 0 0 / 10%) 0 4px 6px -1px, rgba(0 0 0 / 6%) 0 2px 4px -1px;
  text-align: left;

  @keyframes animate-question {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryVariants[7]};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const QuestionButton = function ({ question }) {
  const dispatch = useDispatch();
  const { step } = useLocalChat();
  const submitQuickQuestion = (text) => {
    ChatAPI.storeChatPriority(ExpertsPriority.guurusOnly.value);
    dispatch(chatNewQuickQuestion({ text, step }));
  };
  return (
    <ButtonWrapper>
      <Button onClick={() => submitQuickQuestion(question)}>
        {question}
        <SendButtonOutlined style={{ marginLeft: 10, fontSize: 16 }} />
      </Button>
    </ButtonWrapper>
  );
};

QuestionButton.propTypes = {
  question: PropTypes.string.isRequired,
};

export default QuestionButton;
