import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@guuru/translation-web';
import { SelectInput, TargetOption } from '../../../SelectInput';

const TransferTargets = function ({
  targets,
  onChange = () => {},
  setFieldsValue,
}) {
  return (
    <SelectInput
      labelInValue
      placeholder={i18n.t('chat:::selectTargetSmartForm')}
      onChange={(selected) => {
        setFieldsValue({ target: selected.value });
        onChange(selected.value);
      }}
    >
      {targets?.map((target) => (
        <TargetOption key={target.id} value={target.id}>
          {target.title}
        </TargetOption>
      ))}
    </SelectInput>
  );
};

TransferTargets.propTypes = {
  onChange: PropTypes.func,
  targets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

export default TransferTargets;
