import React from 'react';
import { Flex } from 'antd';
import { usePageTitle } from '@guuru/react-hooks';
import { useCommunitySettings } from '@guuru/community-web';
import AboutCommunity from '../../components/Invitation/Content/AboutCommunity';
import HowItWorks from '../../components/Invitation/Content/HowItWorks';
import HowToJoin from '../../components/Invitation/Content/HowToJoin';
import BestGuurus from '../../components/Invitation/Content/BestGuurus';

const Invitation = function () {
  const { partnerName } = useCommunitySettings();
  usePageTitle(`Invitation - ${partnerName}`);
  return (
    <Flex vertical gap={100}>
      <AboutCommunity />
      <HowItWorks />
      <HowToJoin />
      <BestGuurus />
    </Flex>
  );
};

export default Invitation;
