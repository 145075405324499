import { put, cancel } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import { ChatAPI } from '@guuru/api-web';
import { delay as sleep } from '@guuru/helpers-common';

import createChat from './createChat';
import transferChat from './transferChat';
import handleChatStatusChange from './handleChatStatusChange';

export default function* (partnerId, { handover, targetId } = {}) {
  const {
    chatId,
    chatControlToken,
    updatedChat,
  } = yield createChat(partnerId);

  if (!chatId) yield cancel();

  yield sleep(ChatAPI.getBotEllipsisMessageDelay());

  const { status } = yield transferChat(partnerId, chatId, {
    handover,
    targetId,
  });

  yield put({
    type: types.CHAT_READY,
    chatId,
    chatControlToken,
    messages: updatedChat?.messages?.edges,
  });
  handleChatStatusChange({ chatId, ...updatedChat, status });
}
