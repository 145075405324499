import { gql } from '@apollo/client';

export default gql`
  query GetChatTransferInfo($chatId: ID!) {
    chat(id: $chatId) {
      id
      transfer {
        id
        confirmedAt
      }
    }
  }
`;
