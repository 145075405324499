const InteractionType = {
  Bot: {
    description: 'Interactions where the last assigned expert is the bot, which is whenever the bot is involved and the interaction does not go to a Guuru or Agent afterwards',
    extra: 'This includes Multi Intent and SmartRouting to Transfer cases',
  },
  Guuru: {
    description: 'Interactions where last assigned expert is a Guuru except the ones counting into Transferred Guuru Interactions',
  },
  'Guuru Transferred': {
    description: 'Interactions successfully transferred by Guurus',
    extra: 'This number will be lower than successfully sent Guuru Transfers reported in other views, as this number does not include transfers where the confirmation of the transfer takes place after the rating',
  },
  Agent: {
    description: 'Interactions where last assigned expert is an agent',
  },
  'Good Guuru': {
    description: 'Interactions where last assigned expert is a Guuru, with a good rating, except the ones counting into Guuru Transferred Interactions',
  },
  'Partial Guuru': {
    description: 'Interactions where last assigned expert is a Guuru, with a partial rating, except the ones counting into Guuru Transferred Interactions',
  },
  'Bad Guuru': {
    description: 'Interactions where last assigned expert is a Guuru, with a bad rating, except the ones counting into Guuru Transferred Interactions',
  },
  Missed: {
    description: 'Total missed interactions. (Not charged)',
  },
  Handover: {
    description: 'Total interactions transferred by the system after waiting for Agents. Total interactions transferred by the system in case routing to transfer is the default setting and we are not able to recognize the intent. (Not charged)',
  },
  Waiting: {
    description: 'Recent Interactions that are not ended yet',
  },
  Deleted: {
    description: 'Interactions that were deleted but had an associated cost',
  },
};

module.exports = InteractionType;
