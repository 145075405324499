import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const wave = keyframes`
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
`;

const Dot = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 3px;
  margin-bottom: ${({ $center }) => ($center ? '2px' : '0')};
  background: ${({ theme }) => theme.onLightColor};
  opacity: 0.3;
  animation: ${wave} 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
  }
`;

const Bubble = styled.span`
  padding-left: 5px;
`;

const Typing = function ({ center = true }) {
  return (
    <Bubble>
      <Dot $center={center} />
      <Dot $center={center} />
      <Dot $center={center} />
    </Bubble>
  );
};

Typing.propTypes = {
  center: PropTypes.bool,
};

export default Typing;
