import { parse } from '@guuru/url-common';
import { locales } from '@guuru/constants-common';
import { ChatAPI } from '@guuru/api-web';
import { LocaleHelper } from '@guuru/helpers-common';

const params = parse(window.location.search);
const { pathname } = window.location;

const useInitParams = function () {
  // It is possible to force locale by passing the locale as
  // locale path variable. Trying to extract locale
  let { locale } = params;
  const { category } = params;

  const availableLocales = locales.join('|');
  const re = new RegExp(`/.*/(${availableLocales})$`, 'i');
  const found = window.location.pathname.toLowerCase().match(re);
  if (found) {
    [, locale] = found;
  }
  let partnerId = pathname.substring(1);
  if (partnerId?.indexOf('/') > -1) {
    partnerId = partnerId.substring(0, partnerId.indexOf('/'));
  }
  const systemLocale = LocaleHelper.getSystemLocale();
  ChatAPI.storeCategoryParameter(category);
  ChatAPI.storeLocaleParameter(locale || systemLocale);
  ChatAPI.storeParams(params);

  return { partnerIdParam: partnerId };
};

export default useInitParams;
