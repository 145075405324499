import { useCallback, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import ms from 'ms';
import GET_CANNED_RESPONSES from './queries/getCannedResponses';

const useCannedResponses = function (partnerId, { skip } = {}) {
  const [responses, setResponses] = useState([]);
  const hasResponses = responses.length > 0;
  const [getCannedResponses] = useLazyQuery(GET_CANNED_RESPONSES, {
    onCompleted: ({ partner = {} }) => {
      const { cannedResponses: { edges } = {} } = partner;
      const cannedResponses = edges.map(({ node }) => node);
      setResponses(cannedResponses);
    },
  });

  const lookupCompletions = useMemo(() => debounce((command) => (
    getCannedResponses({
      variables: {
        prefix: command.substring(1),
        partnerId,
        first: 5,
      },
    })
  ), ms('0.2s')), [partnerId, getCannedResponses]);

  const handleInputChange = useCallback((value) => {
    if (skip) return;

    const command = value.trim();
    if (!command.startsWith('/')) {
      if (hasResponses) {
        setResponses([]);
      }
    } else if (command.length > 1) {
      lookupCompletions(command);
    }
  }, [hasResponses, lookupCompletions, skip]);

  return [handleInputChange, responses];
};

export default useCannedResponses;
