import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import styled from 'styled-components';
import Bot from './bot.svg';

const DefaultAvatar = styled(Bot)`
  color: ${({ theme: { primaryColor } = {} }) => primaryColor ?? 'black'};
  height: 100%;
  width: 100%;
`;

const BotAvatar = function ({ customBotAvatar = null }) {
  return customBotAvatar
    ? <Avatar shape="square" size={36} src={customBotAvatar} />
    : <DefaultAvatar />;
};

BotAvatar.propTypes = {
  customBotAvatar: PropTypes.string,
};

export default React.memo(BotAvatar);
