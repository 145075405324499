import styled from 'styled-components';
import { Button } from 'antd';

const ButtonInput = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  && .uploaded-file {
    margin-bottom: 1.5rem;
  }
`;

export default ButtonInput;
