import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { types, useLocalChat } from '@guuru/chat-web';
import orderBy from 'lodash/orderBy';
import { ChatStep } from '@guuru/constants-common';
import ChatRenderText from '../Text';
import Option from '../../Option';

const ChatRenderCategories = function ({
  message,
  locale = 'en',
  readOnly,
  dispatch = () => {},
}) {
  const { currentChatStep } = useLocalChat();
  const categories = message?.metadata?.categories;

  const handleSelection = useCallback((category, categoryLabel) => {
    dispatch({
      type: types.CHAT_RUN_NEXT_STEP,
      processArgs: {
        category,
        categoryLabel,
      },
    });
  }, [dispatch]);

  const sortedCategories = useMemo(() => (
    orderBy(categories, 'priority')
  ), [categories]);

  const showOptions = !readOnly
    && currentChatStep === ChatStep.requestCategory
    && sortedCategories.length > 0;

  return (
    <ChatRenderText message={message} tooltip={false}>
      {showOptions && (
        <div className="message-categories">
          <ul className="message-categories-list">
            {sortedCategories.map((category) => (
              <Option
                key={category.id}
                id={category.id}
                name={category.name}
                locale={locale}
                onClick={handleSelection}
              />
            ))}
          </ul>
        </div>
      )}
    </ChatRenderText>
  );
};

ChatRenderCategories.propTypes = {
  message: PropTypes.shape({
    metadata: PropTypes.shape({
      categories: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.array,
      ]),
    }),
  }).isRequired,
  locale: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
};

export default ChatRenderCategories;
