export const CHAT_INIT = 'CHAT_INIT';
export const CHAT_INIT_SUCCESS = 'CHAT_INIT_SUCCESS';
export const CHAT_FLOW_CONNECTION_ERROR = 'CHAT_FLOW_CONNECTION_ERROR';
export const CHAT_LOADED = 'CHAT_LOADED';
export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_LOAD_STARTING = 'CHAT_LOAD_STARTING';
export const CHAT_NEW_QUESTION = 'CHAT_NEW_QUESTION';
export const CHAT_NEW_TEXT = 'CHAT_NEW_TEXT';
export const CHAT_NEW_TEXT_REPLACE_LAST = 'CHAT_NEW_TEXT_REPLACE_LAST';
export const CHAT_NEW_TEXT_SUCCESS = 'CHAT_NEW_TEXT_SUCCESS';
export const CHAT_READY = 'CHAT_READY';
export const CHAT_RUN_NEXT_STEP = 'CHAT_RUN_NEXT_STEP';
