import { gql } from '@apollo/client';

export default gql`
  query GetChatIsTyping($chatId: ID!) {
    chat(id: $chatId) {
      id
      user {
        id
        name
        phone
      }
      expert {
        id
        name
        photoUrl
      }
      expertIsTyping
      userIsTyping
    }
  }
`;
