import styled from 'styled-components';
import { Input, Form } from 'antd';

const SearchInput = styled(Input.Search)`
  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 812px) {
    input {
      font-size: 1rem;
    }

    .ant-btn {
      height: 1.8rem;
    }
  }
`;

const FormItem = styled(Form.Item)`
  margin: 4px 0 !important;

  .ant-form-item-control-input-content {
    .ant-typography {
      margin-top: 1rem;
    }
  }
`;

export { SearchInput, FormItem };
