import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useFileAttachmentSubmit } from '@guuru/react-upload-hooks';
import { error } from '@guuru/react-message';
import Markdown from './Markdown';
import 'easymde/dist/easymde.min.css';

const Editor = React.lazy(() => import('react-simplemde-editor'));

const MarkdownEditor = function ({
  partnerId,
  setEditor = () => {},
  initialValue = '',
  setIsFullScreen = () => {},
  label = undefined,
  name,
  rules = [],
  messageVariables = {},
  className = undefined,
  minHeight = '100px',
  maxHeight = undefined,
  hideIcons = undefined,
  markdownStyle = undefined,
  placeholder = undefined,
}) {
  const inputFileRef = useRef();
  const editorRef = useRef();
  const handleAttachmentSubmit = useFileAttachmentSubmit({
    partnerId,
    onError: (msg) => error(msg),
  });

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) return;
    // file.name = file.name.replace(/\.[^/.]+$/, '');
    try {
      const address = await handleAttachmentSubmit({
        target: {
          files: [file],
        },
      });
      const output = file.type.startsWith('image')
        ? `![](<${address}>)`
        : `[](<${address}>)`;

      editorRef.current.codemirror.replaceSelection(output);
      editorRef.current.codemirror.save();
      editorRef.current.codemirror.refresh();
    } catch (err) {
      error(err.message);
    }
    editorRef.current.codemirror.focus();
  };

  const editorOptions = useMemo(() => ({
    spellChecker: false,
    status: false,
    showIcons: ['table', 'horizontal-rule'],
    onToggleFullScreen: (isFullScreen) => {
      setIsFullScreen(isFullScreen);
      if (isFullScreen) {
        editorRef.current.toggleSideBySide();
      }
    },
    placeholder,
    syncSideBySidePreviewScroll: false,
    autoRefresh: true,
    minHeight,
    maxHeight,
    hideIcons,
    toolbar: [
      'bold',
      'italic',
      'heading',
      'unordered-list',
      'ordered-list',
      '|',
      'link',
      {
        name: 'image',
        action: () => inputFileRef.current.click(),
        className: 'fa fa-picture-o',
        title: 'Insert Image',
      },
      {
        name: 'file',
        action: () => inputFileRef.current.click(),
        className: 'fa fa-file-pdf-o',
        title: 'Insert File',
      },
      'table',
      'horizontal-rule',
      '|',
      'preview',
      'side-by-side',
      'fullscreen',
      '|',
      'guide',
    ],
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <>
      <input
        type="file"
        id="file"
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={onChangeFile}
      />
      <Form.Item
        label={label}
        className={className}
        name={name}
        initialValue={initialValue}
        messageVariables={messageVariables}
        rules={rules}
        noStyle
      >
        <Markdown
          as={Editor}
          className={markdownStyle}
          getMdeInstance={(editor) => {
            editorRef.current = editor;
            setEditor(editor);
          }}
          options={editorOptions}
        />
      </Form.Item>
    </>
  );
};

MarkdownEditor.propTypes = {
  partnerId: PropTypes.string.isRequired,
  setEditor: PropTypes.func,
  setIsFullScreen: PropTypes.func,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape({})),
  messageVariables: PropTypes.shape({}),
  className: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  hideIcons: PropTypes.arrayOf(PropTypes.string),
  markdownStyle: PropTypes.string,
  placeholder: PropTypes.string,
};

export default MarkdownEditor;
