import styled from 'styled-components';

const ProfileSection = styled.div`
  display: inline-flex;
  flex-grow: 0;
  align-self: flex-end;
  float: left;
`;

const Profile = styled.div`
  width: 36px;
  height: 36px;
  overflow: ${({ $isHeroesFormat }) => ($isHeroesFormat ? 'visible' : 'hidden')};

  &:empty {
    width: 0;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .mod--left & {
    margin-right: 20px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .mod--right & {
    margin-left: 20px;
  }
`;

export { ProfileSection, Profile };
