import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@guuru/translation-web';
import { SendButtonOutlined } from '@guuru/react-icon';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { botMessagesTypes } from '@guuru/chat-web';
import ChatRenderText from '../Text';
import { FormItem, SearchInput } from '../../SearchInput';
import GET_USER_INFO from './queries/getUserInfo';
import PROVIDE_USER_EMAIL from './mutations/provideUserEmail';

const isHotTransfer = (message) => !!message?.metadata?.isHotTransferMessage;

const translateEmailProvided = (message, email) => {
  const translationCode = isHotTransfer(message)
    ? 'bot:::hotTransferEmailProvided'
    : botMessagesTypes.requestEmailThanks;

  return i18n.t(translationCode, { email });
};

const ChatRenderUserInfo = function ({ chatId, readOnly = true, message }) {
  const [form] = Form.useForm();
  const [userEmail, setUserEmail] = useState();

  const {
    loading,
    data: {
      chat: {
        user,
      } = {},
    } = {},
  } = useQuery(GET_USER_INFO, { variables: { id: chatId } });

  const [
    provideUserEmail,
    { loading: isSaving },
  ] = useMutation(PROVIDE_USER_EMAIL, {});

  const invalidEmail = i18n.t('bot:::messageTypeContactValidationEmail');
  const placeholderEmail = i18n.t('bot:::messageTypeContactMessageEmail');

  const newMessage = userEmail
    ? { ...message, i18n: translateEmailProvided(message, userEmail) }
    : message;

  const handleSubmit = async ({ email: submittedEmail }) => {
    try {
      await provideUserEmail({
        variables: {
          chatId,
          email: submittedEmail,
          isHotTransfer: isHotTransfer(message),
        },
      });
      setUserEmail(submittedEmail);
    } catch (e) {
      form.setFields([{ name: 'email', errors: [invalidEmail] }]);
    }
  };

  const Icon = isSaving ? LoadingOutlined : SendButtonOutlined;

  return (
    <ChatRenderText
      message={newMessage}
      tooltip={false}
      loading={loading}
    >
      {!readOnly && !user?.email && !userEmail && (
        <Form
          className="message-options"
          onFinish={handleSubmit}
          form={form}
        >
          <FormItem
            name="email"
            validateTrigger="onSubmit"
            rules={[
              {
                message: invalidEmail,
                type: 'email',
                required: true,
              },
            ]}
          >
            <SearchInput
              htmltype="email"
              placeholder={placeholderEmail}
              enterButton={<Icon />}
              onSearch={form.submit}
              onPressEnter={null}
              disabled={isSaving}
            />
          </FormItem>
        </Form>
      )}
    </ChatRenderText>
  );
};

ChatRenderUserInfo.propTypes = {
  chatId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    text: PropTypes.string,
    metadata: PropTypes.shape({
      email: PropTypes.string,
      isHotTransferMessage: PropTypes.bool,
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

export default ChatRenderUserInfo;
