import { useCallback } from 'react';
import { setBottomSheetVisible } from '@guuru/chat-web';

const useBottomSheet = function () {
  const openBottomSheet = useCallback(() => {
    setBottomSheetVisible(true);
  }, []);
  return { openBottomSheet };
};

export default useBottomSheet;
