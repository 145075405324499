export default async ({
  writeUrl,
  blob,
  contentType,
  maxBytes,
  acl,
}) => (
  fetch(writeUrl, {
    method: 'PUT',
    body: blob,
    headers: {
      'content-type': contentType,
      'cache-control': 'public, max-age=86400',
      'x-goog-content-length-range': `0,${maxBytes}`,
      'x-goog-acl': acl,
    },
  }).then((response) => {
    if (response.ok) return response;
    throw new Error(`Failed to upload ${contentType}: ${response.status}`);
  })
);
