import { ChatReportType, colors, localesData } from '@guuru/constants-common';
import {
  AppstoreOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CustomerServiceOutlined,
  DoubleRightOutlined,
  ExportOutlined,
  FlagOutlined,
  ForkOutlined,
  FrownOutlined,
  FundOutlined,
  InfoCircleOutlined,
  IssuesCloseOutlined,
  LikeOutlined,
  MailOutlined,
  MehOutlined,
  MessageOutlined,
  MonitorOutlined,
  OpenAIOutlined,
  QuestionCircleOutlined,
  RobotOutlined,
  SmileOutlined,
  StarOutlined,
  StopOutlined,
  SwapOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { GuuruOutlined } from '@guuru/react-icon';
import { channelsFilters } from './Channels';

const isAssignedToAgent = {
  key: 'isAssignedToAgent',
  name: 'Agent',
  icon: CustomerServiceOutlined,
};

const isAssignedToGuuru = {
  key: 'isAssignedToGuuru',
  name: 'Guuru',
  icon: GuuruOutlined,
};

const isAssignedToAutomation = {
  key: 'isAssignedToAutomation',
  name: 'Automation',
  icon: RobotOutlined,
};

const isAssignedToBot = {
  key: 'isAssignedToBot',
  name: 'Bot',
  icon: OpenAIOutlined,
};

const isOnboarding = {
  key: 'isOnboarding',
  name: 'Onboarding',
  icon: IssuesCloseOutlined,
};

const isNotRated = {
  key: 'isNotRated',
  name: 'Not Rated',
};

const isManualRated = {
  key: 'isManualRated',
  name: 'Is Manual Rated',
};

const referer = {
  key: 'referer',
  name: 'Referer',
  editable: true,
};

const hasFeedback = {
  key: 'hasFeedback',
  name: 'Has Feedback',
  icon: LikeOutlined,
};
const isFromCustomReferer = {
  key: 'isFromCustomReferer',
  name: 'From Custom Referer',
};

const isNotHandover = {
  key: 'isNotHandover',
  name: 'Not Handover',
};

const isConverted = {
  key: 'isConverted',
  name: 'Conversion',
  icon: FundOutlined,
};

const isTransferred = {
  key: 'isTransferred',
  name: 'Transferred',
  icon: DoubleRightOutlined,
};

const isHotTransferred = {
  key: 'isHotTransferred',
  name: 'Hot Transferred',
  icon: SwapOutlined,
};

const isHotTransferredByBot = {
  key: 'isHotTransferredByBot',
  name: 'Hot Transferred by Bot',
  icon: SwapOutlined,
};

const isAutomated = {
  key: 'isAutomated',
  name: 'Automated',
  icon: RobotOutlined,
};

const notAutomated = {
  key: 'notAutomated',
  name: 'Not Automated',
  icon: StopOutlined,
};

const insufficientAnswerQuality = {
  name: 'Insufficient answer quality',
  key: 'reviewStats',
  value: { name: 'answer_rating', value: [0, 0.5] },
  icon: MonitorOutlined,
};

const claimedEmployee = {
  name: 'Claimed to be an employee',
  key: 'reviewStats',
  value: { name: 'claimed_employee', value: true },
  icon: MonitorOutlined,
};

const timeToRespond = {
  name: 'Too much time to respond',
  key: 'reviewStats',
  value: { name: 'time_to_respond', value: true },
  icon: MonitorOutlined,
};
const chatAbandoned = {
  name: 'Chat abandoned',
  key: 'reviewStats',
  value: { name: 'chat_abandoned', value: true },
  icon: MonitorOutlined,
};
const promotingCompanies = {
  name: 'Promoting other companies',
  key: 'reviewStats',
  value: { name: 'promoting_companies', value: true },
  icon: MonitorOutlined,
};
const shouldTransfer = {
  name: 'Should have transferred',
  key: 'reviewStats',
  value: { name: 'should_transfer', value: true },
  icon: MonitorOutlined,
};
const others = {
  name: 'Others',
  key: 'reviewStats',
  value: { name: 'others', value: true },
  icon: MonitorOutlined,
};

const transferNotJustied = {
  name: 'Transfer not justified',
  key: 'reviewStats',
  value: { name: 'transfer_justified', value: false },
  icon: MonitorOutlined,
};

const firstCallResolution = {
  name: 'First contact resolution',
  key: 'reviewStats',
  value: { name: 'first_call_resolution', value: true },
  icon: InfoCircleOutlined,
};

const notFirstCallResolution = {
  name: 'Not first contact resolution',
  key: 'reviewStats',
  value: { name: 'first_call_resolution', value: false },
  icon: InfoCircleOutlined,
};

const noTrueExpertise = {
  name: 'Guuru doesnt have true expertise',
  key: 'reviewStats',
  value: { name: 'true_expertise', value: 'no' },
  icon: InfoCircleOutlined,
};

const canExpertAnswer = {
  name: 'Can Guuru answer',
  key: 'reviewStats',
  value: { name: 'can_expert_answer', value: false },
  icon: QuestionCircleOutlined,
};

const allReviewActions = {
  name: 'All Actions',
  key: 'reviewStats',
  value: [
    { name: 'answer_rating', value: [0, 0.5] },
    { name: 'claimed_employee', value: true },
    { name: 'time_to_respond', value: true },
    { name: 'chat_abandoned', value: true },
    { name: 'promoting_companies', value: true },
    { name: 'should_transfer', value: true },
    { name: 'others', value: true },
    { name: 'transfer_justified', value: false },
  ],
  icon: MonitorOutlined,
};

const longWaitingTime = {
  name: 'Long waiting time',
  key: 'responseTime',
  value: [3, 5],
  icon: ClockCircleOutlined,
  color: '#f28f2c',
};

const unacceptableWaitingTime = {
  name: 'Unacceptable waiting time',
  key: 'responseTime',
  value: [5, 'Infinity'],
  icon: ClockCircleOutlined,
  color: '#fa541c',
};

const ChatFilters = {
  expert: {
    key: 'expert',
  },
  user: {
    key: 'user',
  },
  member: {
    key: 'member',
  },
  isAssignedToAgent,
  isAssignedToGuuru,
  isAssignedToAutomation,
  assignTo: {
    key: 'assignedTo',
    label: 'Assigned to',
    filters: [
      {
        ...isAssignedToGuuru,
      },
      {
        ...isAssignedToAgent,
        check: (showAll, partner) => (
          showAll || partner?.settings.agents.isEnabled
        ),
      },
      {
        ...isAssignedToAutomation,
        check: (showAll, partner) => (
          showAll || partner?.hasPredictions
        ),
      },
      {
        ...isAssignedToBot,
        check: (showAll, partner) => (
          showAll || partner?.automation?.settings?.isBotEnabled
        ),
      },
    ],
  },
  isConverted,
  isTransferred,
  isHotTransferred,
  isHotTransferredByBot,
  isOnboarding,
  isAutomated,
  isManualRated,
  filterBy: {
    key: 'filterBy',
    label: 'Show Only',
    filters:
      [
        isConverted,
        isTransferred,
        isHotTransferred,
        isHotTransferredByBot,
        isOnboarding,
        isNotRated,
        hasFeedback,
        isFromCustomReferer,
        referer,
        {
          ...isNotHandover,
          check: (showAll) => (showAll),
        },
      ],
  },
  automated: {
    key: 'automated',
    label: 'Automation',
    filters:
      [
        isAutomated,
        notAutomated,
        {
          name: 'Show answer',
          icon: QuestionCircleOutlined,
          value: 'showAnswer',
        },
        {
          name: 'Routing',
          icon: ForkOutlined,
          value: 'routing',
        },
        {
          name: 'Fallback Disabled',
          icon: CloseCircleOutlined,
          value: 'allowFallback',
        },
        {
          name: 'Fallback to Guurus',
          icon: GuuruOutlined,
          value: 'guurusOnly',
        },
        {
          name: 'Fallback to Agents',
          icon: CustomerServiceOutlined,
          value: 'agentsOnly',
        },
        {
          name: 'Fallback Transfer',
          icon: MailOutlined,
          value: 'transfer',
        },
      ].map((filter) => ({
        ...filter,
        check: (showAll, partner) => (showAll || partner?.hasPredictions),
      })),
  },
  rating: {
    key: 'rating',
    label: 'Rating',
    type: 'rate',
    filters: [
      {
        name: 'Good',
        value: '1',
        icon: StarOutlined,
      },
      {
        name: 'Average',
        value: '0.5',
        icon: StarOutlined,
      },
      {
        name: 'Bad',
        value: '0',
        icon: StarOutlined,
      },
    ],
  },
  channel: {
    key: 'channel',
    label: 'Channel',
    filters: [...channelsFilters],
  },
  language: {
    key: 'language',
    label: 'Language',
    type: 'language',
    filters: Object.entries(localesData).map(([key, { name }]) => (
      { name, value: key }
    )),
  },
  review: {
    key: 'review',
    label: 'Review',
    filters: [
      {
        name: 'Good',
        value: '1',
        icon: StarOutlined,
        theme: 'filled',
        color: colors.green,
      },
      {
        name: 'Average',
        value: '0.5',
        icon: StarOutlined,
        theme: 'filled',
        color: colors.orange,
      },
      {
        name: 'Bad',
        value: '0',
        icon: StarOutlined,
        theme: 'filled',
        color: colors.red,
      },
      {
        name: 'No Review',
        value: '-1',
        icon: StarOutlined,
        theme: 'filled',
        color: colors.gray,
      },
    ],
  },
  npsSegment: {
    key: 'npsSegment',
    label: 'NPS Segment',
    filters: [
      {
        name: 'Promoters',
        value: '1',
        icon: SmileOutlined,
        color: '#52c41a',
      },
      {
        name: 'Passives',
        value: '0',
        icon: MehOutlined,
        color: '#fd8a34',
      },
      {
        name: 'Detractors',
        value: '-1',
        icon: FrownOutlined,
        color: '#fc0303',
      },
    ],
  },
  categories: {
    key: 'categories',
    label: 'Categories',
    getFilters: ({ partner }) => (
      partner
        ? partner.categories.filter((c) => c.name).map((item) => (
          { name: item.name, value: item.id, icon: AppstoreOutlined }
        ))
        : null
    ),
  },
  partner: {
    key: 'partner',
    label: 'Partner',
    type: 'partner',
    getFilters: ({ partners }) => (
      partners.map(({ node }) => (
        { name: node.name.en, value: node.id }
      ))
    ),
  },
  searchText: {
    key: 'searchText',
  },
  ipAddress: {
    key: 'ipAddress',
  },
  ipAddressVisitor: {
    key: 'ipAddressVisitor',
  },
  reported: {
    key: 'reported',
    label: 'Reported',
    filters: Object.keys(ChatReportType).map((key) => (
      { name: key, value: key, icon: FlagOutlined }
    )),
  },
  status: {
    key: 'status',
    label: 'Status',
    filters: [
      {
        value: 'assigned',
        name: 'Waiting',
        icon: WarningOutlined,
      },
      {
        value: 'missed',
        name: 'Missed',
        icon: ExportOutlined,
      },
      {
        value: 'handover',
        name: 'Handover',
        icon: DoubleRightOutlined,
      },
      {
        value: 'open',
        name: 'Live',
        icon: MessageOutlined,
      },
      {
        value: 'rated',
        name: 'Rated',
        icon: StarOutlined,
      },
    ],
  },
  isReviewed: {
    key: 'isReviewed',
    name: 'Reviewed',
  },
  isNotReviewed: {
    key: 'isNotReviewed',
    name: 'Not Reviewed',
  },
  isReviewer: {
    key: 'isReviewer',
    name: 'Reviewer',
  },
  chatsToReview: {
    key: 'chatsToReview',
  },

  reviewStats: {
    key: 'reviewStats',
    label: 'Review Feedbacks',
    filters: [
      insufficientAnswerQuality,
      claimedEmployee,
      timeToRespond,
      chatAbandoned,
      promotingCompanies,
      shouldTransfer,
      others,
      transferNotJustied,
      allReviewActions,
    ],
  },

  reviewOthers: {
    key: 'reviewOthers',
    label: 'Review Others',
    filters: [
      firstCallResolution,
      notFirstCallResolution,
      canExpertAnswer,
      noTrueExpertise,
    ],
  },

  waitingTime: {
    key: 'waitingTime',
    label: 'Waiting Time',
    check: (showAll) => showAll,
    filters: [
      longWaitingTime,
      unacceptableWaitingTime,
    ],
  },

};

export default ChatFilters;
