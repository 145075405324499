import React from 'react';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { error } from '@guuru/react-message';
import { i18n } from '@guuru/translation-web';
import { UploadButton, Upload } from './style';

const UploadFilesButton = function () {
  return (
    <UploadButton icon={<UploadOutlined />}>
      {i18n.t('chat:::uploadFile')}
    </UploadButton>
  );
};

const UploadFiles = function ({
  currentFiles = [],
  setCurrentFiles = () => {},
}) {
  const handleChange = async (info) => {
    const { file } = info;
    if (file.status === 'removed') {
      setCurrentFiles(currentFiles.filter((f) => f.uid !== file.uid));
    } else if (file.status === 'uploading') {
      const blob = file?.originFileObj;
      setCurrentFiles([...currentFiles, blob]);
    } else if (file.status === 'error') {
      error();
    }
  };

  return (
    <Upload
      accept="image/*,.pdf"
      customRequest={() => {}}
      fileList={currentFiles}
      onChange={handleChange}
      showUploadList={{ showPreviewIcon: false }}
    >
      {currentFiles.length >= 5 ? null : <UploadFilesButton />}
    </Upload>
  );
};

UploadFiles.propTypes = {
  setCurrentFiles: PropTypes.func,
  currentFiles: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

export default UploadFiles;
