const channelType = {
  chat: 'chat',
  form: 'form',
  community: 'community',
  mail: 'mail',
  facebook: 'facebook',
  salesforce: 'salesforce',
};

module.exports = channelType;
