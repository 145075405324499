import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import events from '@guuru/events-web';
import {
  getChat,
  getCurrentChatId,
  setFeedbackSubmitted,
  types,
} from '@guuru/chat-web';
import { client } from '@guuru/graphql-web';
import createOrUpdateUser from '../../chatActions/createOrUpdateUser';
import handleChatStatusChange from '../../chatActions/handleChatStatusChange';
import postHeightUpdate from '../../chatActions/postHeightUpdate';
import JOIN_CHAT from './mutations/joinChat';

const storeQuestion = (chatData, partnerId) => {
  const chatQuestion = chatData.question;
  ChatAPI.storeQuestion(partnerId, chatQuestion);
};

const storeUserPredictionAnswer = (chatData, partnerId) => {
  const userAnsweredPrediction = chatData?.messages?.edges.find(
    ({ node }) => node?.metadata?.isUserPredictionAnswer,
  );
  const answer = userAnsweredPrediction?.node?.metadata?.response;
  ChatAPI.storeUserPredictionAnswer(partnerId, answer);
};

const storeFeedback = (chatData) => {
  const chatFeedback = chatData?.feedback;
  const chatNps = chatData?.npsRating;

  const hasFeedback = chatFeedback?.topics?.length ?? chatFeedback?.comment;
  if (!hasFeedback && !chatNps) return null;
  return setFeedbackSubmitted(true);
};

const loadChat = function* ({
  partnerId,
  chatId,
  chatControlToken,
  action,
}) {
  const { navigate, location } = action;
  let channel = ChatAPI.storeRetrieveChannel(partnerId);
  yield createOrUpdateUser(partnerId);
  let chatData = null;
  try {
    yield client.mutate({
      mutation: JOIN_CHAT,
      variables: { id: chatId, chatControlToken },
    });
    chatData = yield getChat(chatId, true);
    ChatAPI.storeChatControlToken(partnerId, chatId, chatControlToken);
  } catch (e) {
    ChatAPI.storeReset(partnerId);
    const chatUrl = ChatAPI.getChatURL(partnerId, channel, {
      qs: location.search,
    });
    navigate(chatUrl, { replace: true });
  }
  if (chatData) {
    events.loadExistingChat();

    storeQuestion(chatData, partnerId);
    storeUserPredictionAnswer(chatData, partnerId);
    storeFeedback(chatData);

    channel = chatData.channel;
    ChatAPI.storeChannel(partnerId, channel);

    const chatUrl = ChatAPI.getChatURL(partnerId, channel, {
      chatId,
      token: chatControlToken,
      qs: location.search,
    });
    window.history.replaceState({}, '', chatUrl);

    handleChatStatusChange({ chatId, ...chatData });

    yield put({
      type: types.CHAT_READY,
      chatId,
      chatControlToken,
      acceptedAt: chatData.acceptedAt,
      messages: chatData.messages.edges || [],
    });

    yield put({ type: types.CHAT_LOADED });

    yield postHeightUpdate(partnerId);
    return true;
  }
  return false;
};

// It a user access a direct link ( chatId is available ) then the
// chat should be displayed even if it is rated. If not, we must try
// to load the existing chat from localstorage but if this chat is
// already closed, then we should reset to homepage
const chatLoad = {
  validate() {
    return true;
  },

  * render(partnerId, { action }) {
    // Use given chat id or use a stored one If there is an active chat,
    // redirect to chat view
    const activeChatId = getCurrentChatId();
    const activeChatControlToken = action.chatControlToken
      || ChatAPI.storeRetrieveChatControlToken(partnerId, activeChatId);

    const hasActiveChat = typeof activeChatId === 'string'
      && activeChatId.length > 0
      && typeof activeChatControlToken === 'string'
      && activeChatControlToken.length > 0;
    if (!hasActiveChat) {
      return { goToNextStep: true };
    }

    const chatLoaded = yield loadChat({
      partnerId,
      chatId: activeChatId,
      chatControlToken: activeChatControlToken,
      action,
    });
    return { goToNextStep: !chatLoaded };
  },
  processResponse() {},

};

export default chatLoad;
