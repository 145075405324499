import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Emoji from './Emoji';
import MessageTooltip from '../../MessageTooltip';

const ChatRenderEmoji = function ({ message, tooltip = true }) {
  const textMessage = useMemo(() => (
    <div>
      <Emoji>{message.text}</Emoji>
    </div>
  ), [message]);

  if (!tooltip) {
    return textMessage;
  }
  return <MessageTooltip message={textMessage}>{textMessage}</MessageTooltip>;
};

ChatRenderEmoji.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string,
    createdAt: PropTypes.number,
  }).isRequired,
  tooltip: PropTypes.bool,
};

export default ChatRenderEmoji;
