import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@guuru/react-expert-avatar';

const Photo = function ({ expert }) {
  const { name, photoUrl } = expert;
  return (
    <Avatar
      src={photoUrl}
      text={name}
      shape="square"
      style={{
        objectFit: 'cover',
        aspectRatio: '1 / 1',
        width: '100%',
        height: '100%',
        border: 'inherit',
      }}
    />
  );
};

Photo.propTypes = {
  expert: PropTypes.shape({
    name: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
};

export default Photo;
