import { Sentry } from '@guuru/sentry-web';
import { errorType } from '@guuru/graphql-common';

const REFRESH_INTERVAL_MS = process.env.REFRESH_INTERVAL_MS || (5 * 60 * 1000);
const userErrorCodes = Object.keys(errorType);

const reloadOnSchemaError = (code) => {
  if (code !== 'GRAPHQL_VALIDATION_FAILED') return false;

  const lastCheckAt = Number(window.localStorage.getItem('lastVersionCheckAt'));
  if (lastCheckAt > Date.now() - REFRESH_INTERVAL_MS) return false;

  window.localStorage.setItem('lastVersionCheckAt', Date.now());
  window.location.reload(true);
  return true;
};

const errorCallback = ({ operation = {}, graphQLErrors, networkError }) => {
  let isUserError = false;
  const { operationName, variables } = operation;

  if (graphQLErrors) {
    isUserError = graphQLErrors.every(({ message, extensions = {} }) => {
      const { code = '', exception = {} } = extensions;

      if (userErrorCodes.includes(code)) {
        return true;
      }

      if (reloadOnSchemaError(code)) {
        return false;
      }

      Sentry.captureMessage(message, {
        tags: {
          operation: operationName,
          code,
        },
        extra: {
          operation: operationName,
          variables: JSON.stringify(variables),
          ...exception,
        },
        level: 'error',
      });
      console.error(`[GraphQL error]: ${message}`, operationName);
      return false;
    });
  }

  if (!isUserError && networkError?.name === 'ServerError') {
    Sentry.captureException(networkError, {
      tags: {
        operation: operationName,
      },
      extra: {
        operation: operationName,
        variables: JSON.stringify(variables),
        response: JSON.stringify(networkError.response),
        result: JSON.stringify(networkError.result),
      },
    });
    console.error(networkError);
  }
};

export default errorCallback;
