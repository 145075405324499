const str2hsl = (str, s = 30, l = 80) => {
  if (!str) {
    return undefined;
  }
  const [...nameArray] = str;
  const hash = nameArray.reduce((a, c) => {
    // eslint-disable-next-line no-bitwise
    const h = c.charCodeAt(0) + ((a << 2) - a);
    return h % 360;
  }, 0);

  return `hsl(${hash}, ${s}%, ${l}%)`;
};

export default str2hsl;
