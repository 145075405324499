import styled, { css, keyframes } from 'styled-components';

const breathing = keyframes`
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
`;

const animation = css`
  animation: ${breathing} 1s ease-out infinite normal;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
  opacity: 0.6;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  ${({ $motion }) => ($motion ? animation : 'animation: none;')}

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    color: black;
    opacity: 0.6;
  }
`;

export default Logo;
