import styled from 'styled-components';
import { Avatar as AntAvatar } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const AvatarGroup = styled(AntAvatar.Group)`
  margin-right: -8px;
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
