import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ChatAPI } from '@guuru/api-web';
import { types, useLocalChat } from '@guuru/chat-web';
import { ChatStep } from '@guuru/constants-common';
import SmartFormIntentOptions from './SmartFormIntentOptions';
import ChatIntentOptions from './ChatIntentOptions';

const ChatRenderIntentSelection = function ({
  message,
  readOnly,
  partnerId = undefined,
  dispatch = () => {},
  locale = 'en',
}) {
  const { requestIntentSelection } = ChatStep;
  const { currentChatStep } = useLocalChat();
  const requestIntent = currentChatStep === requestIntentSelection;
  const [
    showOptions,
    setShowOptions,
  ] = useState(requestIntent);

  const handleSelection = useCallback(
    (intent) => {
      const automationBehavior = ChatAPI.storeRetrieveAutomationBehavior(
        partnerId,
      );
      setShowOptions(false);
      dispatch({
        type: types.CHAT_RUN_NEXT_STEP,
        processArgs: {
          automationBehavior: { ...automationBehavior, ...intent },
        },
      });
    },
    [dispatch, partnerId],
  );

  const isSmartForm = message?.smartFormStyle === true;
  const IntentOptions = isSmartForm
    ? SmartFormIntentOptions
    : ChatIntentOptions;
  const showMultiIntentOptions = (requestIntent && showOptions) || readOnly;

  return (
    <IntentOptions
      intents={message.metadata?.intents}
      handleSelection={handleSelection}
      languageCode={locale}
      message={message}
      showOptions={showMultiIntentOptions}
      readOnly={readOnly}
    />
  );
};

ChatRenderIntentSelection.propTypes = {
  message: PropTypes.shape({
    metadata: PropTypes.shape({
      intents: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    smartFormStyle: PropTypes.bool,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  partnerId: PropTypes.string,
  dispatch: PropTypes.func,
  locale: PropTypes.string,
};

export default ChatRenderIntentSelection;
