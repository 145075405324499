import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useChatSettings } from '@guuru/chat-web';
import { Col, Row, Space } from 'antd';
import { ChatRowWrapper } from '@guuru/react-chat-messages';
import RatingButtons from './RatingButtons';
import GET_CHAT_HEADER from './queries/getChatHeader';
import StyledHeader from './StyledHeader';
import Heading from './Heading';
import CloseButton from './CloseButton';

const ChatHeader = function ({ showRate = false, chatId = null }) {
  const chatReducer = useSelector((state) => state.chatReducer);

  const {
    data: { chatSettings } = {},
    loading: isLoadingSettings,
  } = useChatSettings();

  const {
    data: { chat } = {},
  } = useQuery(GET_CHAT_HEADER, {
    variables: { id: chatId },
    skip: !chatId || chatReducer?.loading,
  });

  if (isLoadingSettings || !chatSettings) {
    return null;
  }

  return (
    <ChatRowWrapper isUser>
      <StyledHeader
        $backgroundImage={chatSettings.chatWidgetHeaderImageUrl}
        justify="space-between"
      >
        <Col flex="100%">
          <Row>
            <Col flex="auto">
              <CloseButton />
            </Col>
            <Col flex="none">
              <Row justify="end">
                <Space direction="vertical" size={3}>
                  <Row justify="end">
                    {showRate && (
                      <RatingButtons
                        selectedRating={chat?.rating}
                        chatId={chatId}
                      />
                    )}
                  </Row>
                </Space>
              </Row>
            </Col>
          </Row>
        </Col>
        <Heading chat={chat} />
      </StyledHeader>
    </ChatRowWrapper>
  );
};

ChatHeader.propTypes = {
  showRate: PropTypes.bool,
  chatId: PropTypes.string,
};

export default ChatHeader;
