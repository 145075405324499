import { useEffect } from 'react';
import { Howl } from 'howler';

const useSoundEffect = (options = {}, dependencies = []) => {
  const { when } = options;
  useEffect(() => {
    if (when) {
      const sound = new Howl({
        src: 'https://cdn.guuru.com/assets/sounds/newChatMessageSound.mp3',
        onunlock: () => {
          sound.volume(0.0);
          sound.once('end', () => sound.volume(1.0));
        },
      });
      sound.play();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useSoundEffect;
