import { gtag } from '@guuru/analytics-web';
import * as ga from './src/ga';
import sendEvent from './src/events';
import useTrackEventOnce from './src/hooks/useTrackEventOnce';

const events = {
  setAnalyticsProperties(args) {
    gtag.setProperties(args);
  },
  useTrackEventOnce,
  requestPartnerSelection(locale, partnerAccountId) {
    gtag.setProperties({ locale, account: partnerAccountId });
    gtag.track(
      'Request Partner Selection',
      ga.CHAT_FLOW,
      ga.BOT_MESSAGE,
      true,
    );
    sendEvent('chat:bot:requestPartnerSelection');
  },
  expertConnected(responseTime) {
    sendEvent('chat:bot:connectedWithExpert');
    sendEvent('chat:expertConnected', { val: responseTime });
  },
  loadExistingChat() {
    gtag.track('Load Existing', ga.CHAT_FLOW, ga.NAVIGATION, { nonInteraction: true });
    sendEvent('chat:loadExistingChat');
  },
  emailSubmitted(email) {
    gtag.track('Email', ga.CHAT_FLOW, ga.CHAT_MESSAGE);
    sendEvent('chat:email', { val: email });
  },
  nameSubmitted(name) {
    gtag.track('Name', ga.CHAT_FLOW, ga.CHAT_MESSAGE);
    sendEvent('chat:name', { val: name });
  },
  connectingWithExpert() {
    gtag.track('Connecting with expert', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
    sendEvent('chat:bot:connectingWithExpert');
  },
  requestName() {
    gtag.track('Request Name', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
    sendEvent('chat:bot:requestName');
  },
  requestEmail() {
    gtag.track('Request Email', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
    sendEvent('chat:bot:requestEmail');
  },
  greeting() {
    gtag.track('Greeting', ga.CHAT_FLOW, ga.CHAT_MESSAGE);
  },
  questionSubmitted(question) {
    gtag.track('Question', ga.CHAT_FLOW, ga.CHAT_MESSAGE);
    sendEvent('chat:question', { val: question });
  },
  chatWithExpert(message) {
    gtag.track('Chat with Expert', ga.CHAT_FLOW, ga.BOT_MESSAGE);
    sendEvent('chat:chatWithExpert', { val: message });
  },
  newChat(deviceType) {
    gtag.track(deviceType, ga.CHAT_FLOW, 'New Chat', { nonInteraction: true });
    sendEvent('chat:newChat');
  },
  chatCreated(chatId) {
    sendEvent('chat:chatCreated', { val: chatId });
  },
  ratePrompted() {
    gtag.track('Rate Prompted', ga.CHAT_FLOW, ga.NAVIGATION);
    sendEvent('chat:ratePrompted');
  },
  rateBadPrompted() {
    gtag.track('Bad Rate Prompted', ga.CHAT_FLOW, ga.NAVIGATION);
    sendEvent('chat:rateBadPrompted');
  },
  preRateExpertCtaShown() {
    gtag.track('Pre Rating CTA Shown', ga.CHAT_FLOW, ga.RATE, { nonInteraction: true });
  },
  preRateExpertCtaPressed() {
    gtag.track('Pre Rating CTA Press', ga.CHAT_FLOW, ga.RATE);
  },
  preRateExpertCtaDismiss() {
    gtag.track('Pre Rating CTA Dismiss', ga.CHAT_FLOW, ga.RATE);
  },
  preRateExpert(label) {
    gtag.track(label, ga.CHAT_FLOW, ga.RATE);
  },
  rateExpert(label, rating) {
    gtag.track(label, ga.CHAT_FLOW, ga.RATE);
    sendEvent('chat:rateExpert', { val: rating });
  },
  newChatClick() {
    gtag.track('Restart Chat Flow (New Chat press)', ga.CHAT_FLOW, ga.NAVIGATION);
    sendEvent('chat:newChatClick');
  },
  startingNew() {
    gtag.track('Starting New', ga.CHAT_FLOW, ga.NAVIGATION, { nonInteraction: true });
  },
  welcome() {
    gtag.track('Welcome', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
    sendEvent('chat:bot:welcome');
  },
  chatLoaded() {
    sendEvent('chat:loaded');
  },
  communityLoaded() {
    gtag.track('Community Space Viewed', ga.COMMUNITY_SPACE, ga.NAVIGATION, { nonInteraction: true });
    sendEvent('community:loaded');
  },
  communityIconClicked() {
    gtag.track('Community Icon Clicked', ga.COMMUNITY_SPACE, ga.NAVIGATION);
  },
  communityBackButtonClicked() {
    gtag.track('Community Guuru Back Button Clicked', ga.COMMUNITY_SPACE, ga.NAVIGATION);
  },
  requestCategory() {
    gtag.track('Request Category', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
    sendEvent('chat:bot:requestCategory');
  },
  categorySubmitted(category) {
    gtag.track('Category', ga.CHAT_FLOW, ga.CHAT_MESSAGE);
    sendEvent('chat:category', { val: category });
  },
  requestQuickActionSelection() {
    gtag.track('Choose Quick Action', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
  },
  quickActionEndFlow() {
    gtag.track('Quick Action End Flow', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
  },
  quickActionRequestQuestion() {
    gtag.track('Quick Action Request Question', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
  },
  quickActionHandleDirectLink() {
    gtag.track('Quick Action End Flow', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
  },
  quickActionSubmitted(label, quickAction) {
    gtag.track(label, ga.CHAT_FLOW, ga.CHAT_MESSAGE);
    sendEvent('chat:quickActionSubmitted', { val: quickAction.id });
  },
  waitFallback(contact) {
    gtag.track('WaitFallback', ga.CHAT_FLOW, ga.CHAT_MESSAGE);
    sendEvent('chat:waitFallback', { val: contact });
  },
  emailWaitTimeout() {
    gtag.track('Email Wait Timeout Reached', ga.CHAT_FLOW, ga.BOT_MESSAGE, { nonInteraction: true });
    sendEvent('chat:bot:emailWaitTimeout');
  },
  transferByEmail() {
    gtag.track('Transfer by Email', ga.CHAT_FLOW, ga.CHAT_MESSAGE);
    sendEvent('chat:transferByEmail');
  },
  acceptChat() {
    gtag.track('Accept Chat', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  rejectChat() {
    gtag.track('Reject Chat', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  closeChat() {
    sendEvent('chat:close');
  },
  attachmentSubmit(label) {
    gtag.track(label, ga.MESSAGE_BAR, ga.SUBMIT_ATTACHMENT);
  },
  submit(label) {
    gtag.track(label, ga.MESSAGE_BAR, ga.SUBMIT_INPUT);
  },
  termsAndConditionsClick() {
    gtag.track('Terms and Conditions', ga.NAVIGATION, ga.NAVIGATION);
  },
  predict(answer) {
    sendEvent('chat:predict', { val: answer });
  },
  expertTransferToCategory() {
    gtag.track('Expert transferred chat to category', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  expertTransferToTarget() {
    gtag.track('Expert transferred chat to target', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  expertCloseChat() {
    gtag.track('Expert closed chat', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  expertOpenChat() {
    gtag.track('Expert open chat', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  userReloadChat() {
    gtag.track('User reloaded chat', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  delegateFormToClient() {
    gtag.track('User question delegated to client form', ga.CHAT_FLOW, ga.NAVIGATION);
  },
  rateChatNps(score) {
    gtag.track(`Scored ${score}`, ga.CHAT_FLOW, ga.NPS);
  },
  rateNpsPrompted() {
    gtag.track('NPS prompted for chat', ga.CHAT_FLOW, ga.NPS_PROMPTED);
  },
  requestFeedback() {
    gtag.track('Feedback request prompted for chat', ga.CHAT_FLOW, ga.FEEDBACK_REQUEST_PROMPTED);
  },
  downloadGuuruApp() {
    gtag.track(undefined, ga.COMMUNITY_INVITATION_PAGE, 'download_app');
  },
  openInvitationLink() {
    gtag.track(undefined, ga.COMMUNITY_INVITATION_PAGE, 'join_click');
  },
  openCommunityChatSupport() {
    gtag.track(undefined, ga.COMMUNITY_INVITATION_PAGE, 'invitation_support_click');
  },
  expertProfileViewed() {
    gtag.track('User viewed expert profile ', ga.BOTTOM_SHEET, ga.NAVIGATION);
  },
};

export default events;
