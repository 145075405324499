import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Comment from './Comment';

const CommentsList = function ({ dataSource }) {
  return (
    <Row wrap justify="space-between" gutter={[24, 16]}>
      {dataSource.map(({ comment }, index) => (
      // eslint-disable-next-line react/no-array-index-key
        <Col flex={1} key={index} xs={24} xl={12}>
          <Comment comment={comment} />
        </Col>
      ))}
    </Row>
  );
};

CommentsList.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

export default CommentsList;
