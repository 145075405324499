import styled from 'styled-components';
import { Tabs } from 'antd';

export default styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 4px;
  }

  .ant-tabs-tab {
    padding-bottom: 6px;
  }

  .ant-tabs-tab:hover {
    color: ${({ theme }) => theme.primaryColor};
  }

  .ant-tabs-tab-active,
  .ant-tabs-tab-active:hover {
    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.primaryVariants?.[6]};
    }
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.primaryColor};
  }
`;
