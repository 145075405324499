import { ChatStep } from '@guuru/constants-common';
import {
  INPUT_TYPE_BACK,
  INPUT_TYPE_CHAT,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_NAME,
  INPUT_TYPE_NONE,
  INPUT_TYPE_QUESTION,
  INPUT_TYPE_RESTART,
  STEP_AUTOMATION,
  STEP_BOOTING,
  STEP_CATEGORY,
  STEP_CHAT,
  STEP_CONNECTING_TO_EXPERT,
  STEP_EMAIL,
  STEP_FINISHED,
  STEP_NAME,
  STEP_QUESTION,
  STEP_RATE,
  STEP_RATE_BAD,
} from '../constants/flow';
import storage from '../storage/containers';

const {
  chatId,
  chatStep,
  chatChannel,
  isRunningStep,
  connectionError,
  step,
  inputType,
  feedbackSubmitted,
  bottomSheetVisible,
} = storage;

const getInputType = (currentStep) => {
  switch (currentStep) {
    case ChatStep.requestName:
      return INPUT_TYPE_NAME;
    case ChatStep.requestEmail:
      return INPUT_TYPE_EMAIL;
    case ChatStep.chatQuestion:
    case ChatStep.greeting:
      return INPUT_TYPE_QUESTION;
    case ChatStep.chat:
      return INPUT_TYPE_CHAT;
    case ChatStep.connectingExpert:
      return INPUT_TYPE_CHAT;
    case ChatStep.closeChat:
      return INPUT_TYPE_NONE;
    case ChatStep.rateBadChat:
      return INPUT_TYPE_BACK;
    case ChatStep.rateChat:
    case ChatStep.chatEnd:
    case ChatStep.makePrediction:
      return INPUT_TYPE_RESTART;
    default:
      return INPUT_TYPE_NONE;
  }
};

const getStep = (currentStep) => {
  switch (currentStep) {
    case ChatStep.requestCategory:
      return STEP_CATEGORY;
    case ChatStep.requestName:
      return STEP_NAME;
    case ChatStep.requestEmail:
      return STEP_EMAIL;
    case ChatStep.chatQuestion:
    case ChatStep.greeting:
      return STEP_QUESTION;
    case ChatStep.chat:
    case ChatStep.requestVisitorRole:
      return STEP_CHAT;
    case ChatStep.requestIntentSelection:
    case ChatStep.makePrediction:
      return STEP_AUTOMATION;
    case ChatStep.connectingExpert:
      return STEP_CONNECTING_TO_EXPERT;
    case ChatStep.rateBadChat:
      return STEP_RATE_BAD;
    case ChatStep.rateChat:
    case ChatStep.chatEnd:
      return STEP_FINISHED;
    case ChatStep.closeChat:
      return STEP_RATE;
    default:
      return STEP_BOOTING;
  }
};

const getCurrentChatId = () => chatId();
const setCurrentChatId = (value = null) => {
  if (value === chatId()) return;
  chatId(value);
};
const setFeedbackSubmitted = (value) => feedbackSubmitted(value);
const getFeedbackSubmitted = () => feedbackSubmitted;

const setBottomSheetVisible = (value) => bottomSheetVisible(value);
const getBottomSheetVisible = () => bottomSheetVisible;

const getCurrentChatStep = () => chatStep();
const updateChatStep = (value) => {
  if (value === chatStep()) return;
  if (!value) {
    chatStep(null);
    return;
  }
  if (!ChatStep[value]) {
    throw new Error(`You must provide a valid ChatStep, you provided ${value}`);
  }
  chatStep(value);
  step(getStep(value));
  inputType(getInputType(value));
};

const getCurrentChatChannel = () => chatChannel;
const setCurrentChatChannel = (value) => chatChannel(value);

const setInputType = (newInputType) => inputType(newInputType);

export {
  getCurrentChatId,
  setCurrentChatId,
  getCurrentChatStep,
  getCurrentChatChannel,
  setCurrentChatChannel,
  updateChatStep,
  isRunningStep,
  connectionError,
  setFeedbackSubmitted,
  getFeedbackSubmitted,
  setBottomSheetVisible,
  getBottomSheetVisible,
  setInputType,
};
