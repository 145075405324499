import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.primaryVariants[6]};
  border-color: ${({ theme }) => theme.primaryVariants[6]};
  margin-top: 0.75rem;

  &&:focus,
  &&:active,
  &&:hover {
    color: ${({ theme }) => theme.primaryVariants[5]};
    border-color: ${({ theme }) => theme.primaryVariants[5]};
  }
`;

const FeedbackNext = function () {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center' }}>
      <StyledButton htmlType="submit">{t('chat:::feedbackFormButton')}</StyledButton>
    </div>
  );
};

export default FeedbackNext;
