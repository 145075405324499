import React from 'react';
import { useQuery } from '@apollo/client';
import { StarFilled } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import GET_STATS from './queries/getStats';
import Statistic from '../Statistic';

const { Text } = Typography;

const TotalMembers = function () {
  const { partnerId } = useParams();

  const {
    data: {
      community: {
        stats: {
          averageRating,
        } = {},
      } = {},
    } = {},
    loading,
  } = useQuery(GET_STATS, {
    variables: { partnerId },
  });

  return (
    <Statistic
      loading={loading}
      title="Satisfaction"
      value={averageRating?.toFixed(1)}
      suffix={(
        <Space>
          <Text style={{ fontSize: 18 }}>
            /5<StarFilled />
          </Text>
        </Space>
      )}
      decimals={1}
    />
  );
};

export default TotalMembers;
