import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import events from '@guuru/events-web';
import { types, getCurrentChatId } from '@guuru/chat-web';

const chatStart = {
  validate() {
    return !getCurrentChatId();
  },

  * render(partnerId) {
    yield put({ type: types.CHAT_READY, chatId: null, chatControlToken: null });
    yield put({ type: types.CHAT_INIT_SUCCESS, messages: [] });

    ChatAPI.storeAutomationBehavior(partnerId, null);
    ChatAPI.storeResetPendingMessages();

    const { isChatAvailable } = yield ChatAPI.getChatSettings(partnerId);
    const channel = ChatAPI.storeRetrieveChannel(partnerId);
    if (channel === 'chat') {
      if (!isChatAvailable) {
        yield put({
          type: types.CHAT_INIT_SUCCESS,
          messages: [
            ChatAPI.messages.createBotMessage(
              new Date().getTime(),
              'chat:::outOfWorkingHours',
              'message',
              {},
              false,
              false,
            ),
          ],
        });
        yield put({ type: types.CHAT_LOADED });
        return { stayInCurrentStep: true };
      }
    }

    events.startingNew();
    return { goToNextStep: true };
  },
  processResponse() {},

};

export default chatStart;
