import React from 'react';
import { createRoot } from 'react-dom/client';
import { initSentry, Sentry } from '@guuru/sentry-web';
import { ApolloProvider } from '@apollo/client';
import { client } from '@guuru/graphql-web';
import { i18n } from '@guuru/translation-web';
import { I18nextProvider } from 'react-i18next';
import MainView from '../../navigation/MainView';
import 'antd/dist/reset.css';

if (process.env.CHAT_SENTRY_DSN) {
  initSentry(process.env.CHAT_SENTRY_DSN);
}

const CommunityApp = Sentry.withProfiler(() => (
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18n}>
      <MainView />
    </I18nextProvider>
  </ApolloProvider>
));

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<CommunityApp />);
