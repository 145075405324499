import {
  FacebookOutlined,
  FileTextOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { colors } from '@guuru/constants-common';
import { SalesforceOutlined } from '@guuru/react-icon';

const channels = {
  chat: {
    name: 'Chat',
    icon: MessageOutlined,
    value: 'chat',
    chartColor: colors.blue,
  },
  form: {
    name: 'Form',
    icon: FileTextOutlined,
    value: 'form',
    chartColor: colors.yellow,
  },
  facebook: {
    name: 'Facebook',
    value: 'facebook',
    icon: FacebookOutlined,
    chartColor: colors.red,
  },
  salesforce: {
    name: 'Salesforce',
    value: 'salesforce',
    icon: SalesforceOutlined,
    chartColor: colors.orange,
  },
};

const channelsChartConfig = Object.keys(channels)
  .reduce((res, key) => {
    res[key] = { label: channels[key].name };
    return res;
  }, {});

const channelsFilters = Object.values(channels);

export {
  channels,
  channelsChartConfig,
  channelsFilters,
};
