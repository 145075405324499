export const INPUT_TYPE_CHAT = 'INPUT_TYPE_CHAT';
export const INPUT_TYPE_EMAIL = 'INPUT_TYPE_EMAIL';
export const INPUT_TYPE_LOADING = 'INPUT_TYPE_LOADING';
export const INPUT_TYPE_NAME = 'INPUT_TYPE_NAME';
export const INPUT_TYPE_NONE = 'INPUT_TYPE_NONE';
export const INPUT_TYPE_BACK = 'INPUT_TYPE_BACK';
export const INPUT_TYPE_QUESTION = 'INPUT_TYPE_QUESTION';
export const INPUT_TYPE_RESTART = 'INPUT_TYPE_RESTART';
export const STATE_DISABLED = 'disabled';
export const STATE_ENABLED = 'enabled';
export const STEP_BOOTING = 'STEP_BOOTING';
export const STEP_CATEGORY = 'STEP_CATEGORY';
export const STEP_CHAT = 'STEP_CHAT';
export const STEP_CONNECTING_TO_EXPERT = 'STEP_CONNECTING_TO_EXPERT';
export const STEP_AUTOMATION = 'STEP_AUTOMATION';
export const STEP_EMAIL = 'STEP_EMAIL';
export const STEP_FINISHED = 'STEP_FINISHED';
export const STEP_NAME = 'STEP_NAME';
export const STEP_QUESTION = 'STEP_QUESTION';
export const STEP_RATE = 'STEP_RATE';
export const STEP_RATE_BAD = 'STEP_RATE_BAD';
