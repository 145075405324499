import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import events from '@guuru/events-web';
import { storage, types } from '@guuru/chat-web';
import { ChatStep, messageTypes } from '@guuru/constants-common';
import { ExpertsPriority } from '@guuru/expert-common';

const {
  CHAT_NEW_TEXT_SUCCESS,
} = types;

const { isBotTypingVar } = storage;
const { MESSAGE_TYPE_QUICK_ACTION_SELECTION } = messageTypes;

const addQuickAction = function (quickAction) {
  const message = ChatAPI.messages.createAnonymousTextMessage(
    quickAction.name,
    true,
    false,
  );
  ChatAPI.pushPendingMessages(message);
  return message;
};

const addBotRequestQuickActionSelection = function (quickActions) {
  const message = ChatAPI.messages.createBotMessage(
    new Date().getTime(),
    'bot:::requestQuickActionSelection',
    MESSAGE_TYPE_QUICK_ACTION_SELECTION,
    { quickActions },
    true,
    false,
  );
  if (Object.keys(quickActions).length === 0
    && quickActions.constructor === Object) {
    ChatAPI.pushPendingMessages(message);
  }
  return message;
};

const handleEndFlowWithBotMessage = function* (actionCodeMessage, quickAction) {
  const requestChannelMessage = (
    yield addBotRequestQuickActionSelection([])
  );
  yield put({
    type: types.CHAT_NEW_TEXT_REPLACE_LAST,
    message: requestChannelMessage,
  });
  const message = yield addQuickAction(quickAction);
  yield put({ type: CHAT_NEW_TEXT_SUCCESS, message });

  events.quickActionEndFlow();
  const endFlowMessage = yield ChatAPI.addBotMessage(actionCodeMessage);
  yield put({ type: CHAT_NEW_TEXT_SUCCESS, message: endFlowMessage });
};

const handleDirectLink = function (actionCodeMessage) {
  events.quickActionHandleDirectLink();
  const link = document.createElement('a');
  if (actionCodeMessage.startsWith('https://chat.guuru.com')) {
    const locationSearch = window.location.search;
    link.href = `${actionCodeMessage}${locationSearch}`;
  } else {
    link.target = '_blank';
    link.href = actionCodeMessage;
  }
  link.click();
};

const quickActionSelection = {
  validate() {
    const { priority } = ChatAPI.storeRetrieveParams();
    return ![
      ExpertsPriority.bot.value,
      ExpertsPriority.guurusOnly.value,
    ].includes(priority);
  },

  * render(partnerId) {
    const { quickActions } = yield ChatAPI.getChatSettings(partnerId);
    events.requestQuickActionSelection();

    const requestQuickActionSelection = (
      yield addBotRequestQuickActionSelection(quickActions)
    );
    yield put({
      type: CHAT_NEW_TEXT_SUCCESS,
      message: requestQuickActionSelection,
    });
    yield put({ type: types.CHAT_LOADED });
  },

  * processResponse(partnerId, {
    quickAction,
    autoSubmited,
    createChatImmediately,
  }) {
    isBotTypingVar(false);

    if (!autoSubmited) {
      events.quickActionSubmitted(
        `Quick Action: ${quickAction.id} (partnerId: ${partnerId}; name: ${quickAction.name})`,
        quickAction,
      );
    }

    const actionCodeMessage = quickAction.response;
    switch (quickAction.action) {
      case 'endFlowWithBotMessage': {
        yield handleEndFlowWithBotMessage(actionCodeMessage, quickAction);
        return { goToStep: ChatStep.chatEnd };
      }
      case 'requestQuestion': {
        let initialMessages = [];

        if (quickAction?.assignTo) {
          ChatAPI.storeChatPriority(
            quickAction?.expertsPriority || quickAction.assignTo,
          );
        }

        if (!autoSubmited) {
          events.quickActionRequestQuestion();
          const qaGroup = ChatAPI.messages.craeteBotMessage(
            Date.now(),
            'bot:::requestQuickActionSelectionGuurus',
          );
          const qaText = ChatAPI.messages.createAnonymousTextMessage(
            quickAction.name,
            true,
            false,
          );
          ChatAPI.pushPendingMessages(qaGroup);
          ChatAPI.pushPendingMessages(qaText);

          initialMessages = [qaGroup, qaText];

          if (createChatImmediately) {
            yield put({ type: CHAT_NEW_TEXT_SUCCESS, message: qaGroup });
            yield put({ type: CHAT_NEW_TEXT_SUCCESS, message: qaText });
            return { goToNextStep: true };
          }
        }

        yield put({ type: types.CHAT_INIT_SUCCESS, messages: initialMessages });
        const welcomeMessage = ChatAPI.messages.craeteBotMessage(
          Date.now(),
          actionCodeMessage,
        );

        return { nextStepOptions: { welcomeMessage } };
      }
      case 'directLink': {
        handleDirectLink(actionCodeMessage);
        return { stayInCurrentStep: true };
      }
      default:
    }
    return {};
  },
};

export default quickActionSelection;
