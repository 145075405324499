import ApolloClient from './apollo';
import { errorMessage, errorCode, errorType } from './error';

const client = ({
  getCurrentUser = () => null,
  errorCallback = () => null,
  tracingHeaders = () => null,
  defaults = null,
  resolvers = null,
  sha256Fn = null,
  fragments = [],
}) => (
  new ApolloClient({
    onError: errorCallback,
    uri: process.env.CORE_ENDPOINT,
    ws: process.env.CORE_WS_ENDPOINT,
    clientState: {
      defaults: defaults || {},
      resolvers: resolvers || {},
    },
    fragments,
    sha256Fn,
    request: async (operation) => {
      const user = await getCurrentUser();
      if (!user) return;
      if (operation.operationName?.startsWith('GetPersisted')) return;
      const token = await user.getIdToken();
      operation.setContext({
        headers: {
          authorization: `Bearer fat.${token}`,
          ...tracingHeaders(),
        },
      });
    },
    connection: async () => {
      const user = await getCurrentUser();
      if (!user) return {};
      const token = await user.getIdToken();
      return { authorization: `Bearer fat.${token}` };
    },
  })
);

export {
  client,
  errorMessage,
  errorCode,
  errorType,
};
