import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';

const DropzoneActive = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 6em;
  color: white;
  z-index: 9999;
`;

const ChatDropActive = function ({ isDragActive }) {
  return isDragActive
    ? <DropzoneActive><UploadOutlined /></DropzoneActive>
    : null;
};

ChatDropActive.propTypes = {
  isDragActive: PropTypes.bool.isRequired,
};

export default ChatDropActive;
