import { gql } from '@apollo/client';

export default gql`
  mutation RateChatNPS($id: ID!, $rating: NPS!) {
    rateChatNPS(id: $id, rating: $rating) {
      id
      npsRating
    }
  }
`;
