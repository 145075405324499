import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';

const ExpertAvatar = function ({ url = null, size = 36 }) {
  return <Avatar size={size} src={url} />;
};

ExpertAvatar.propTypes = {
  url: PropTypes.string,
  size: PropTypes.number,
};

export default React.memo(ExpertAvatar);
