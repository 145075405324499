import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { makeVar, useReactiveVar } from '@apollo/client';

const scrollRestorationVar = makeVar({});
const setScrollRestoration = (func) => (
  scrollRestorationVar(func(scrollRestorationVar()))
);

const useScrollRestoration = (key) => {
  const scrollRestoration = useReactiveVar(scrollRestorationVar);
  const [element, setElement] = useState(null);
  const ref = useCallback((el) => {
    if (el) {
      setElement(el);
    }
  }, []);

  const currentScrollRestoration = scrollRestoration[key];
  const hasRestoration = key in scrollRestoration;

  useEffect(() => {
    if (!element) return;

    const handleScroll = debounce(() => {
      const { scrollTop, scrollLeft } = element;
      setScrollRestoration((prevScrollRestoration) => ({
        ...prevScrollRestoration,
        [key]: { scrollTop, scrollLeft },
      }));
    }, 100);

    element.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [key, element]);

  useEffect(() => {
    if (!element) return;

    if (hasRestoration) {
      element.scrollTo(
        currentScrollRestoration.scrollLeft,
        currentScrollRestoration.scrollTop,
      );
    } else {
      const initialScrollRestoration = {
        scrollTop: element.scrollTop,
        scrollLeft: element.scrollLeft,
      };

      setScrollRestoration((prevScrollRestoration) => ({
        ...prevScrollRestoration,
        [key]: initialScrollRestoration,
      }));
    }
  }, [
    currentScrollRestoration,
    element,
    key,
    hasRestoration,
  ]);

  return { ref };
};

export default useScrollRestoration;
