import { gql } from '@apollo/client';

export default gql`
  mutation SetExpertIsTyping($id: ID!, $value: Boolean!) {
    setExpertIsTyping(id: $id, value: $value) {
      id
      expertIsTyping
    }
  }
`;
