import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Sentry } from '@guuru/sentry-web';
import rootReducer from '../reducers/_root';
import rootSaga from '../sagas/index';

//  Returns the store instance
// It can  also take initialState argument when provided
const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const sentryEnhancer = Sentry.createReduxEnhancer();
  return {
    ...createStore(
      rootReducer,
      compose(applyMiddleware(sagaMiddleware), sentryEnhancer),
    ),
    runSaga: sagaMiddleware.run(rootSaga),
  };
};

export default configureStore;
