import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Col, Form, Row,
} from 'antd';
import FeedbackTag from './FeedbackTag';

const FeedbackTagsList = function ({ topics }) {
  const FormItem = Form.Item;
  return (
    <FormItem
      name="feedbackTags"
      initialValue={[]}
      valuePropName="checked"
      style={{ margin: 0 }}
    >
      <Checkbox.Group style={{ display: 'flex', justifyContent: 'center' }}>
        <Row gutter={[16, 16]} style={{ width: '100%' }}>
          {topics.map(({ id, title }) => (
            <Col key={id} style={{ textAlign: 'center' }} span={12}>
              <FeedbackTag id={id} title={title} />
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </FormItem>
  );
};

FeedbackTagsList.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.objectOf(
      PropTypes.string,
    ).isRequired,
  })).isRequired,
};

export default FeedbackTagsList;
