import { gql } from '@apollo/client';

export default gql`
  query GetChat(
    $id: ID!,
    $isVisibleByExpert: Boolean,
    $isVisibleByUser: Boolean,
    $chatControlToken: String,
  ) {
    chat(id: $id, chatControlToken: $chatControlToken) {
      ...ChatPublicFields
      messages(
        isVisibleByExpert: $isVisibleByExpert
        isVisibleByUser: $isVisibleByUser
        orderBy: {
          direction: asc
          field: createdAt
        }
      ) {
        edges {
          node {
            ...MessageFields
          }
        }
      }
    }
  }
`;
