import { createGlobalStyle } from 'styled-components';

const InvitationGlobalStyle = createGlobalStyle`
  ${({ font }) => font && font !== 'system-ui' && (
    `@font-face {
      font-family: PartnerFont;
      src: ${/^https?:\/\//.test(font) ? `url(${font})` : `local(${font})`};
    }`
  )}

  html {
    ${({ font }) => font === 'system-ui' && (`
      @supports (font: -apple-system-body) and (-webkit-touch-callout: default) {
        font: -apple-system-body;
      }
    `)}
  }
`;

export default InvitationGlobalStyle;
