import styled, { css } from 'styled-components';
import { Col } from 'antd';

const RateButton = styled(Col)`
  &:hover {
    cursor: pointer;
  }

  ${({ $selected }) => !$selected && css`
    filter: grayscale(100%);
  `}

  color: ${({ $rating }) => {
    switch ($rating) {
      case 'average':
        return '#f4b414';
      case 'bad':
        return '#e44646';
      default:
        return '#42c694';
    }
  }}
`;

export default RateButton;
