import { localesData } from '@guuru/constants-common';

import {
  ApiOutlined,
  CheckCircleOutlined,
  DisconnectOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

const confirmed = {
  key: 'confirmed',
  name: 'Confirmed',
  icon: CheckCircleOutlined,
};

const pending = {
  key: 'pending',
  name: 'Pending',
  icon: QuestionCircleOutlined,
};

const notSet = {
  key: 'notSet',
  name: 'New',
  icon: MinusCircleOutlined,
};

const subscribed = {
  key: 'subscribed',
  name: 'Subscribed',
  icon: ApiOutlined,
};

const unsubscribed = {
  key: 'unsubscribed',
  name: 'Unsubscribed',
  icon: DisconnectOutlined,
};

const QuestionFilters = {
  intentId: {
    key: 'intentId',
  },
  language: {
    key: 'language',
    label: 'Language',
    type: 'language',
    filters: Object.entries(localesData).map(([key, { name }]) => (
      { name, value: key }
    )),
  },
  searchText: {
    key: 'searchText',
  },
  industry: {
    key: 'industry',
    label: 'Industry',
  },
  partner: {
    key: 'partner',
    label: 'Partner',
    type: 'partner',
    getFilters: ({ partners }) => (
      partners.map(({ node }) => (
        { name: node.name.en, value: node.id }
      ))
    ),
  },
  notSet,
  pending,
  confirmed,
  subscribed,
  unsubscribed,
  filterBy: {
    key: 'filterBy',
    label: 'Show Only',
    getFilters: ({ subscribedIntents }) => {
      const baseFilters = [
        notSet,
        pending,
        confirmed,
      ];

      if (subscribedIntents && subscribedIntents.length > 0) {
        baseFilters.push({ ...subscribed, value: subscribedIntents });
        baseFilters.push({ ...unsubscribed, value: subscribedIntents });
      }
      return baseFilters;
    },
  },
};

export default QuestionFilters;
