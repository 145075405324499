import { gql } from '@apollo/client';

const communityExpertFragment = gql`
  fragment CommunityExpertFragment on CommunityExpert {
    id
    externalId
    bio
    photoUrl
    name
    joinedAt
    rating
  }
`;

export default communityExpertFragment;
