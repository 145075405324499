import styled from 'styled-components';
import { Form } from 'antd';

export default styled(Form)`
  display: flex;
  justify-content: center;
  align-items: ${({ theme }) => theme.chatAlignment};
  flex-direction: column;
  padding: ${({ theme }) => (theme.chatAlignment === 'center' ? '0 8px' : undefined)};

  && textarea {
    font-size: 1rem;
    font-weight: 200;
    padding: 0.75rem;

    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  button {
    box-shadow: none;
  }

  .ant-input-disabled {
    color: rgba(0 0 0 / 70%);
    background: #f8f8f8;
  }
`;
