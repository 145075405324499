import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { makeVar } from '@apollo/client';
import PropTypes from 'prop-types';
import HistoryContext from '../context/historyContext';

const historyPointerVar = makeVar('global');
const setHistoryPointer = (value) => historyPointerVar(value);

const RouteHistory = function ({ children }) {
  const records = useRef({}).current;
  const [currentHistory, setCurrentHistory] = useState([]);
  const route = useLocation();
  const context = useMemo(() => ({
    setHistoryPointer,
    records,
    history: currentHistory,
  }), [currentHistory, records]);

  useEffect(() => {
    const key = historyPointerVar();
    records[key] = records[key] || [];
    const history = records[key];

    const currentRouteIndex = window.history.state?.idx ?? null;
    const lastRouteIndex = history[history.length - 1]?.idx ?? null;

    if (currentRouteIndex < lastRouteIndex) {
      history.pop();
    }

    if (currentRouteIndex > lastRouteIndex || lastRouteIndex === null) {
      const { search, pathname } = route;

      history.push({
        pathname: `${pathname}${search}`,
        idx: currentRouteIndex,
      });
    }

    setCurrentHistory([...history]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return (
    <HistoryContext.Provider value={context}>
      {children}
    </HistoryContext.Provider>
  );
};

RouteHistory.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RouteHistory;
