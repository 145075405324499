import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const useScrollToBottom = () => {
  const containerRef = useRef(undefined);
  const scrollAnchorRef = useRef(undefined);
  const scrollLocked = useRef(true);
  const [isNearBottom, setNearBottom] = useState(false);

  const scrollToBottom = () => {
    if (!isNearBottom && scrollAnchorRef.current && containerRef.current) {
      containerRef.current.scrollTo({ top: scrollAnchorRef.current.offsetTop });
    }
  };

  const isAnchorVisible = () => (
    scrollAnchorRef.current.getBoundingClientRect().top
    <= containerRef.current.getBoundingClientRect().bottom
  );

  const handleScroll = useCallback(() => {
    if (scrollAnchorRef.current && !isAnchorVisible()) {
      scrollLocked.current = false;
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    scrollToBottom();

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setNearBottom(true);
          scrollLocked.current = true;
        } else {
          setNearBottom(false);
          if (scrollAnchorRef.current && scrollLocked.current) {
            containerRef.current
              ?.scrollTo({ top: scrollAnchorRef.current.offsetTop });
          }
        }
      },
      { root: containerRef.current },
    );

    observer.observe(scrollAnchorRef.current);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    scrollToBottom,
    isNearBottom,
    setContainerRef: containerRef,
    setScrollAnchorRef: scrollAnchorRef,
  };
};

export default useScrollToBottom;
