const BotSettings = {
  BOT_ID: 0,
  BOT_UID: 'bot',
  BOT_NAME: '', // "Chat Bot",
  BOT_COVER_IMAGE: 'https://cdn.guuru.com/assets/logo/guuru_logo.svg',
  BOT_TYPING_DELAY_MIN: 100,
  BOT_TYPING_DELAY_MAX: 200,
  BOT_TYPING_ELLIPSIS_MIN: 500,
  BOT_TYPING_ELLIPSIS_MAX: 1000,
};

module.exports = BotSettings;
