import styled from 'styled-components';
import { Menu } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const Bar = styled(Menu)`
  box-shadow: 0 2px 4px 0 rgba(0 0 0 / 50%);
  display: ${({ $showNavigation }) => ($showNavigation ? 'flex' : 'none')};
  justify-content: center;
  line-height: 24px;
  column-gap: 24px;
  z-index: 15;

  &::before {
    content: unset;
  }

  .ant-menu-item {
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 16px 0 10px 0;
    color: gray;
    width: 100px;
  }

  .ant-menu-title-content {
    margin-inline-start: 0 !important;

  }

  .anticon.ant-menu-item-icon {
    font-size: 22px;
  }

  .ant-menu-item-selected {
    font-weight: 700;
  }

  .ant-menu-item-selected::after {
    content: unset !important;
  }

  .ant-menu-item:hover::after {
    content: unset !important;
  }

  .ant-menu-item::after {
    content: unset !important;
  }
`;
