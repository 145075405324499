import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import events from '@guuru/events-web';
import { types } from '@guuru/chat-web';
import { ChatStep } from '@guuru/constants-common';

// Handles greeting,
export default function* () {
  events.greeting();
  ChatAPI.storeQuestion();

  const message = yield ChatAPI.addBotMessage(
    'chat:::greeting',
    true,
  );
  yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
  return {
    goToStep: ChatStep.chatQuestion,
  };
}
