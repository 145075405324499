import { gql } from '@apollo/client';
import communityExpertFragment
  from '../../../../graphql/fragments/communityExpertFragment';

export default gql`
  ${communityExpertFragment}
  query GetPersistedExperts(
    $partnerId: ID!,
    $first: Int,
    $after: String,
    $loadMore: Boolean!,
  ) {
    community(id: $partnerId) {
      id
      experts(first: $first, after: $after) @connection(key: $first){
        edges {
          node {
            ...CommunityExpertFragment
          }
        }
        pageInfo {
          hasNextPage @include(if: $loadMore)
          endCursor @include(if: $loadMore)
        }
      }
    }
  }
`;
