import { ChatAPI } from '@guuru/api-web';
import events from '@guuru/events-web';
import { client } from '@guuru/graphql-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import { messageTypes } from '@guuru/constants-common';
import GET_PARTNER from './queries/partner';

const { MESSAGE_TYPE_WELCOME } = messageTypes;

const WELCOME = 'bot:::welcome';

const createIntroWelcome = (message) => {
  const msg = ChatAPI.messages.createBotMessage(
    new Date().getTime(),
    message,
    MESSAGE_TYPE_WELCOME,
    {},
    true,
    false,
  );
  ChatAPI.pushPendingMessages(msg);
  return msg;
};

const getWelcomeMessage = async (partnerId) => {
  const { locale, mId } = ChatAPI.storeRetrieveParams();
  const {
    data: {
      partner: {
        autoMessage: { content = {} } = {},
      },
    },
  } = await client.query({
    query: GET_PARTNER,
    variables: { id: partnerId, autoMessageId: mId },
  });

  const autoMessageContent = content && content[locale];
  if (autoMessageContent) {
    return createIntroWelcome(autoMessageContent);
  }

  return createIntroWelcome(WELCOME);
};

export default {
  validate() {
    return true;
  },

  * render(partnerId, { welcomeMessage }) {
    events.welcome();
    let message;
    if (welcomeMessage) {
      message = welcomeMessage;
      ChatAPI.pushPendingMessages(message);
    } else {
      message = yield getWelcomeMessage(partnerId);
    }
    yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
    return { goToNextStep: true };
  },

  processResponse() {},

};
