import { makeVar } from '@apollo/client';

/**
 * State containers aka reactive vars should be defined here.
 * note: don't call them directly in react components, good practices says
 * to use them inside custom hooks or call them through useReactiveVar hook.
 * */
const isBotTypingVar = makeVar();
const chatId = makeVar(null);
const chatStep = makeVar(null);
const chatChannel = makeVar(null);
const step = makeVar(null);
const inputType = makeVar(null);
const isRunningStep = makeVar(false);
const connectionError = makeVar(false);
const feedbackSubmitted = makeVar(false);
const bottomSheetVisible = makeVar(false);
const isChatIframedVar = makeVar(window.self !== window.top);

export default {
  isBotTypingVar,
  chatId,
  chatStep,
  chatChannel,
  isRunningStep,
  connectionError,
  step,
  inputType,
  feedbackSubmitted,
  isChatIframedVar,
  bottomSheetVisible,
};
