const originalPageTitle = document.title;
const originalFaviconUrl = 'https://cdn.guuru.com/assets/logo/favicon.ico';

const updateFaviconAndTitle = (unreadMessagesCount) => {
  let title = originalPageTitle;
  let faviconUrl = originalFaviconUrl;
  if (unreadMessagesCount) {
    const count = unreadMessagesCount <= 9 ? unreadMessagesCount : '9+';
    faviconUrl = 'https://cdn.guuru.com/assets/logo/favicon_dot.png';
    title = `(${count}) ${originalPageTitle}`;
  }
  document.querySelector("link[rel='shortcut icon']").href = faviconUrl;
  document.title = title;
};

const usePageCounter = () => {
  const resetPageCount = () => {
    updateFaviconAndTitle();
  };

  const updatePageCount = (unreadMessagesCount) => {
    updateFaviconAndTitle(unreadMessagesCount);
  };

  return {
    resetPageCount,
    updatePageCount,
  };
};

export default usePageCounter;
