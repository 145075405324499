import styled from 'styled-components';
import { Typography } from 'antd';
import { InputSection } from '@guuru/react-chatwidget';
import { ChatRowWrapper } from '@guuru/react-chat-messages';

const { Text } = Typography;

export const Welcome = styled(ChatRowWrapper)`
  min-height: 65px;
  align-items: center;
  background-color: ${({ theme }) => (theme.isFullScreen ? theme.primaryColor : 'inherit')};
`;

export const WelcomeText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 38px;
  font-weight: 300;
  flex-direction: column;
  color: ${({ theme }) => (theme.isFullScreen ? theme.onSecondaryColor : 'black')};
  text-align: ${({ theme }) => theme.chatAlignment ?? 'center'};
`;

export const BottomBar = styled(InputSection)`
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: inherit;
`;
