import React, { useEffect } from 'react';
import events from '@guuru/events-web';
import { Outlet, useLocation } from 'react-router-dom';
import { useChatSettings } from '@guuru/chat-web';
import {
  Col, ConfigProvider, Flex, Row,
} from 'antd';
import { PoweredBy } from '@guuru/react-powered-by';
import { useTheme } from 'styled-components';
import CommunityHeader from './CommunityHeader';
import useScrollRestoration
  from '../../../../navigation/hooks/useScrollRestoration';

const CommunityLayout = function () {
  const token = useTheme();
  const {
    data: {
      chatSettings: {
        id: partnerId,
        chatWidgetPrimaryColor,
      },
    },
  } = useChatSettings();

  const { pathname } = useLocation();
  const { ref } = useScrollRestoration(pathname);

  useEffect(() => {
    events.communityLoaded();
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: token.primaryColor,
        },
        components: {
          Statistic: {
            titleFontSize: 14,
            contentFontSize: 28,
          },
        },
      }}
    >
      <meta name="theme-color" content={chatWidgetPrimaryColor} />
      <CommunityHeader />
      <Row ref={ref} justify="center" style={{ overflow: 'scroll', padding: '16px 0' }}>
        <Col xs={22} sm={22} md={20}>
          <Flex align="center" vertical gap={36}>
            <Outlet />
            <PoweredBy
              url={`https://www.guuru.com/?utm_source=chat&utm_medium=chat&utm_campaign=community&utm_term=${partnerId}&utm_content=${partnerId}`}
            />
          </Flex>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default CommunityLayout;
