import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

const HELPER = {
  getQuery(resultSet) {
    return resultSet?.query() || {};
  },

  getData(resultSet) {
    return resultSet?.rawData() || [];
  },

  getTimeDimensions(resultSet) {
    return this.getQuery(resultSet)?.timeDimensions || [];
  },

  getGranularity(resultSet) {
    return this.getTimeDimensions(resultSet)[0]?.granularity;
  },

  getDateRange(resultSet) {
    return this.getTimeDimensions(resultSet)[0]?.dateRange || [];
  },

  getTimezone(resultSet) {
    return this.getQuery(resultSet)?.timezone;
  },

  resolveFilters(filters, resolvers, parameters) {
    return (filters || []).reduce((resolvedSet, { key, value }) => ({
      filters: [
        ...resolvedSet.filters,
        ...resolvers[key]?.resolve(value, parameters).filters || [],
      ],
      segments: [
        ...resolvedSet.segments,
        ...resolvers[key]?.resolve(value, parameters).segments || [],
      ],
    }), {
      filters: [],
      segments: [],
    });
  },

  merge(baseQuery, dateRange, filterSet = {}, granularity = undefined) {
    const {
      filters = [],
      segments = [],
    } = filterSet;

    let timeDimensions = baseQuery?.timeDimensions
      ? [
        {
          ...baseQuery?.timeDimensions?.[0],
          ...(dateRange ? { dateRange, granularity } : {}),
        },
      ] : [];

    timeDimensions = timeDimensions.filter((v) => !isEmpty(v));

    const { ignoreMerge, ...query } = baseQuery;

    return {
      ...query,
      filters: ignoreMerge
        ? query?.filters
        : [...query?.filters || [], ...filters],
      segments:
        ignoreMerge
          ? query?.segments
          : [...query?.segments || [], ...segments],
      timeDimensions,
    };
  },

  removeSegments(baseQuery, segmentsToRemove) {
    return {
      ...baseQuery,
      segments: baseQuery.segments.filter((s) => !segmentsToRemove.includes(s)),
    };
  },

  formatDateRange(dataRange) {
    if (!dataRange) {
      return null;
    }
    const [start, end] = dataRange;
    return [
      moment(start).format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
      moment(end).format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
    ];
  },

};

export default HELPER;
