import { ChatAPI } from '@guuru/api-web';
import events from '@guuru/events-web';

const getChatSource = () => {
  const { refererTrigger } = ChatAPI.storeRetrieveParams();
  return refererTrigger || 'Direct';
};

const chatInit = {
  validate() {
    return true;
  },

  * render(partnerId) {
    const partner = yield ChatAPI.getChatSettings(partnerId);
    const chatCategory = ChatAPI.storeRetrieveCategory();
    const locale = ChatAPI.storeRetrieveLocale();
    const chatChannel = ChatAPI.storeRetrieveChannel(partnerId);
    const chatSource = getChatSource();
    events.setAnalyticsProperties({
      locale,
      partner: partnerId,
      account: partner.account,
      chatCategory,
      chatChannel,
      chatSource,
    });

    return { goToNextStep: true };
  },
  processResponse() {},

};

export default chatInit;
