import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import GuuruSvg from './GuuruOutlined.svg';

const GuuruOutlined = function (props, ref) {
  return (
    <Icon
      aria-label="guuru"
      component={GuuruSvg}
      ref={ref}
      {...props}
    />
  );
};

export default React.forwardRef(GuuruOutlined);
