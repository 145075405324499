import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';

const OpenDialogIcon = function ({
  loading = false,
  onClick = () => {},
  className = '',
}) {
  const Icon = loading ? LoadingOutlined : SmileOutlined;

  return (
    <Icon spin={loading} className={className} onClick={onClick} />
  );
};

OpenDialogIcon.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default OpenDialogIcon;
