import * as Sentry from '@sentry/browser';

const logger = {
  log(message, payload = {}, severity = 'info') {
    console[severity](
      message,
      Object.values(payload).filter((v) => v).join(', '),
    );
  },

  debug(message, payload = {}) {
    this.log(message, payload, 'debug');
  },

  info(message, payload = {}) {
    this.log(message, payload, 'info');
  },

  warn(message, payload = {}) {
    this.log(message, payload, 'warn');
  },

  error(error) {
    Sentry.captureException(error);
    console.error(error);
  },
};

export default logger;
