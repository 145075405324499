import React from 'react';
import PropTypes from 'prop-types';
import { BottomSheet } from '@guuru/react-chatwidget';
import ExpertProfile from './Profile';

const ExpertProfileSheet = function ({ chatId = undefined }) {
  if (!chatId) {
    return null;
  }

  return (
    <BottomSheet>
      <ExpertProfile chatId={chatId} />
    </BottomSheet>
  );
};

ExpertProfileSheet.propTypes = {
  chatId: PropTypes.string,
};

export default ExpertProfileSheet;
