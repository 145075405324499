import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import Compressor from 'compressorjs';
import UPLOAD_PARTNER_PICTURE from './mutations/uploadPartnerPicture';
import DELETE_PARTNER_PICTURE from './mutations/deletePartnerPicture';
import uploadGCSSignedUrl from '../uploadGCSSignedUrl';

const usePartnerPictureSubmit = function ({
  id,
  onProgress = () => {},
  onUpload = () => {},
  onError = () => {},
  onFinish = () => {},
}) {
  const [uploadPartnerPicture] = useMutation(UPLOAD_PARTNER_PICTURE);
  const [deletePartnerPicture] = useMutation(DELETE_PARTNER_PICTURE);

  const handleSubmit = useCallback(async (file, type, options = {}) => {
    if (!file) return null;

    if (!/image\/*/.test(file.type)) {
      onError('Oops, you can only upload images.');
      return null;
    }

    onProgress();

    try {
      const blob = await new Promise((resolve, reject) => (
        // eslint-disable-next-line no-promise-executor-return
        new Compressor(file, {
          quality: 0.7,
          mimeType: file.type,
          maxWidth: 512,
          maxHeight: 512,
          ...options,
          success: resolve,
          error: reject,
        })
      ));

      const {
        data: {
          uploadPartnerPicture: {
            writeUrl,
            readUrl,
            maxBytes,
            acl,
          },
        },
      } = await uploadPartnerPicture({
        variables: {
          id,
          input: {
            type,
            mimetype: file.type,
          },
        },
      });

      if (blob.size > maxBytes) {
        throw new Error('The picture you are trying to upload is too big');
      }

      await uploadGCSSignedUrl({
        contentType: file.type,
        writeUrl,
        blob,
        maxBytes,
        acl,
      });

      await onUpload(readUrl);
      onFinish();
      return readUrl;
    } catch (err) {
      onError(err.message);
      return null;
    }
  }, [
    id,
    uploadPartnerPicture,
    onError,
    onUpload,
    onFinish,
    onProgress,
  ]);

  const handleDelete = useCallback(async (url, type) => {
    await deletePartnerPicture({
      variables: {
        id,
        input: { url, type },
      },
    });
  }, [id, deletePartnerPicture]);

  return {
    uploadPartnerPicture: handleSubmit,
    deletePartnerPicture: handleDelete,
  };
};

export default usePartnerPictureSubmit;
