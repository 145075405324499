import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Bot,
  BotSettings,
  DEFAULT_AVATAR_PHOTO,
} from '@guuru/constants-common';
import { Col, Space } from 'antd';
import { MarkdownRender } from '@guuru/react-markdown-render';
import { ExpertAvatar } from '@guuru/react-chat-avatar';
import Wrapper from './styles';
import SubTitle from './SubTitle';
import useBottomSheet from '../../../hooks/useBottomSheet';

const getFormatedHeadline = (headline) => (
  headline.startsWith('#') ? headline : `# ${headline}`
);

const Headline = function ({ chat = {} }) {
  const { t } = useTranslation();
  const { openBottomSheet } = useBottomSheet();
  const { expert, isAssignedToAgent: isAgent } = chat;
  const expertName = expert?.name;
  const expertAvatar = expert?.photoUrl ?? DEFAULT_AVATAR_PHOTO;
  const isBot = expert?.id === BotSettings.BOT_UID;
  const isGptBot = expert?.id === Bot.BOT_UID;
  const isGuuru = !isAgent && !isGptBot && !isBot && expertName;

  const subtitle = () => {
    if (isAgent) return t('chat:::headlineAgent');
    if (isGptBot) return t('chat:::headlineGptBot');
    if (isGuuru) return t('chat:::headlineGuuru');
    return null;
  };

  const title = (!expertName || isBot)
    ? t('chat:::headline')
    : expertName;

  return (
    <Wrapper wrap={false} onClick={isGuuru ? openBottomSheet : null}>
      <Space size={6}>
        {(!isBot && (expertAvatar !== DEFAULT_AVATAR_PHOTO)) && (
          <Col flex="none">
            <ExpertAvatar url={expertAvatar} size={48} />
          </Col>
        )}
        <Col flex="auto">
          <MarkdownRender content={getFormatedHeadline(title)} />
          <SubTitle text={subtitle()} />
        </Col>
      </Space>
    </Wrapper>
  );
};

Headline.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string,
    expert: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      photoUrl: PropTypes.string,
    }),
    isAssignedToAgent: PropTypes.bool,
  }),
};

export default Headline;
