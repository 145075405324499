import { gql } from '@apollo/client';

const GET_CHAT = gql`
  query GetChatSmsFallback($id: ID!) {
    chat(id: $id){
      id
      status
      rating
    }
  }
`;

export default GET_CHAT;
