import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const RateExpertHeadline = styled(Text)`
  display: block;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 400;
  margin: 0 4px;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const RateExpertTeaser = styled(Text)`
  font-size: 1rem;
  text-align: center;
  opacity: 0.8;
  margin: 0;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const RateExpertLabel = styled.span`
  line-height: 28px;
  font-size: 18px;
`;

export const RateExpertActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const RateExpertAction = styled.div`
  min-width: 76px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 8px 10px 3px;
  margin: 8px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  box-shadow: 0 4px 6px rgba(50 50 93 / 11%), 0 1px 3px rgba(0 0 0 / 8%);
  transition: transform 0.25s, box-shadow 0.25s;
  color: ${({ $color }) => $color};

  &:hover {
    box-shadow: 0 1px 14px rgba(0 0 0 / 30%);
    transform: translateY(-1px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
`;
