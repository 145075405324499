import React from 'react';
import { Button, Result } from 'antd';
import { Sentry } from '@guuru/sentry-web';
import Icon from '@ant-design/icons/lib/components/Icon';
import ImageSvg from './Error_500.svg';

const ErrorPage = function () {
  return (
    <Result
      icon={(
        <Icon component={ImageSvg} style={{ fontSize: '300px' }} />
      )}
      status={404}
      title="Oops"
      subTitle="An unexpected error seems to have occurred."
      extra={(
        <Button type="primary" onClick={() => Sentry.showReportDialog()}>
          Report feedback
        </Button>
    )}
    />
  );
};

export default ErrorPage;
