import { types } from '@guuru/chat-web';
import initialState from './_initialState';

// eslint-disable-next-line default-param-last
export default function (state = initialState.chat, action) {
  let newState = { ...state };

  switch (action.type) {
    case types.CHAT_LOADING:
      newState = { ...state };
      newState.loading = true;
      return newState;

    case types.CHAT_LOADED:
      newState = { ...state };
      newState.loading = false;
      return newState;

    case types.CHAT_INIT_SUCCESS:
      newState = { ...state };
      newState.messages = [...action.messages];
      return newState;

    case types.CHAT_NEW_TEXT_SUCCESS:
      newState = { ...state };
      newState.messages = [...newState.messages, action.message];

      return newState;

    case types.CHAT_READY:
      newState = { ...state };
      newState.chatId = action.chatId;
      newState.chatControlToken = action.chatControlToken;
      newState.acceptedAt = action.acceptedAt;
      newState.messages = action.messages?.length
        ? [...action.messages]
        : [...newState.messages];
      return newState;

    case types.CHAT_LOAD_STARTING:
      newState = { ...state };
      newState.chatId = action.chatId;
      newState.chatControlToken = action.chatControlToken;
      return newState;

    case types.CHAT_NEW_TEXT_REPLACE_LAST:
      newState = { ...state };
      newState.messages[newState.messages.length - 1] = action.message;
      newState.messages = [...newState.messages]; // Force refresh
      return newState;

    default:
      return state;
  }
}
