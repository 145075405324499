import { gql } from '@apollo/client';

export default gql`
  mutation ProvideUserEmail($chatId: ID!, $email: String!, $isHotTransfer: Boolean) {
    provideUserEmail(chatId: $chatId, email: $email, isHotTransfer: $isHotTransfer) {
      id
      email
    }
  }
`;
