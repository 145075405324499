import React from 'react';
import PropTypes from 'prop-types';
import HTMLMessage from '../../HtmlMessage';
import MessageTooltip from '../../MessageTooltip';

const BotAnswer = function ({ message }) {
  return (
    <MessageTooltip message={message}>
      <HTMLMessage message={message} />
    </MessageTooltip>
  );
};

BotAnswer.propTypes = {
  message: PropTypes.shape({
    i18n: PropTypes.string,
    createdAt: PropTypes.number,
  }).isRequired,
};

export default BotAnswer;
