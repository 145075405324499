const SalesforceIntegration = {
  SALESFORCE_AGENT_ID: -2,
  SALESFORCE_UID: 'salesforce',
  SALESFORCE_IP: 'cf80:0830:e098:bb59:93a7:9a3b::7303',
  AUTHENTICATION_URLS: [
    'https://login.salesforce.com',
    'https://test.salesforce.com',
  ],
};

module.exports = SalesforceIntegration;
