import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { colors, localesData } from '@guuru/constants-common';

const LanguageFlag = function ({
  languageCode = null,
  showLanguage = false,
  showAll = false,
  size = '14px',
  tooltipPreffix = '',
  className = '',
  style = undefined,
}) {
  if (!languageCode) {
    return (
      <WarningTwoTone twoToneColor={colors.yellow} />
    );
  }
  const language = localesData[languageCode.toLowerCase()]?.name;
  if (showAll) {
    return (
      <Tooltip title={language ? `${tooltipPreffix}${language}` : ''}>
        <span className={className}>
          {` ${language}`}
        </span>
      </Tooltip>
    );
  }
  if (showLanguage) {
    return language;
  }
  return (
    <Tooltip title={language ? `${tooltipPreffix}${language}` : ''}>
      <span className={className}>
        <Typography.Text style={{ fontSize: size, ...style }} type="secondary">
          {language}
        </Typography.Text>
      </span>
    </Tooltip>
  );
};

LanguageFlag.propTypes = {
  languageCode: PropTypes.string,
  showLanguage: PropTypes.bool,
  showAll: PropTypes.bool,
  size: PropTypes.string,
  tooltipPreffix: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

export default LanguageFlag;
