import { message, Modal, notification as antNotification } from 'antd';

message.config({
  maxCount: 1,
});

antNotification.config({
  duration: 7,
});

export const info = (content) => {
  message.info(content);
};

// eslint-disable-next-line default-param-last
export const success = (content, modal = false, onOk) => {
  if (modal) {
    message.destroy();
    Modal.success({
      title: 'Success',
      content,
      onOk,
    });
  } else {
    message.success(content);
  }
};

export const error = (content, modal = true) => {
  const errorMessage = content?.graphQLErrors
    ?.map(({ message: msg }) => msg)
    .join('<br>')
    || content?.message
    || content;

  if (modal) {
    message.destroy();
    Modal.error({
      title: 'Oops, something went wrong...',
      content: errorMessage,
    });
  } else {
    message.error(errorMessage);
  }
};

export const warning = (content) => {
  message.warning(content);
};

export const loading = (content, duration) => {
  message.loading(content, duration || 0);
};

export const destroy = () => {
  message.destroy();
};

export const notification = (title, description, type = 'success') => {
  message.destroy();
  antNotification[type]({ message: title, description });
};
