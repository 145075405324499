import { gql } from '@apollo/client';

export default gql`
  query IsAdviceQuestion($partnerId: ID!, $question: String!, $languageCode: String!) {
    partner(id: $partnerId) {
      id
      isAdviceQuestion(question: $question, languageCode: $languageCode)
    }
  }
`;
