import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import DesktopOutlined from '@ant-design/icons/lib/icons/DesktopOutlined';
import MobileOutlined from '@ant-design/icons/lib/icons/MobileOutlined';
import Tags from './Tags';
import Icon from './Icon';

const ExpertNotificationsTags = function ({
  expert,
  showEmailVerified = false,
}) {
  return (
    <Tags>
      {showEmailVerified && (
        <Tooltip title="Email Verified">
          <Icon
            as={MailOutlined}
            $enabled={showEmailVerified && expert.isEmailVerified}
          />
        </Tooltip>
      )}
      <Tooltip title="Web Notifications">
        <Icon as={DesktopOutlined} $enabled={expert?.pushTokenWeb} />
      </Tooltip>
      <Tooltip title="Mobile Notifications">
        <Icon as={MobileOutlined} $enabled={expert?.pushToken} />
      </Tooltip>
    </Tags>
  );
};

ExpertNotificationsTags.propTypes = {
  expert: PropTypes.shape({
    isEmailVerified: PropTypes.bool,
    pushToken: PropTypes.string,
    pushTokenWeb: PropTypes.string,
  }).isRequired,
  showEmailVerified: PropTypes.bool,
};

export default ExpertNotificationsTags;
