import { useChatSettings } from '@guuru/chat-web';
import log from '@guuru/logger-web';
import { ChatAPI } from '@guuru/api-web';
import { LocaleHelper } from '@guuru/helpers-common';

const useInitChatSettings = function (partnerId) {
  const {
    data: {
      chatSettings: {
        name,
        organization,
        language,
      } = {},
    } = {},
    loading,
  } = useChatSettings(partnerId);

  if (loading) {
    return { loading: true };
  }

  if (!name && !organization) {
    log.info(`Partner ${partnerId} not found`);
    return { partnerId: undefined, loading: false };
  }

  if (!organization) {
    ChatAPI.storePartnerId(partnerId);
  }
  LocaleHelper.storeSystemLocale(language);
  ChatAPI.storeLocale(language);

  return { partnerId, loading: false };
};

export default useInitChatSettings;
