import { gql } from '@apollo/client';

export default gql`
  subscription ExpertIsTyping($chatId: ID!) {
    expertIsTyping(id: $chatId) {
      id
      expertIsTyping
    }
  }
`;
