import React from 'react';
import PropTypes from 'prop-types';
import { Statistic as AntStatistic } from 'antd';
import CountUp from 'react-countup';
import styled from 'styled-components';

const Stat = styled(AntStatistic)`
  display: flex;
  flex-direction: column-reverse;

  .ant-statistic-content-value, .ant-typography {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const Statistic = function ({
  loading,
  title,
  value = 0,
  decimals = undefined,
  suffix = undefined,
}) {
  const formatter = () => <CountUp end={value} decimals={decimals} separator="," />;

  return (
    <Stat
      loading={loading}
      title={title}
      value={value}
      suffix={suffix}
      decimals={decimals}
      formatter={formatter}
      valueStyle={{
        fontWeight: 500,
      }}
    />
  );
};

Statistic.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimals: PropTypes.number,
  suffix: PropTypes.node,
};

export default Statistic;
