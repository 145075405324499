import styled from 'styled-components';
import { Typography, Row, Space } from 'antd';

const { Text } = Typography;

export const RateExpertBadTeaserWrapper = styled(Space)`
  text-align: center;
  font-weight: 300;
  margin-left: 16px;
  margin-right: 16px;
`;

export const RateExpertBadTeaserTop = styled(Text)`
  font-size: 18px;
  margin-bottom: 0;
  font-weight: normal;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const RateExpertBadTeaserBottom = styled(Text)`
  font-size: 14px;
  margin-bottom: 0;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const EndNoEmailWrapper = styled(Row)`
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const RateBadExpertEndNoMail = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const RateBadExpertEndNoMailUnderline = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const RateBadExpertWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;
