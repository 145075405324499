/**
 * Note: This file is used directly by webpack and, as such, must continue
 * to use commonjs style modules until esm is supported by node natively.
 */
const {
  locales,
  localesData,
  mobileLocales,
  languageToCountryMapping,
  languageMap,
} = require('./src/locales');
const endpoints = require('./src/endpoints');
const ChatState = require('./src/ChatState');
const ChatStep = require('./src/ChatStep');
const Emoji = require('./src/Emoji');
const ExpertBucket = require('./src/ExpertBucket');
const ChatReportType = require('./src/ChatReportType');
const { colors, gutter } = require('./src/colors');
const { accountsPayable } = require('./src/accounts');
const { rating } = require('./src/rating');
const { chartTypes } = require('./src/chartTypes');
const chatTransferReasons = require('./src/chatTransferReasons');
const chatReviewAutoFeedback = require('./src/chatReviewAutoFeedback');
const BotSettings = require('./src/BotSettings');
const FreshchatSettings = require('./src/FreshchatSettings');
const FacebookIntegration = require('./src/FacebookIntegration');
const SalesforceIntegration = require('./src/SalesforceIntegration');
const Bot = require('./src/Bot');
const Api = require('./src/Api');
const QuickActionsOptions = require('./src/quickActions');
const MonitoringOptions = require('./src/MonitoringOptions');
const messageTypes = require('./src/messageTypes');
const { availabilityTypes } = require('./src/availability');
const ChannelType = require('./src/ChannelType');
const Automation = require('./src/Automation');
const InteractionType = require('./src/InteractionType');
const ExpertType = require('./src/ExpertType');
const PartnerStatus = require('./src/PartnerStatus');
const {
  TranslationsFilters,
  Namespaces,
  Features,
} = require('./src/TranslationsFilters');
const { formalLanguages } = require('./src/formalLanguages');
const { translations } = require('./src/translations');
const TransferTargetChannel = require('./src/TransferTargetChannel');
const { DeviceTypes, getDeviceType } = require('./src/userDevices');
const ImageSize = require('./src/ImageSize');
const AutoMessagesActions = require('./src/AutoMessagesActions');

const buildExpertWebUrl = (chatId) => (
  `${process.env.EXPERT_WEB_ENDPOINT}/chats/${chatId}`
);
const DEFAULT_AVATAR_PHOTO = 'https://cdn.guuru.com/assets/logo/avatar.png';

module.exports = {
  ENDPOINTS: endpoints,
  accountsPayable,
  getDeviceType,
  DeviceTypes,
  colors,
  gutter,
  locales,
  localesData,
  mobileLocales,
  languageToCountryMapping,
  availabilityTypes,
  languageMap,
  buildExpertWebUrl,
  Emoji,
  ExpertBucket,
  ChatState,
  ChatStep,
  ChatReportType,
  rating,
  chartTypes,
  chatTransferReasons,
  chatReviewAutoFeedback,
  BotSettings,
  FreshchatSettings,
  FacebookIntegration,
  SalesforceIntegration,
  QuickActionsOptions,
  DEFAULT_AVATAR_PHOTO,
  MonitoringOptions,
  messageTypes,
  TranslationsFilters,
  Namespaces,
  Features,
  formalLanguages,
  translations,
  TransferTargetChannel,
  ChannelType,
  Automation,
  InteractionType,
  ExpertType,
  PartnerStatus,
  ImageSize,
  Bot,
  AutoMessagesActions,
  Api,
};
