const en = [
  'hi',
  'hello',
  'hey',
  'good',
  'morning',
  'afternoon',
  'night',
  'help',
  'problem',
  'question',
  'dear',
];

const de = [
  'hallo',
  'guten',
  'tag',
  'abend',
  'morgen',
  'frage',
  'problme',
];

const it = [
  'ciao',
  'buon',
  'buona',
  'buongiorno',
  'buonasera',
  'buonanotte',
  'moin',
  'gruezi',
  'mitenand',
  'servus',
  'salve',
  'salu',
  'sali',
  'hoi',
  'egregi',
  'domanda',
  'prblema',
];

const fr = [
  'bonjour',
  'bonsoir',
];

const pt = [
  'ola',
  'boa',
  'bom',
  'tarde',
  'noite',
  'dia',
  'ajuda',
  'problema',
  'pergunta',
  'duvida',
];

const ro = [
  'buna',
  'ziua',
];

const bg = [
  'Здравейте',
];

const isGreeting = (text) => {
  const normalizedText = text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[\W]+/g, ' ')
    .trim();

  if (normalizedText.length > 20) return false;
  if (normalizedText.length === 0) return true;

  const words = normalizedText.toLowerCase().split(' ');

  return [
    ...en,
    ...de,
    ...fr,
    ...it,
    ...pt,
    ...ro,
    ...bg,
  ].find((greeting) => words.find((word) => word.startsWith(greeting)));
};

export default isGreeting;
