import React from 'react';
import { PoweredBy } from '@guuru/react-powered-by';
import { Flex, Layout } from 'antd';
import { useCommunitySettings } from '@guuru/community-web';
import Help from './Help';

const Footer = function () {
  const { partnerId } = useCommunitySettings();
  return (
    <Layout.Footer>
      <Flex align="center" vertical>
        <Help />
        <PoweredBy url={`https://www.guuru.com/?utm_source=pages&utm_medium=pages&utm_campaign=pages&utm_term=${partnerId}&utm_content=${partnerId}`} />
      </Flex>
    </Layout.Footer>
  );
};

export default Footer;
