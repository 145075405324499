import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Link from './Link';

const PoweredBy = function ({ url, text = 'Powered by Guuru' }) {
  return (
    <Link
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon />
      {text}
    </Link>
  );
};

PoweredBy.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default PoweredBy;
