import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import { Option, Col } from './style';
import LinkOption from './LinkOption';

const OptionsList = function ({
  subscriptionOptions = [],
  showOptions = false,
  onSelect,
}) {
  if (!showOptions) return null;

  return (
    <Row justify="center" gutter={[8, 8]}>
      {subscriptionOptions.map(({ name, option }) => {
        const showContactAsLink = option === 'Transfer';
        return (
          <Col key={option} xs={showContactAsLink ? 24 : {}}>
            {!showContactAsLink
              ? (
                <Option
                  id={option}
                  name={name}
                  onClick={onSelect}
                  $highlighted={option === 'No'}
                  icon={option === 'No' && (
                    <CommentOutlined style={{ fontSize: '26px' }} />
                  )}
                />
              )
              : <LinkOption id={option} name={name} onClick={onSelect} />}
          </Col>
        );
      })}
    </Row>
  );
};

OptionsList.propTypes = {
  subscriptionOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
  })),
  showOptions: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default OptionsList;
