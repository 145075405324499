import React from 'react';
import PropTypes from 'prop-types';

import { Header } from './style';

const ChatHeaderWrapper = function ({ isUser, header }) {
  if (!isUser) {
    return null;
  }

  return (
    <Header className="chat-header-wrapper">
      {header}
    </Header>
  );
};

ChatHeaderWrapper.propTypes = {
  isUser: PropTypes.bool.isRequired,
  header: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
};

export default ChatHeaderWrapper;
