import React from 'react';
import { Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import Cover from '../Cover';
import { Meta } from './style';

const HorizontalLayout = function ({
  avatar = null,
  title = null,
  name = undefined,
  description = null,
  loading,
}) {
  return (
    <Card
      hoverable
      styles={{ body: { padding: '0 15px 0 0' } }}
      style={{ marginBottom: 0 }}
    >
      <Skeleton
        active
        avatar={{ size: 150, shape: 'square' }}
        loading={loading}
        paragraph={{ rows: 3, width: [400, 240, 100] }}
        title={false}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Meta
          style={{ alignItems: 'center' }}
          avatar={(
            <Cover
              src={avatar}
              text={name || title}
              style={{ borderRadius: '5px 0 0 5px' }}
              size={120}
            />
          )}
          title={title}
          description={description}
        />
      </Skeleton>
    </Card>
  );
};

HorizontalLayout.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  description: PropTypes.string,
};

export default HorizontalLayout;
