import styled from 'styled-components';
import { Row, Upload as AntUpload } from 'antd';

export const ErrorRow = styled(Row)`
  color: ${(props) => (props.$hasError ? 'red' : 'black')};
  width: ${(props) => (props.$hasError ? '200px' : '100%')};
`;

export const Upload = styled(AntUpload)`
  .ant-upload-list-item-container,
  .ant-upload-select {
    ${({ $uploaderWidth }) => ($uploaderWidth
    ? `width: ${$uploaderWidth} !important;
    height: ${$uploaderWidth} !important;`
    : undefined)}
  }
`;
