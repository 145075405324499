import React from 'react';
import {
  Card, Flex, Skeleton, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import Cover from '../Cover';

const { Text, Paragraph } = Typography;

const VerticalLayout = function ({
  avatar = null,
  title = null,
  name = undefined,
  subTitle = undefined,
  description = null,
  footer = null,
  extraTitle = null,
  actions = [],
  loading,
}) {
  return (
    <Card
      hoverable
      bordered={false}
      actions={actions}
      cover={loading
        ? undefined
        : (
          <Cover
            src={avatar}
            text={name || title}
          />
        )}
    >
      <Skeleton
        loading={loading}
        active
        title={false}
        avatar={{ size: 100, shape: 'square' }}
        paragraph={{ rows: 3, width: [100, 50, 120] }}
        style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
      >
        <Flex vertical gap="middle">
          <Flex justify="space-between" wrap="wrap" align="center">
            <Card.Meta
              title={subTitle ? title : title.split(' ')[0]}
              description={subTitle}
            />
            {extraTitle}
          </Flex>
          <Flex vertical justify="space-between">
            {description && (
            <Paragraph ellipsis={{
              rows: 6,
              expandable: true,
              symbol: 'more',
            }}
            >
              {`“${description}”`}
            </Paragraph>
            )}
            <Text type="secondary">
              {footer}
            </Text>
          </Flex>
        </Flex>
      </Skeleton>
    </Card>
  );
};

VerticalLayout.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.string,
  avatar: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
  extraTitle: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ])),
};

export default VerticalLayout;
