import React from 'react';
import PropTypes from 'prop-types';
import { MessageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ChatCta from '../ChatCta';

const ChatNewMessagesCta = function ({ count, onClick }) {
  const { t } = useTranslation();

  return (
    <ChatCta onClick={onClick}>
      <MessageOutlined />{' '}
      <strong>{count} {t('chat:::unreadMessages')}</strong>
    </ChatCta>
  );
};

ChatNewMessagesCta.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChatNewMessagesCta;
