import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

const HELPER = {
  printRange([startDate, endDate], timezone = 'Etc/UTC') {
    const start = moment(startDate).tz(timezone).format('MMM DD');
    const end = moment(endDate).tz(timezone).format('MMM DD, YYYY');
    return `${start} - ${end}`;
  },

  getTime(currentTime, timeRange) {
    const time = moment(currentTime);
    time.hours(timeRange.split(':')[0]);
    time.minutes(timeRange.split(':')[1]);
    return time;
  },

  dateParser(dateString, timezone) {
    let momentRange;
    const dateText = dateString.toLowerCase();
    if (dateText.match(/(this|last)\s+(day|week|month|year|quarter|hour|minute|second)/)) {
      const match = dateText.match(/(this|last)\s+(day|week|month|year|quarter|hour|minute|second)/);
      let start = moment.tz(timezone);
      let end = moment.tz(timezone);
      if (match[1] === 'last') {
        start = start.add(-1, match[2]);
        end = end.add(-1, match[2]);
      }
      const span = match[2] === 'week' ? 'isoWeek' : match[2];
      momentRange = [start.startOf(span), end.endOf(span)];
    } else if (dateText.match(/last\s+(\d+)\s+(day|week|month|year|quarter|hour|minute|second)/)) {
      const match = dateText.match(/last\s+(\d+)\s+(day|week|month|year|quarter|hour|minute|second)/);
      const span = match[2] === 'week' ? 'isoWeek' : match[2];
      momentRange = [
        moment.tz(timezone).startOf(span)
          .add(-parseInt(match[1], 10), match[2]),
        moment.tz(timezone).endOf(span).add(-1, match[2]),
      ];
    } else if (dateText.match(/today/)) {
      momentRange = [
        moment.tz(timezone).startOf('day'),
        moment.tz(timezone).endOf('day'),
      ];
    } else if (dateText.match(/yesterday/)) {
      momentRange = [
        moment.tz(timezone).startOf('day').add(-1, 'day'),
        moment.tz(timezone).endOf('day').add(-1, 'day'),
      ];
    } else {
      throw Error(`Can't parse date: '${dateString}'`);
    }
    return momentRange.map((d) => d.format(moment.HTML5_FMT.DATETIME_LOCAL_MS));
  },

  convertToDateRange(datePeriod, timezone = moment.tz.guess()) {
    let dateRange;
    try {
      if (typeof datePeriod === 'string') {
        dateRange = this.dateParser(datePeriod, timezone);
        dateRange = [moment(dateRange[0]), moment(dateRange[1])];
      } else {
        dateRange = datePeriod && datePeriod.length === 1
          ? [datePeriod[0], datePeriod[0]]
          : datePeriod;
      }
    } catch (err) {
      dateRange = [];
    }
    return dateRange;
  },
};

export default HELPER;
