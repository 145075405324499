import Moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const TIME_SERIES = {
  hour: (range) => Array.from(range.by('hour'))
    .map((d) => d.format('YYYY-MM-DDTHH:00:00.000')),
  day: (range) => Array.from(range.by('day'))
    .map((d) => d.format('YYYY-MM-DDT00:00:00.000')),
  week: (range) => Array.from(range.snapTo('isoweek').by('week'))
    .map((d) => d.startOf('isoweek').format('YYYY-MM-DDT00:00:00.000')),
  month: (range) => Array.from(range.snapTo('month').by('month'))
    .map((d) => d.format('YYYY-MM-01T00:00:00.000')),
  quarter: (range) => Array.from(range.snapTo('quarter').by('quarter'))
    .map((d) => d.format('YYYY-MM-01T00:00:00.000')),
  year: (range) => Array.from(range.snapTo('year').by('year'))
    .map((d) => d.format('YYYY-01-01T00:00:00.000')),
};

export default (start, stop, step, timezone) => {
  const startInTz = moment.tz(start, timezone);
  const endWithTz = moment.tz(stop, timezone);
  const nowInTZ = moment().tz(timezone);
  const range = [
    startInTz.isAfter(nowInTZ) ? nowInTZ : startInTz,
    endWithTz.isAfter(nowInTZ) ? nowInTZ : endWithTz,
  ];
  return TIME_SERIES[step](moment.range(range));
};
