import React from 'react';
import { Empty, Space, Typography } from 'antd';

const { Title } = Typography;

const EmptyList = function () {
  return (
    <Empty
      style={{ display: 'flex', flexDirection: 'column-reverse' }}
      image="https://cdn.guuru.com/assets/images/pages/community.png"
      imageStyle={{ height: 'clamp(150px, 30vw, 650px)' }}
      description={(
        <Space direction="vertical">
          <Title level={3}>
            We’re cooking up something amazing...
          </Title>
          <Title type="secondary" level={5}>
            Come back to find a new community here soon
          </Title>
        </Space>
      )}
    />
  );
};

export default EmptyList;
