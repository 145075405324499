import { gql } from '@apollo/client';

export default gql`
  query GetUserEmailAndShouldBeContacted($id: ID!) {
    chat(id: $id){
      id
      shouldBeContacted
      user {
        id
        email
      }
    }
  }
`;
