import { GuuruOutlined } from '@guuru/react-icon';
import { CustomerServiceOutlined } from '@ant-design/icons';

const PartnerStatsFilters = {
  agents: {
    key: 'agents',
  },

  guurus: {
    key: 'guurus',
  },

  expertsType: {
    key: 'expertsType',
    label: 'Type',
    filters: [
      {
        name: 'Guurus',
        icon: GuuruOutlined,
        value: 'guuru',
      },
      {
        name: 'Agents',
        icon: CustomerServiceOutlined,
        value: 'agent',
      },
    ],
  },
};

export default PartnerStatsFilters;
