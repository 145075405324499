import styled from 'styled-components';

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.onBackgroundColor};
  font-variant: all-small-caps;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 8px 12px;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.onBackgroundColor};
    background-color: rgba(0 0 0 / 3%);
  }
`;

export default Link;
