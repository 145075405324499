import styled from 'styled-components';

export const Text = styled.div`
  color: ${({ theme }) => theme.onBackgroundColor};
  opacity: 0.85;
  text-align: ${({ theme }) => theme.chatAlignment};
  font-size: 0.625rem;
  line-height: 1.5;
  font-weight: 300;
  padding-top: 5px;
  margin-bottom: 0;
  padding-bottom: 12px;
  -webkit-font-smoothing: antialiased;

  a {
    &,
    :hover,
    :visited {
      color: ${({ theme }) => theme.onBackgroundColor};
      text-decoration: underline;
      margin-left: 2px;
      margin-right: 2px;
      display: inline-block;
    }
  }
`;

export const Row = styled.div`
  display: ${({ theme }) => (theme.chatLayout ? '' : 'inline-block')};
`;
