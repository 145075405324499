import styled, { css } from 'styled-components';
import { Row } from 'antd';

// align title with icon if there is no description
const centeredListItem = css`
  padding-bottom: 0;

  .ant-list-item-meta {
    align-items: center;
  }
`;

export default styled(Row)`
  padding: 0.375rem;

  .ant-list-item-meta {
    display: flex;
  }

  .ant-list-item-meta-description,
  .ant-list-item-meta-title {
    line-height: 1.25;
  }

  .ant-list-item-meta-description {
    font-size: 0.75rem;
    color: rgba(0 0 0 / 45%);
  }

  .ant-list-item-meta-avatar {
    margin: 0 -0.4rem;
  }

  ${({ description }) => !description && centeredListItem}
`;
