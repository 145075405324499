import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LoadingRow } from './style';

const Loading = function ({
  height = 50,
  spinSize = 'default',
  error = undefined,
}) {
  return (
    <LoadingRow
      type="flex"
      justify="center"
      $height={height}
    >
      <Spin
        size={spinSize}
        indicator={error ? <ExclamationCircleOutlined /> : undefined}
      />
    </LoadingRow>
  );
};

Loading.propTypes = {
  height: PropTypes.number,
  spinSize: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default Loading;
