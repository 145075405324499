import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Emoji } from '@guuru/react-emoji';
import { Row, Space } from 'antd';
import events from '@guuru/events-web';
import { useTranslation } from 'react-i18next';
import RATE_EXPERT from './mutations/rateExpert';
import RateButton from './RateButton';

const EmojiOptions = [
  {
    rating: 'bad',
    ratingText: 'chat:::rateExpertBad',
  },
  {
    rating: 'average',
    ratingText: 'chat:::rateExpertPartial',
  },
  {
    rating: 'good',
    ratingText: 'chat:::rateExpertGood',
  },
];

const EmojiRateExpert = function ({
  selectedRating = undefined,
  chatId,
  width = 30,
  onSubmit = () => {},
  eventLabel = 'Pre Rated',
  showEmojiText = false,
}) {
  const [currentRating, setCurrentRating] = useState(selectedRating);
  const [preRateChat] = useMutation(RATE_EXPERT);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentRating(selectedRating);
  }, [selectedRating]);

  const handleRateSelection = (rating) => {
    if (rating === currentRating) return;
    setCurrentRating(rating);
    events.preRateExpert(`${eventLabel}: ${rating}`);
    preRateChat({ variables: { id: chatId, rating } });
    onSubmit();
  };

  return (
    <Row align="bottom">
      <Space direction="horizontal" size={4}>
        {EmojiOptions.map(({ rating, ratingText }) => (
          <RateButton
            key={rating}
            $selected={!currentRating || rating === currentRating}
            $rating={rating}
            onClick={() => handleRateSelection(rating)}
          >
            <Space direction="vertical" size={10} align="center">
              <Emoji
                type={rating}
                width={rating === currentRating ? width : width - 2}
              />
              {showEmojiText && t(ratingText)}
            </Space>
          </RateButton>
        ))}
      </Space>
    </Row>
  );
};

EmojiRateExpert.propTypes = {
  chatId: PropTypes.string.isRequired,
  selectedRating: PropTypes.string,
  eventLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  width: PropTypes.number,
  showEmojiText: PropTypes.bool,
};

export default EmojiRateExpert;
