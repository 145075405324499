import styled from 'styled-components';

export default styled.span`
  display: block;
  position: relative;
  font-size: 0.875rem;
  color: ${({ theme }) => (theme?.neutralVariants ? theme.neutralVariants[5] : 'hsl(0, 0%, 39%)')};
  padding-top: 0.7rem;

  &:first-child {
    padding-top: 0;
  }

  .mod--expert & {
    color: #fff;

    a,
    a:visited {
      color: #fff;
      text-decoration: underline;
    }
  }

  .mod--smartForm & {
    display: flex;
  }
`;
