import { gql } from '@apollo/client';

export default gql`
  mutation PreRateChat($id: ID!, $rating: Rating!) {
    preRateChat(id: $id, rating: $rating) {
      id
      rating
    }
  }
`;
