import { useSuspenseQuery } from '@apollo/client';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import GET_TRANSLATIONS from './queries/getTranslations';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const useSuspenseTranslations = function (
  keys,
  language = 'en',
  placeholders = {},
) {
  const {
    data: {
      globalTranslations: {
        edges = [],
      } = {},
    } = {},
  } = useSuspenseQuery(GET_TRANSLATIONS, {
    variables: {
      filters: [
        { key: 'keys', value: keys },
        { key: 'language', value: [language] },
      ],
    },
  });

  return {
    loading: false,
    data: edges?.reduce((acc, { node: { key, value } }) => ({
      [key]: (template(value[language]))(placeholders),
      ...acc,
    }), {}) || {},
  };
};

export default useSuspenseTranslations;
