import React from 'react';
import PropTypes from 'prop-types';
import Good from './good.svg';
import Average from './average.svg';
import Bad from './bad.svg';

const Components = {
  good: Good,
  average: Average,
  bad: Bad,
};

const Emoji = function ({ type, width = 28, ...props }) {
  return React.createElement(Components[type], {
    key: type,
    width,
    height: width,
    ...props,
  });
};

Emoji.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
};

export default Emoji;
