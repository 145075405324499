import styled from 'styled-components';
import { GuuruOutlined } from '@guuru/react-icon';

const Icon = styled(GuuruOutlined)`
  font-size: 16px;
  vertical-align: middle;
  padding: 10px 5px 10px 0;
`;

export default Icon;
