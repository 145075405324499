import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useLiveQuery } from '@guuru/react-hooks';
import GET_CHAT from '../../queries/getChat';
import CHAT_UPDATED from './subscriptions/chatUpdated';
import CHAT_DELETED from './subscriptions/chatDeleted';

export default ({
  chatId,
  isUser = true,
  useSubscriptions = false,
  skip = false,
  chatControlToken,
  onChatUpdate = () => {},
  onChatDelete = () => {},
  onError = () => {},
} = {}) => {
  const {
    updateQuery,
    refetch,
    called,
    ...result
  } = useLiveQuery(GET_CHAT, CHAT_UPDATED, {
    onError,
    onData: ({ chat }) => (
      onChatUpdate({ data: { data: { chatUpdated: chat } } })
    ),
    skip: skip || !chatId,
    variables: {
      id: chatId,
      isVisibleByExpert: !isUser,
      isVisibleByUser: isUser,
    },
    queryOptions: {
      variables: {
        chatControlToken,
      },
    },
    subscriptionOptions: {
      skip: !useSubscriptions,
    },
  });

  // We need to refetch the chat and the corresponding messages when
  // the chat id changes, e.g., when changing between chats in expert web,
  // otherwise the messages received while the corresponding chat was not
  // on focus will not be displayed.
  useEffect(() => {
    if (called && chatId && !isUser) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useSubscription(CHAT_DELETED, {
    variables: { id: chatId },
    skip: !useSubscriptions || !chatId,
    onData: onChatDelete,
  });

  if (!chatId) {
    return { data: undefined, loading: true };
  }

  return {
    updateQuery,
    refetch,
    ...result,
  };
};
