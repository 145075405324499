import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import events from '@guuru/events-web';
import { delay as sleep } from '@guuru/helpers-common';
import { messageTypes } from '@guuru/constants-common';

const { MESSAGE_TYPE_MESSAGE } = messageTypes;

const requestEmail = 'bot:::requestEmail';

const addBotRequestEmailMessage = () => (
  sleep(ChatAPI.getBotEllipsisMessageDelay())
    .then(() => {
      const message = ChatAPI.messages.createBotMessage(
        new Date().getTime(),
        requestEmail,
        MESSAGE_TYPE_MESSAGE,
        {},
        true,
        false,
      );
      ChatAPI.pushPendingMessages(message);
      return message;
    })
);

const addedEmail = (partnerId, email) => {
  const message = ChatAPI.messages.createAnonymousTextMessage(
    email,
    true,
    false,
  );
  ChatAPI.storeEmail(partnerId, email);
  ChatAPI.pushPendingMessages(message);
  return message;
};

export default {
  validate() {
    const { priority } = ChatAPI.storeRetrieveParams();
    return priority !== 'bot';
  },

  * render() {
    const message = yield addBotRequestEmailMessage();
    events.requestEmail();
    yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
  },

  * processResponse(partnerId, { text }) {
    const addedEmailMessage = addedEmail(partnerId, text);
    events.emailSubmitted(text);
    yield put({
      type: types.CHAT_NEW_TEXT_SUCCESS,
      message: addedEmailMessage,
    });
  },
};
