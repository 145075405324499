import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { QuickActionsIcons } from '@guuru/constants-web';

const OptionIcon = function ({ icon = '' }) {
  const Icon = useMemo(() => (
    QuickActionsIcons.find(({ value }) => value === icon)?.icon
  ), [icon]);

  return Icon ? <Icon /> : null;
};

OptionIcon.propTypes = {
  icon: PropTypes.string,
};

export default OptionIcon;
