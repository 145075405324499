import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { ThemeContext } from 'styled-components';
import RowWrapper from './RowWrapper';

const ChatRowWrapper = function ({
  children = null,
  className = undefined,
  isUser = false,
}) {
  const theme = useContext(ThemeContext);

  const width = (isUser && theme?.chatLayout) || theme?.isFullScreen ? 16 : 24;
  return (
    <RowWrapper className={className} type="flex" justify="center">
      <Col xs={24} sm={24} md={width} lg={width} xl={width}>
        {children}
      </Col>
    </RowWrapper>
  );
};

ChatRowWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isUser: PropTypes.bool,
};

export default ChatRowWrapper;
