/* eslint-disable no-underscore-dangle */
import { useQuery } from '@apollo/client';
import React, { useContext, useMemo } from 'react';
import {
  arrayOf, bool, func, shape, string,
} from 'prop-types';
import { i18n } from '@guuru/translation-web';
import { messageType } from '@guuru/react-types';
import { botMessage, botMessagesTypes } from '@guuru/chat-web';
import { ThemeContext } from 'styled-components';
import { Bot, messageTypes } from '@guuru/constants-common';
import ChatMessage from '../ChatMessage';
import ChatRowWrapper from './ChatRowWrapper';
import useIsTyping from './hooks/useIsTyping';
import GET_CHAT_STATUS from './queries/getChatStatus';

const { MESSAGE_TYPE_PREDICTION_ANSWER } = messageTypes;

const showProfileImage = (messagesList, message, pos) => !(
  (message?.author?.id === messagesList[pos + 1]?.author?.id)
);

const getSide = function (isUser, message, smartFormStyle) {
  if (smartFormStyle) return 'center';

  // eslint-disable-next-line no-underscore-dangle
  const authorType = message.author.__typename;
  return ((isUser && authorType === 'UserAuthor')
    || (!isUser && authorType === 'ExpertAuthor')
    || (!isUser && authorType === 'BotAuthor'))
    ? 'right' : 'left';
};

const splitMessage = ({ text, type, author }) => (
  (author.id === Bot.BOT_UID || type === MESSAGE_TYPE_PREDICTION_ANSWER)
    ? [text]
    : text?.split('\n') || ['']
);

const ChatMessages = function ({
  chatId = null,
  messages = [],
  mods = '',
  isUser,
  showBotAvatar,
  customBotAvatar = null,
  readOnly = false,
  preview = false,
  partner = null,
  showDelete = false,
  subscribeIsTyping = true,
  dispatch = () => {},
  locale = null,
}) {
  const theme = useContext(ThemeContext);
  const typingMessage = useIsTyping({
    chatId,
    isUser,
    skip: !subscribeIsTyping,
  });

  const { data: { chat: { status } = {} } = {} } = useQuery(GET_CHAT_STATUS, {
    variables: { chatId },
    skip: !chatId,
  });

  const showConnectingMessage = function () {
    return (['assigned', 'created'].includes(status))
      ? botMessage(
        'connecting',
        i18n.t(botMessagesTypes.connectingWithExpert, ''),
        'connecting',
      ) : null;
  };

  const showSmartFormStyle = function (message) {
    if (theme?.chatLayout !== false) return false;
    const { metadata = {} } = message || {};
    return metadata?.messageStyle === 'smartForm';
  };

  const renderMessage = function (message, post, profileImage) {
    const smartFormStyle = showSmartFormStyle(message);
    const currentMessage = {
      ...message,
      smartFormStyle,
    };
    return (
      <ChatMessage
        chatId={chatId}
        key={message.id}
        message={currentMessage}
        side={getSide(isUser, message, smartFormStyle)}
        showProfileImage={profileImage}
        showBotAvatar={showBotAvatar}
        customBotAvatar={customBotAvatar}
        readOnly={readOnly}
        preview={preview}
        partner={partner}
        showDelete={showDelete}
        dispatch={dispatch}
        locale={locale}
      />
    );
  };

  const renderMessages = (messagesToRender) => (
    messagesToRender.map((message, pos) => renderMessage(
      message,
      pos,
      showProfileImage(messagesToRender, message, pos),
    ))
  );

  const transcript = useMemo(() => {
    const messagesToRender = [];
    const firstMessage = (theme?.chatLayout !== false) ? 0 : 1;
    for (let i = firstMessage; i < messages.length; i += 1) {
      const { node: message } = messages[i];

      const smartFormStyle = showSmartFormStyle(message);
      if (smartFormStyle) {
        // If we are in smartForm layout, we don't want to split bot messages
        messagesToRender.push({ ...message, i18n: message.text });
      } else {
        // Check if there is a need to break down the message
        // in multiple messages
        let lineSplittedParagraphs = splitMessage(message);

        if (lineSplittedParagraphs.length > 1) {
          lineSplittedParagraphs = (
            lineSplittedParagraphs.filter((paragraph) => paragraph.length)
          );
        }

        const splittedMessageCount = lineSplittedParagraphs.length;
        for (let j = 0; j < splittedMessageCount; j += 1) {
          const paragraph = lineSplittedParagraphs[j];
          const newMessage = { ...message };
          newMessage.i18n = i18n.t(paragraph);
          newMessage.id = `${newMessage.id}.${j}`;
          // If there is more than one message then reset the type
          // of all except the last to avoid duplication of select options for
          // instance ( display options only on the last ).
          if (splittedMessageCount > 1) {
            const isLastMessage = j === splittedMessageCount - 1;
            if (!isLastMessage) {
              newMessage.type = 'message'; // default
            }
          }

          messagesToRender.push(newMessage);
        }
      }
    }

    const connectingMessage = showConnectingMessage(messagesToRender);
    if (connectingMessage) {
      messagesToRender.push(connectingMessage.node);
    }

    if (typingMessage && !connectingMessage) {
      const [lastMessage] = messagesToRender.slice(-1);
      const smartFormStyle = showSmartFormStyle(lastMessage) || !lastMessage;
      const styledTypingMessage = {
        ...typingMessage,
        metadata: {
          messageStyle: smartFormStyle ? 'smartForm' : 'chat',
        },
      };

      messagesToRender.push(styledTypingMessage);
    }
    return renderMessages(messagesToRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, typingMessage, status]);

  return (
    <ChatRowWrapper className={`chat-messages ${mods}`} isUser={isUser}>
      {transcript}
    </ChatRowWrapper>
  );
};

ChatMessages.propTypes = {
  chatId: string,
  messages: arrayOf(messageType),
  isUser: bool.isRequired,
  showBotAvatar: bool.isRequired,
  customBotAvatar: string,
  readOnly: bool,
  preview: bool,
  mods: string,
  partner: shape({}),
  showDelete: bool,
  subscribeIsTyping: bool,
  dispatch: func,
  locale: string,
};

export default ChatMessages;
