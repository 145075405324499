import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { ErrorRow, Upload } from './style';

const getDimensions = (file) => new Promise((resolve) => {
  const fileUrl = URL.createObjectURL(file);
  const img = new Image();
  img.onload = () => {
    resolve({ height: img.height, width: img.width });
    URL.revokeObjectURL(fileUrl);
  };
  img.src = fileUrl;
});

const FileInput = function ({
  onChange = () => {},
  value = null,
  shape = undefined,
  keepAspect = false,
  fillColor = undefined,
  uploaderWidth = undefined,
  maxCount = 1,
}) {
  const [error] = useState();
  const getValueArray = () => {
    if (!value) return [];
    return Array.isArray(value) ? value : [value];
  };
  const [files, setFiles] = useState(getValueArray());
  const [aspect, setAspect] = useState(1);

  const handleBeforeCrop = async (file) => {
    if (keepAspect) {
      const { height, width } = await getDimensions(file);
      setAspect(width / height);
    }
    return true;
  };

  const handleChange = ({ fileList }) => {
    setFiles(fileList);
    if (onChange) {
      onChange(maxCount === 1 ? { ...error, ...fileList[0] } : fileList);
    }
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const uploadButton = (
    <ErrorRow $hasError={!!error} justify="center">
      <Col>
        {error ? <ExclamationCircleOutlined /> : <PlusOutlined />}
        <Row>
          {error || 'Upload'}
        </Row>
      </Col>
    </ErrorRow>
  );

  return (
    <ImgCrop
      aspect={aspect}
      cropShape={shape}
      beforeCrop={(file) => handleBeforeCrop(file)}
      fillColor={fillColor}
    >
      <Upload
        accept="image/*"
        action={() => {}}
        customRequest={dummyRequest}
        beforeUpload={() => true}
        fileList={files}
        listType={shape === 'round' ? 'picture-circle' : 'picture-card'}
        onChange={handleChange}
        showUploadList={{ showPreviewIcon: false }}
        $uploaderWidth={uploaderWidth}
      >
        {files.length < maxCount ? uploadButton : null}
      </Upload>
    </ImgCrop>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  shape: PropTypes.string,
  keepAspect: PropTypes.bool,
  fillColor: PropTypes.string,
  uploaderWidth: PropTypes.string,
  maxCount: PropTypes.number,
};

export default FileInput;
