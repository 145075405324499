import React from 'react';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors } from '@guuru/constants-common';
import VerticalLayout from './VerticalLayout';
import HorizontalLayout from './HorizontalLayout';

const HighlightCard = function ({
  isLoading = false,
  avatar = null,
  title = null,
  name = undefined,
  subTitle = undefined,
  description = null,
  footer = null,
  extraTitle = null,
  url = null,
  actions = [],
  tooltip = '',
  layout = 'vertical',
}) {
  return (
    <Link to={url}>
      <Tooltip
        title={tooltip}
        color={colors.primaryColor}
        placement="top"
        arrow={false}
        align={{ offset: [0, 20] }}
        overlayStyle={{ cursor: 'pointer' }}
        autoAdjustOverflow={false}
        zIndex={1}
      >
        <span>
          {layout === 'vertical'
            ? (
              <VerticalLayout
                loading={isLoading || (!isLoading && !title)}
                title={title}
                avatar={avatar}
                subTitle={subTitle}
                description={description}
                extraTitle={extraTitle}
                footer={footer}
                actions={actions}
                name={name}
              />
            )
            : (
              <HorizontalLayout
                loading={isLoading || (!isLoading && !title)}
                description={description}
                title={title}
                avatar={avatar}
                name={name}
              />
            )}
        </span>
      </Tooltip>
    </Link>
  );
};

HighlightCard.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.string,
  layout: PropTypes.string,
  avatar: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
  extraTitle: PropTypes.node,
  url: PropTypes.string,
  isLoading: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ])),
  tooltip: PropTypes.string,
};

export default HighlightCard;
