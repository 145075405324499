import React from 'react';
import ThemeProvider from '@guuru/theme-web';
import {
  Col, Divider, Flex, Layout, Row, theme,
} from 'antd';
import { Outlet } from 'react-router-dom';
import { PageNotFound } from '@guuru/react-error-pages';
import { useCommunitySettings } from '@guuru/community-web';
import Header from '../../components/Invitation/Header';
import Footer from '../../components/Invitation/Footer';
import InvitationStyle from './InvitationStyle';
import { invitationTheme, invitationThemeConfig } from './invitationTheme';

const { useToken } = theme;

const { Content } = Layout;

const InvitationLayout = function () {
  const { token } = useToken();
  const { partnerName, partnerFont, partnerColor } = useCommunitySettings();
  const invTheme = invitationTheme({ partnerFont, partnerColor });

  if (!partnerName) {
    return <PageNotFound />;
  }

  return (
    <ThemeProvider
      theme={{ ...invTheme, antd: token }}
      configProvider={invitationThemeConfig}
    >
      <Layout>
        <InvitationStyle font={partnerFont} />
        <Row align="center">
          <Col xs={22} xxl={20}>
            <Row align="center">
              <Col xs={24} lg={20}>
                <Flex gap={24} vertical>
                  <Col />
                  <Header />
                  <Divider />
                  <Content>
                    <Outlet />
                  </Content>
                  <Divider />
                </Flex>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default InvitationLayout;
