/* eslint-disable no-underscore-dangle */
const botMessagesTypes = {
  welcome: 'bot:::welcome',
  requestName: 'bot:::requestName',
  requestCategory: 'bot:::requestCategory',
  predictionResult: 'bot:::predictionResult',
  predictionSelectionSmartForm: 'bot:::predictionSelectionSmartForm',
  predictionRoutingToExperts: 'bot:::predictionRoutingToExperts',
  requestPartnerSelection: 'bot:::requestPartnerSelection',
  requestEmail: 'bot:::requestEmail',
  connectingWithExpert: 'bot:::connectingWithExpert',
  thankForFillingForm: 'bot:::thankForFillingForm',
  connectionFailed: 'bot:::connectionFailed',
  transferContactInformationConfirmed: 'bot:::transferContactInformationConfirmed',
  requestVisitorRole: 'bot:::requestVisitorRole',
  smsFallbackRegistered: 'bot:::smsFallbackRegistered',
  waitFallbackRegistered: 'bot:::waitFallbackRegistered',
  requestEmailThanks: 'bot:::requestEmailThanks',
  requestSentSmartForm: 'bot:::requestSentSmartForm',
};

const botMessage = (id, text, type, metadata) => ({
  node: {
    id,
    author: {
      name: 'Guuru-Bot',
      coverImage: 'https://cdn.guuru.com/assets/logo/guuru_logo.svg',
      id: 'bot',
      isBot: true,
      photoUrl: 'https://cdn.guuru.com/assets/logo/guuru_logo.svg',
      uid: 'bot',
      __typename: 'BotAuthor',
    },
    isBot: true,
    isUser: false,
    text,
    i18n: text,
    type,
    metadata,
  },
});

const visitorMessage = (id, text) => ({
  node: {
    id,
    author: {
      id: 'bot',
      name: 'visitor',
      photoUrl: null,
      phone: null,
      __typename: 'UserAuthor',
    },
    isBot: false,
    isUser: true,
    text,
    createdAt: Date.now(),
    hideForExpert: false,
    hideForUser: false,
    metadata: {},
    type: 'text',
    __typename: 'TextMessage',
  },
});

const getAuthor = (oldAuthor) => {
  let __typename = 'UserAuthor';

  if (oldAuthor.isBot) {
    __typename = 'BotAuthor';
  }
  if (oldAuthor.isExpert) {
    __typename = 'ExpertAuthor';
  }

  return ({
    __typename,
    photoUrl: oldAuthor.coverImage,
    id: oldAuthor.uid,
    ...oldAuthor,
  });
};

const createMessageForPresentation = ({
  timestamp,
  i18n,
  data,
  type,
  author: oldAuthor,
  ...message
}) => {
  const author = getAuthor(oldAuthor);

  if (['smsFallback', 'waitFallback'].includes(type)) {
    return ({
      node: {
        ...message,
        type,
        createdAt: timestamp,
        author,
        text: i18n || message.message,
        metadata: data,
        phone: data.number,
        email: data.email,
      },
    });
  }

  if (type === 'contact') {
    return ({
      node: {
        ...message,
        type,
        createdAt: timestamp,
        author,
        text: i18n || message.message,
        metadata: data,
        attachment: {
          url: message.address,
          filename: message.filename,
          mimetype: message.mimetype,
        },
        user: {
          ...data.contact,
        },
      },
    });
  }

  if (type === 'attachment') {
    return ({
      node: {
        ...message,
        type,
        createdAt: timestamp,
        author,
        text: i18n || message.message,
        metadata: data,
        attachment: {
          url: message.address,
          filename: message.filename,
          mimetype: message.mimetype,
        },
      },
    });
  }

  if (type === 'waitTimeout') {
    return ({
      node: {
        id: message.id,
        type,
        createdAt: timestamp,
        author,
        text: i18n || message.message,
        metadata: data,
      },
    });
  }

  return ({
    node: {
      ...message,
      type,
      createdAt: timestamp,
      author,
      text: i18n || message.message,
      metadata: data,
    },
  });
};

export {
  createMessageForPresentation,
  botMessage,
  visitorMessage,
  botMessagesTypes,
};
