import { gql } from '@apollo/client';

const GET_CHAT = gql`
  query GetChatNPSRating($id: ID!) {
    chat(id: $id){
      id
      npsRating
    }
  }
`;

export default GET_CHAT;
