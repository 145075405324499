import {
  RetweetOutlined,
  QuestionCircleOutlined,
  ForkOutlined,
} from '@ant-design/icons';
import { colors } from '@guuru/constants-common';

const AutomationActions = {
  greeting: {
    name: 'Greeting Catcher',
    helper: 'Prevent greeting messages from creating a chat',
    icon: RetweetOutlined,
    color: colors.black,
  },
  showAnswer: {
    name: 'Show Answer',
    helper: 'Quick answer to the user based on his question',
    icon: QuestionCircleOutlined,
    color: colors.orange,
  },
  routing: {
    name: 'Routing',
    helper: 'Choose who should answer this question',
    icon: ForkOutlined,
    color: colors.primaryColor,
  },
};

export default AutomationActions;
