import { gtag } from '@guuru/analytics-web';
import { useChatSettings } from '@guuru/chat-web';

const useInitGoogleAnalytics = function (partnerId) {
  const {
    data: {
      chatSettings: {
        chatWidgetAnalyticsEnabled,
      } = {},
    } = {},
  } = useChatSettings(partnerId);
  if (process.env.CHAT_GA_MEASUREMENT_ID && chatWidgetAnalyticsEnabled) {
    gtag.initialize(process.env.CHAT_GA_MEASUREMENT_ID, {
      cookie_prefix: 'guuruGa',
      cookie_domain: 'auto',
      cookie_expires: 31556926, // 12 months
      cookie_flags: 'SameSite=None; Secure',
      allow_ad_personalization_signals: false,
      allow_google_signals: false,
      debug_mode: process.env.ANALYTICS_DEBUG,
    });
  }
};

export default useInitGoogleAnalytics;
