import { gql } from '@apollo/client';

export default gql`
  mutation ConfirmTransfer($chatId: ID!, $input: ChatTransferDetails!) {
    confirmTransfer(chatId: $chatId, input: $input) {
      id
      user {
        id
        email
        phone
      }
      transfer {
        id
        customMeta
        confirmedAt
      }
    }
  }
`;
