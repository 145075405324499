import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCommunitySettings } from '@guuru/community-web';
import ImageCard from './ImageCard';

const { Title } = Typography;

const HowItWorks = function () {
  const {
    partnerName,
    rewards: { isPoints, goodAmount },
  } = useCommunitySettings();
  const { t } = useTranslation();

  const Cards = [
    {
      key: 1,
      title: t('pages:::receiveChatsTitle'),
      description: t('pages:::receiveChatsDescription'),
      icon: 'step1.svg',
    }, {
      key: 2,
      title: t('pages:::answerQuestionsTitle'),
      description: t('pages:::answerQuestionsDescription'),
      icon: 'step2.svg',
    }, {
      key: 3,
      title: isPoints
        ? t('pages:::getRewardedTitle')
        : t('pages:::makeMoneyTitle'),
      description: isPoints
        ? t('pages:::getRewardedDescription', { amount: goodAmount })
        : t('pages:::makeMoneyDescription', { amount: goodAmount }),
      icon: 'step3.svg',
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} align="center">
        <Title level={3}>{t('pages:::stepsTitle', { partnerName })}</Title>
      </Col>
      {Cards.map(({ key, ...card }) => (
        <Col xs={24} sm={8} align="center" key={key}>
          <ImageCard {...card} />
        </Col>
      ))}
    </Row>
  );
};

export default HowItWorks;
