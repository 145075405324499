import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row } from 'antd';
import { useAttachmentSubmit } from '@guuru/react-upload-hooks';
import { handleFormErrors } from '@guuru/react-forms';
import { destroy, warning } from '@guuru/react-message';
import { i18n } from '@guuru/translation-web';
import ChatRenderText from '../Text';
import { FormItem } from '../../SearchInput';
import GET_CHAT_TRANSFER_INFO from './queries/getChatTransferInfo';
import CONFIRM_TRANSFER from './mutations/confirmTransfer';
import UploadFiles from './UploadFiles';
import TransferTargets from './TransferTargets';
import CustomFields from './CustomFields';
import Contacts from './Contacts';
import ContactsSubmited from './ContactsSubmited';
import ButtonInput from '../../ButtonInput';
import { ContactWrapper } from './style';
import StickyMessage from '../../StickyMessage';

const getCustomMeta = ({
  email, phone, attachment, ...customFields
}) => (
  Object.keys(customFields).length
    ? Object.entries(customFields)
      .filter(([, value]) => value === 0 || Boolean(value))
      .reduce((acc, [label, value], index) => {
        acc[index] = { label, value };
        return acc;
      }, {})
    : undefined
);

const ChatRenderContact = function ({ chatId, message, readOnly = false }) {
  const transferTargets = message.metadata?.transferTargets;
  const targetId = message.metadata?.targetId;
  const [email, setEmail] = useState(message.user?.email);
  const [phone, setPhone] = useState(message.user?.phone);
  const [files, setFiles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [isTransferExpired, setIsTransferExpired] = useState(false);
  const [currentTargetId, setCurrentTargetId] = useState(targetId);

  const [form] = Form.useForm();
  const {
    data: {
      chat: { transfer } = {},
    } = {},
  } = useQuery(GET_CHAT_TRANSFER_INFO, { variables: { chatId } });

  const confirmedAt = transfer?.confirmedAt;
  const { smartFormStyle = false } = message;

  const [confirmTransfer] = useMutation(CONFIRM_TRANSFER);

  const handleAttachmentSubmit = useAttachmentSubmit({
    chatId,
    onError: warning,
    onFinish: destroy,
  });

  const uploadAttachments = async () => {
    const attachments = [];
    // TODO: Check if we still need to to compress one attachment at a time.
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      const address = await handleAttachmentSubmit({
        target: {
          files: [file],
        },
      });
      if (address === null) {
        file.status = 'error';
      } else {
        file.status = 'success';
        attachments.push({
          label: 'attachment',
          value: address,
          url: address,
          name: file?.name,
          type: 'file',
          status: address === null ? 'error' : 'success',
        });
      }
    }
    return {
      attachments,
      filesStatus: files,
    };
  };

  const handleSubmit = async (values) => {
    setSaving(true);
    const customMeta = getCustomMeta(values);
    const input = {
      userDetails: { email: values.email, phone: values.phone },
      customMeta,
      targetId: currentTargetId?.toString(),
    };

    try {
      if (files.length) {
        const { attachments, filesStatus } = await uploadAttachments();
        setFiles(filesStatus);
        if (filesStatus.some(({ status }) => status === 'error')) {
          throw new Error('Invalid file format');
        }
        input.customMeta = { attachments, ...input.customMeta };
      }
      await confirmTransfer({ variables: { chatId, input } });
      setEmail(values.email);
      setPhone(values.phone);
    } catch (err) {
      setIsTransferExpired(err.graphQLErrors?.[0].extensions.isExpired);
      handleFormErrors(err, form, { showError: warning });
    } finally {
      setSaving(false);
    }
  };

  return (
    <ContactWrapper $showShadow={!smartFormStyle}>
      {(confirmedAt || readOnly)
        ? (
          <ContactsSubmited
            message={message}
            readOnly={readOnly}
            email={email}
            phone={phone}
          />
        )
        : (
          <StickyMessage>
            <ChatRenderText message={message} tooltip={!message.smartFormStyle}>
              <Form
                form={form}
                initialValues={{ email, phone, target: currentTargetId }}
                onFinish={handleSubmit}
              >
                {!targetId && transferTargets?.length > 1 && (
                  <FormItem
                    name="target"
                    rules={[{ required: true, message: i18n.t('chat:::requiredField') }]}
                  >
                    <TransferTargets
                      targets={transferTargets}
                      setFieldsValue={form.setFieldsValue}
                      onChange={setCurrentTargetId}
                    />
                  </FormItem>
                )}
                <CustomFields
                  customFields={message?.metadata?.transferCustomDetails}
                  readOnly={readOnly}
                  selectedTarget={currentTargetId}
                />
                <FormItem name="attachment">
                  <UploadFiles
                    chatId={chatId}
                    setCurrentFiles={setFiles}
                    currentFiles={files}
                  />
                </FormItem>
                <Contacts
                  contactType={message?.metadata?.transferContactType}
                  contacts={message?.metadata?.contacts}
                  message={message}
                  readOnly={readOnly}
                  submited={!!confirmedAt}
                  phone={phone}
                  email={email}
                  saving={saving}
                  form={form}
                />
                <Row justify="center">
                  <ButtonInput
                    type="primary"
                    htmlType="submit"
                    loading={saving}
                    disabled={isTransferExpired}
                  >
                    {i18n.t(isTransferExpired ? 'chat:::transferExpired' : 'chat:::confirmTransfer')}
                  </ButtonInput>
                </Row>
              </Form>
            </ChatRenderText>
          </StickyMessage>

        )}
    </ContactWrapper>
  );
};

ChatRenderContact.propTypes = {
  chatId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    createdAt: PropTypes.number,
    metadata: PropTypes.shape({
      transferContactType: PropTypes.string,
      transferCustomDetails: PropTypes.arrayOf(PropTypes.shape({})),
      transferTargets: PropTypes.arrayOf(PropTypes.shape({})),
      partnerName: PropTypes.string,
      targetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      contacts: PropTypes.shape({
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
    smartFormStyle: PropTypes.bool,
    i18n: PropTypes.string,
    text: PropTypes.string,
    user: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

export default ChatRenderContact;
