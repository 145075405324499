import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tag, Form } from 'antd';
import styled from 'styled-components';
import { LocaleHelper } from '@guuru/helpers-common';
import { ChatAPI } from '@guuru/api-web';

const { CheckableTag } = Tag;

const GuuruCheckableTag = styled(CheckableTag)`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryVariants[3]};
  color: ${({ theme }) => theme.onSecondaryColor};
  border-radius: 1.25rem;
  box-shadow: rgba(0 0 0 / 10%) 0 1px 3px 0, rgba(0 0 0 / 6%) 0 1px 2px 0;
  padding-top: 0.43rem;
  padding-bottom: 0.43rem;
  font-size: 0.875rem;
  text-align: center;

  &.ant-tag-checkable {
    &:hover {
      background-color: rgba(255 255 255 / 90%);
      color: ${({ theme }) => theme.primaryVariants[7]};
      border-color: ${({ theme }) => (theme.chatLayout ? 'transparent' : theme.primaryColor)};
    }

    ${({ checked, theme }) => checked && `
      border-color: ${theme.chatLayout ? 'white' : theme.primaryColor};
      color: ${theme.primaryVariants[7]};
      && {
        background-color: white;
      }
    `}
  }
`;

const tagClicked = (tagId, {
  tagChecked,
  setCheckedFn,
  setFieldsValueFn,
  getFieldValueFn,
}) => {
  let feedbackTags = getFieldValueFn('feedbackTags');
  if (tagChecked) {
    feedbackTags.push(tagId);
    setCheckedFn(true);
  } else if (!tagChecked) {
    feedbackTags = feedbackTags.filter((id) => tagId !== id);
    setCheckedFn(false);
  }
  setFieldsValueFn({ feedbackTags });
};

const FeedbackTag = function ({ id, title }) {
  const FormItem = Form.Item;
  const [checked, setChecked] = useState(false);
  const locale = ChatAPI.storeRetrieveLocale();
  return (
    <FormItem
      shouldUpdate={(prevValues, curValues) => (
        prevValues?.feedbackTags?.includes(id)
        !== curValues?.feedbackTags?.includes(id)
      )}
      noStyle
    >
      {({ getFieldValue, setFieldsValue }) => (
        <GuuruCheckableTag
          checked={checked}
          onChange={(tagChecked) => tagClicked(id, {
            tagChecked,
            setCheckedFn: setChecked,
            setFieldsValueFn: setFieldsValue,
            getFieldValueFn: getFieldValue,
          })}
        >
          {LocaleHelper.localize(title, locale)}
        </GuuruCheckableTag>
      )}
    </FormItem>
  );
};

FeedbackTag.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.string,
  ).isRequired,
  id: PropTypes.string.isRequired,
};

export default FeedbackTag;
