import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Emoji } from '@guuru/react-emoji';
import { Col, Row, Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import events from '@guuru/events-web';
import { updateChatStep } from '@guuru/chat-web';
import { ChatStep } from '@guuru/constants-common';
import { errorCode, errorType } from '@guuru/graphql-common';
import RATE_CHAT from './mutations/rateChat';
import GET_CHAT_RATING from './queries/getChatRating';
import ExpertFeedbackAvatar from '../../ExpertFeedbackAvatar';
import {
  RateExpertAction,
  RateExpertActionsWrapper,
  RateExpertHeadline,
  RateExpertLabel,
  RateExpertTeaser,
} from './style';

const ratingOptions = [
  {
    id: 'bad',
    color: '#e44646',
    label: 'chat:::rateExpertBad',
  },
  {
    id: 'average',
    color: '#f4b414',
    label: 'chat:::rateExpertPartial',
  },
  {
    id: 'good',
    color: '#42c694',
    label: 'chat:::rateExpertGood',
  },
];

const useRateWithPreRating = function (chat, handleRateCtaClick) {
  useEffect(() => {
    if (!['bad', 'average', 'good'].includes(chat?.rating)) {
      return;
    }
    handleRateCtaClick(chat?.rating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const ChatRateExpert = function () {
  const chatId = useSelector((state) => state.chatReducer.chatId);
  const { t } = useTranslation();
  const [rateChat] = useMutation(RATE_CHAT);
  const { useTrackEventOnce, ratePrompted } = events;

  const {
    data: { chat } = {},
    loading: isLoadingChat,
  } = useQuery(GET_CHAT_RATING, {
    variables: { id: chatId },
    skip: !chatId,
  });

  useTrackEventOnce(
    () => ratePrompted(),
    [chatId],
  );

  const handleRateCtaClick = useCallback(async (rating) => {
    events.rateExpert(`Rated: ${rating}`, rating);
    if (rating === 'bad') {
      updateChatStep(ChatStep.rateBadChat);
    } else {
      try {
        await rateChat({ variables: { id: chatId, rating } });
      } catch (e) {
        if (errorCode(e) === errorType.FORBIDDEN) {
          window.location.reload();
          return;
        }
        throw e;
      }
    }
  }, [chatId, rateChat]);

  useRateWithPreRating(chat, handleRateCtaClick);

  const alreadyRated = ['bad', 'average', 'good'].includes(chat?.rating);
  if (isLoadingChat || alreadyRated) {
    return null;
  }
  const expertName = chat?.expert?.name || 'the expert';
  const expertPhoto = chat?.expert?.photoUrl;
  return (
    <Space direction="vertical" align="center" size={0}>
      <Space direction="vertical" size={30}>
        <ExpertFeedbackAvatar expertPhoto={expertPhoto} />
        <RateExpertHeadline>
          {t('chat:::rateExpertHeadline', { expertName })}
        </RateExpertHeadline>
      </Space>
      <RateExpertTeaser>
        {t('chat:::rateExpertTeaser')}
      </RateExpertTeaser>
      <RateExpertActionsWrapper>
        {ratingOptions.map(({ id, color, label }) => (
          <RateExpertAction
            key={id}
            onClick={() => handleRateCtaClick(id)}
            onKeyPress={() => handleRateCtaClick(id)}
            role="button"
            tabIndex="0"
            $color={color}
          >
            <Col>
              <Row justify="center">
                <span
                  role="img"
                  aria-label={id}
                >
                  <Emoji type={id} width={32} />
                </span>
              </Row>
              <Row justify="center">
                <RateExpertLabel>
                  {t(label)}
                </RateExpertLabel>
              </Row>
            </Col>
          </RateExpertAction>
        ))}
      </RateExpertActionsWrapper>
    </Space>
  );
};

export default ChatRateExpert;
