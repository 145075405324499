import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ChatAPI } from '@guuru/api-web';
import { emailHelper, textHelper } from '@guuru/helpers-common';
import { flow } from '@guuru/chat-web';
import useUserIsTyping from './hooks/useUserIsTyping';
import useCannedResponses from './hooks/useCannedResponses';
import TextInput from './TextInput';
import ReviewRateChatInput from './ReviewRateChatInput';
import StartNewFreshChatInput from './StartNewFreshChatInput';
import useUpdateInputBar from './hooks/useUpdateInputBar';
import MessageBar from './MessageBar';
import useHideMessageBar from './hooks/useHideMessageBar';

const ChatMessageBar = function ({
  chatId = '',
  isUser = false,
  isClosed = false,
  isReviewingRate = false,
  isFinished = false,
  inputType = null,
  onSubmit,
  onAskNewQuestion = () => {},
  onInputFocus = () => {},
  hasTextAction = null,
  onAttachmentSubmit = () => { throw Error('Missing onAttachmentSubmit'); },
  partnerId = '',
  inputState = '',
  noFocus = false,
}) {
  const [text, setText] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isEnabled, setIsEnabled] = useState(flow.STATE_DISABLED);
  const { hideMessageBar } = useHideMessageBar(isUser, isFinished, partnerId);
  const { initIsTyping, stopIsTyping } = useUserIsTyping(chatId, isUser);
  const [
    handleCannedResponseInput,
    cannedResponses,
  ] = useCannedResponses(partnerId, { skip: isUser });

  /** Handler for input text change */
  const handleInput = useCallback((value, onChange) => {
    switch (inputType) {
      case flow.INPUT_TYPE_EMAIL: {
        const isValidValue = emailHelper.isValid(value);
        setText(value);
        setIsValid(isValidValue);
        setIsEnabled(isValidValue ? flow.STATE_ENABLED : flow.STATE_DISABLED);
        break;
      }
      case flow.INPUT_TYPE_CHAT:
        initIsTyping();
        // eslint-disable-next-line no-fallthrough
      default:
        if (chatId) {
          ChatAPI.storeChatUserPendingInput(chatId, value);
        }
        setText(value);
        setIsValid(true);
        setIsEnabled(value.trim().length > 0
          ? flow.STATE_ENABLED
          : flow.STATE_DISABLED);

        if (!onChange) return;

        handleCannedResponseInput(value);
    }
  }, [
    inputType,
    initIsTyping,
    handleCannedResponseInput,
    chatId,
  ]);

  useUpdateInputBar(chatId, inputType, text, handleInput);

  const submit = useCallback((eventLabel, newText) => {
    const textType = textHelper.validateSingleEmoji(newText);

    if (!isValid || isEnabled !== flow.STATE_ENABLED) return;
    if (newText.length <= 0) return;
    if (!isUser && newText.startsWith('/')) return;
    if (!onSubmit) return;

    const isMessageBarLoading = inputType === flow.INPUT_TYPE_LOADING;
    const isChatReady = inputType === flow.INPUT_TYPE_CHAT && !chatId;

    if (isUser && (isMessageBarLoading || isChatReady)) return;
    const message = isUser ? newText.replace(/\n./g, '') : newText;
    onSubmit(eventLabel, message, textType);
    stopIsTyping();
    setText('');
    if (chatId) {
      ChatAPI.deleteChatUserPendingInput(chatId);
    }
    setIsEnabled(flow.STATE_DISABLED);
  }, [
    chatId,
    inputType,
    isEnabled,
    isUser,
    isValid,
    onSubmit,
    stopIsTyping,
  ]);

  if (hideMessageBar) {
    return null;
  }

  return (
    <MessageBar>
      <TextInput
        chatId={chatId}
        isVisible={!isUser || (!isClosed && !isFinished && !isReviewingRate)}
        isEnabled={isEnabled === flow.STATE_ENABLED}
        isValid={isValid}
        isUser={isUser}
        text={text}
        noFocus={noFocus}
        partnerId={partnerId}
        onAttachmentSubmit={onAttachmentSubmit}
        hasTextAction={hasTextAction}
        inputType={inputType}
        cannedResponses={cannedResponses}
        inputState={inputState}
        handleInputFocus={onInputFocus}
        handleInput={handleInput}
        submit={submit}
        setText={setText}
      />
      <ReviewRateChatInput
        isVisible={isUser && isReviewingRate}
        chatId={chatId}
      />
      <StartNewFreshChatInput
        isVisible={isUser && isFinished}
        onAskNewQuestion={onAskNewQuestion}
      />
    </MessageBar>
  );
};

ChatMessageBar.propTypes = {
  inputType: PropTypes.string,
  chatId: PropTypes.string,
  partnerId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isUser: PropTypes.bool,
  onAskNewQuestion: PropTypes.func,
  onInputFocus: PropTypes.func,
  onAttachmentSubmit: PropTypes.func,
  isClosed: PropTypes.bool,
  isReviewingRate: PropTypes.bool,
  isFinished: PropTypes.bool,
  inputState: PropTypes.string,
  hasTextAction: PropTypes.func,
  noFocus: PropTypes.bool,
};

export default ChatMessageBar;
