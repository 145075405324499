import {
  CheckOutlined,
  ApiOutlined,
  DisconnectOutlined,
  ForkOutlined,
  MinusCircleOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons';

const enabled = {
  key: 'enabled',
  name: 'Enabled',
  icon: CheckOutlined,
};

const disabled = {
  key: 'disabled',
  name: 'Disabled',
  icon: MinusCircleOutlined,
};

const subscribed = {
  key: 'subscribed',
  name: 'Subscribed',
  icon: CheckOutlined,
};

const unsubscribed = {
  key: 'unsubscribed',
  name: 'Unsubscribed',
  icon: MinusCircleOutlined,
};

const fallbackEnabled = {
  key: 'fallbackEnabled',
  name: 'Fallback enabled',
  icon: ApiOutlined,
};

const fallbackDisabled = {
  key: 'fallbackDisabled',
  name: 'Fallback disabled',
  icon: DisconnectOutlined,
};

const intentSubscriptionActions = [
  {
    name: 'Show Answer',
    value: 'showAnswer',
    icon: QuestionCircleOutlined,
  },
  {
    name: 'Greeting Catcher',
    value: 'greeting',
    icon: RetweetOutlined,
  },
  {
    name: 'Routing',
    value: 'routing',
    icon: ForkOutlined,
  },
];

const intentFilterBy = {
  unsubscribed: [
    fallbackEnabled,
    fallbackDisabled,
  ],
  subscribed: [
    enabled,
    disabled,
    fallbackEnabled,
    fallbackDisabled,
  ],
};

const IntentFilters = {
  enabled,
  disabled,
  fallbackEnabled,
  fallbackDisabled,
  filterBy: {
    key: 'intentsFilters',
    label: 'Show Only',
    filters: intentFilterBy,
  },
  action: {
    key: 'action',
    label: 'Action',
    filters: intentSubscriptionActions,
  },
  topic: {
    key: 'topic',
    label: 'Topic',
  },
  searchText: {
    key: 'searchText',
  },
  industry: {
    key: 'industry',
    label: 'Industry',
  },
};

const IntentStatus = {
  subscribed,
  unsubscribed,
};

export { IntentFilters, IntentStatus };
