import React from 'react';
import {
  Col,
  Flex,
  Grid,
  Skeleton,
  Typography,
} from 'antd';
import { makeVar, useQuery, useReactiveVar } from '@apollo/client';
import { Link, useLocation, useParams } from 'react-router-dom';
import CarouselWrapper from '@guuru/react-carousel';
import GET_COMMUNITY_FEEDBACKS from './queries/getCommunityFeedbacks';
import FeedbackContainer from './FeedbackContainer';
import QuoteSymbol from '../QuoteSymbol';
import FeedbackComment from './FeedbackComment';

const { Text, Title } = Typography;

const currentSlideVar = makeVar(0);
const { useBreakpoint } = Grid;

const Feedback = function () {
  const { partnerId } = useParams();
  const { search } = useLocation();
  const currentSlide = useReactiveVar(currentSlideVar);
  const { md } = useBreakpoint();

  const {
    data: {
      community: {
        bestFeedbackComments = [],
      } = {},
    } = {},
    loading,
  } = useQuery(GET_COMMUNITY_FEEDBACKS, {
    variables: { partnerId },
  });

  if (!loading && !bestFeedbackComments.length) {
    return null;
  }

  return (
    <FeedbackContainer align="center">
      <Skeleton active loading={loading}>
        <Title level={2}>Why Users love it</Title>
        <QuoteSymbol />
        <CarouselWrapper
          initialSlide={currentSlide}
          afterChange={currentSlideVar}
          slidesToShow={md ? 3 : 1}
          arrows
        >
          {bestFeedbackComments.map(({ comment, expert }) => (
            <Col key={comment}>
              <Flex vertical gap="small">
                <FeedbackComment level={5} italic>
                  {comment}
                </FeedbackComment>
                <Text>- Given to
                  <Link to={`/${partnerId}/guurus/${expert.externalId}${search}`}>
                    {` ${expert.name}`}
                  </Link> -
                </Text>
              </Flex>
            </Col>
          ))}
        </CarouselWrapper>
      </Skeleton>
    </FeedbackContainer>
  );
};

export default Feedback;
