import PropTypes from 'prop-types';
import React from 'react';
import { Image, Space, Typography } from 'antd';

const ImageCard = function ({
  title,
  description,
  icon,
}) {
  return (
    <Space direction="vertical" size={8}>
      <Image
        src={`https://cdn.guuru.com/assets/pages/invitation/howitworks/${icon}`}
        width={86}
        preview={false}
      />
      <Space direction="vertical" size={2}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Typography.Text type="secondary">{description}</Typography.Text>
      </Space>
    </Space>
  );
};

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
};

export default ImageCard;
