import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

const units = [
  { name: 'year', amount: 1000 * 60 * 60 * 24 * 365 },
  { name: 'month', amount: 1000 * 60 * 60 * 24 * 30 },
  { name: 'day', amount: 1000 * 60 * 60 * 24 },
];

const RelativeTime = function ({ date, locale = 'en' }) {
  const joinedAtDate = new Date(date);
  const nowDate = new Date();

  const dateUnit = units.find(({ amount }) => {
    const value = Math.floor((nowDate - joinedAtDate) / amount);
    return value > 0;
  });

  const format = { unit: 'day', value: 0 };

  if (dateUnit) {
    const value = Math.floor((nowDate - joinedAtDate) / dateUnit.amount);
    format.unit = dateUnit.name;
    format.value = -value;
  }

  const { unit, value } = format;
  const relativeTime = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  return <Text type="secondary">{relativeTime.format(value, unit)}</Text>;
};

RelativeTime.propTypes = {
  locale: PropTypes.string,
  date: PropTypes.number.isRequired,
};

export default RelativeTime;
