import styled from 'styled-components';
import { DeleteTwoTone } from '@ant-design/icons';

export default styled(DeleteTwoTone)`
  visibility: hidden;

  &&& {
    margin: 0 0.5rem;
    visibility: ${({ open }) => open && 'visible'};
    opacity: ${({ open }) => open && 1};
  }
`;
