import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { emailHelper } from '@guuru/helpers-common';
import { SendButtonOutlined } from '@guuru/react-icon';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import events from '@guuru/events-web';
import { useChatSettings } from '@guuru/chat-web';
import { Space } from 'antd';
import EmailInput from './EmailInput';
import GET_BAD_RATE_INFO from './queries/getBadRateInfo';
import ESCALATE_CHAT from './mutations/escalateChat';
import RATE_CHAT from './mutations/rateChat';
import ExpertFeedbackAvatar from '../../ExpertFeedbackAvatar';
import {
  EndNoEmailWrapper,
  RateBadExpertEndNoMail,
  RateBadExpertEndNoMailUnderline,
  RateBadExpertWrapper,
  RateExpertBadTeaserBottom,
  RateExpertBadTeaserTop,
  RateExpertBadTeaserWrapper,
} from './styles';

const ChatRateBadExpert = function () {
  const { t } = useTranslation();
  const [escalateChat, { loading: isEscalating }] = useMutation(ESCALATE_CHAT);
  const [rateChat] = useMutation(RATE_CHAT);
  const { useTrackEventOnce, rateBadPrompted } = events;

  const chatId = useSelector((state) => state.chatReducer.chatId);
  const {
    data: {
      chatSettings: {
        name: partnerName,
        isEscalationsEnabled,
      } = {},
    } = { },
  } = useChatSettings();

  const [emailValue, setEmailValue] = useState();
  const [validEmail, setValidEmail] = useState();

  const {
    data: { chat: { user, expert } = {} } = {},
  } = useSuspenseQuery(GET_BAD_RATE_INFO, {
    variables: { id: chatId },
    skip: !chatId,
  });

  useTrackEventOnce(() => (
    rateBadPrompted()
  ), [chatId]);

  useEffect(() => {
    setEmailValue(user?.email);
    setValidEmail(emailHelper.isValid(user?.email));
  }, [user]);

  const handleConfirmBadRateclick = useCallback((rating) => {
    if (rating === 'no-email') {
      rateChat({ variables: { id: chatId, rating: 'bad' } });
    } else if (emailHelper.isValid(emailValue)) {
      escalateChat({
        variables: {
          id: chatId,
          visitorEmail: emailValue,
        },
      });
    }
  }, [
    emailValue,
    rateChat,
    chatId,
    escalateChat,
  ]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    setEmailValue(value);
    setValidEmail(emailHelper.isValid(value));
  }, [setEmailValue, setValidEmail]);

  const handlePressEnter = useCallback(() => {
    handleConfirmBadRateclick('with-email');
  }, [handleConfirmBadRateclick]);

  let Icon = validEmail ? SendButtonOutlined : CloseOutlined;
  Icon = (isEscalating) ? LoadingOutlined : Icon;

  return (
    <RateBadExpertWrapper>
      <RateExpertBadTeaserWrapper direction="vertical">
        <Space direction="vertical" style={{ height: 25 }}>
          <ExpertFeedbackAvatar expertPhoto={expert?.photoUrl} />
        </Space>
        <RateExpertBadTeaserTop>
          {t('chat:::rateExpertBadHeadline', { expertName: expert?.name })}
        </RateExpertBadTeaserTop>
        {isEscalationsEnabled && (
          <RateExpertBadTeaserBottom>
            {t('chat:::rateExpertBadTeaser', { partnerName })}
          </RateExpertBadTeaserBottom>
        )}
      </RateExpertBadTeaserWrapper>
      {isEscalationsEnabled && (
        <EmailInput
          autoFocus
          enterButton={<Icon />}
          placeholder={t('chat:::placeholderEmail')}
          value={emailValue}
          onSearch={handlePressEnter}
          onChange={handleChange}
          type="email"
          style={{ fontFamily: 'inherit' }}
        />
      )}
      <EndNoEmailWrapper
        align="middle"
        justify="center"
        type="flex"
      >
        <RateBadExpertEndNoMail>
          {isEscalationsEnabled && t('chat:::rateExpertBadEndWithoutEmail01')}
          {' '}
          <RateBadExpertEndNoMailUnderline
            onClick={() => { handleConfirmBadRateclick('no-email'); }}
            onKeyPress={() => { handleConfirmBadRateclick('no-email'); }}
            role="button"
            tabIndex="0"
          >
            {t('chat:::rateExpertBadEndWithoutEmail02')}
          </RateBadExpertEndNoMailUnderline>
        </RateBadExpertEndNoMail>
      </EndNoEmailWrapper>
    </RateBadExpertWrapper>
  );
};

export default ChatRateBadExpert;
