import React from 'react';
import { Col, Row as AntRow, Typography } from 'antd';
import PropTypes from 'prop-types';
import { scaleRating } from '@guuru/rating-common';
import { Rating } from '@guuru/react-rating';
import styled from 'styled-components';

const { Title } = Typography;

const Row = styled(AntRow)`
  h1 {
    margin-bottom: 0;
  }

  .ant-rate-star {
    font-size: 18px;
  }
`;

const Name = function ({ expert }) {
  const { name, rating } = expert;

  return (
    <Row justify="space-between" align="middle" gutter={[16, 8]}>
      <Col flex="auto">
        <Title>{name}</Title>
      </Col>
      <Col flex="none">
        <Rating rating={scaleRating(rating)} hoverable={false} />
      </Col>
    </Row>
  );
};

Name.propTypes = {
  expert: PropTypes.shape({
    name: PropTypes.string,
    rating: PropTypes.number,
  }).isRequired,
};

export default Name;
