import { useEffect, useState } from 'react';

const ALLOW_FULLSCREEN_WIDTH = '768';

const useIsMobile = function () {
  const [isMobile, setIsMobile] = useState(
    window.outerWidth < ALLOW_FULLSCREEN_WIDTH,
  );
  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.outerWidth < ALLOW_FULLSCREEN_WIDTH);
    };
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, [setIsMobile]);
  return { isMobile };
};

export default useIsMobile;
