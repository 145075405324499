import styled from 'styled-components';

const ChatMessageBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 3.2rem;

  & > i {
    height: auto;
  }

  label {
    margin: 0;
  }

  && textarea {
    min-height: 2.25rem;
    border: none;
    resize: none;
  }

  && .ant-input {
    font-size: 1rem;
    line-height: 1.55rem;
    outline: none;
    border: none;
    box-shadow: none;
  }

  .chat-input,
  .chat-input.ant-select-auto-complete {
    flex-grow: 1;
    resize: none;

    &::placeholder {
      font-weight: 300;
      font-size: 1rem;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .chat-input.ant-select-single {
    height: inherit;
  }

  #chat-message-bar-attachment-input {
    display: none
  }
`;

export default ChatMessageBar;
