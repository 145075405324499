import { useEffect } from 'react';
import { flow } from '@guuru/chat-web';
import { ChatAPI } from '@guuru/api-web';

// Every time the inputType changes we need to recalculate
// the handleInput and check if the user had any pending messages
const useUpdateInputBar = (chatId, inputType, text, handleInput) => {
  useEffect(() => {
    let lastInput = text;
    if (flow.INPUT_TYPE_CHAT && chatId) {
      lastInput = ChatAPI.storeRetrieveChatUserPendingInput(chatId) ?? '';
    }
    handleInput(lastInput, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputType, chatId]);
};

export default useUpdateInputBar;
