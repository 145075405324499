import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export default gql`
  query GetPersistedTranslations(
    $id: ID,
    $namespaces: [Namespace!]!,
    $locale: Language!,
  ) {
    translations(id: $id, namespaces: $namespaces, locale: $locale)
  }
`;
