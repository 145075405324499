import { useChatSettings } from '@guuru/chat-web';
import log from '@guuru/logger-web';
import { ChatAPI } from '@guuru/api-web';

const useToken = function (partnerId) {
  const params = ChatAPI.storeRetrieveParams();
  const {
    loading,
    data: {
      chatSettings: {
        isTokenValid,
      } = {},
    } = {},
  } = useChatSettings(partnerId);

  if (!partnerId) {
    return { isTokenValid: true };
  }
  if (params.token && !loading && !isTokenValid) {
    log.info('Token not valid');
    return {
      isTokenValid: false,
      tokenInvalidMessage: 'Your token is no longer valid',
    };
  }
  return { isTokenValid: true };
};

export default useToken;
