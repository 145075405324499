import { useEffect } from 'react';

export default (
  eventFn = () => {},
  deps = [],
) => {
  useEffect(() => {
    eventFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
