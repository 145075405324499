import styled from 'styled-components';

const Emoji = styled.span`
  font-size: 5rem;
  height: 5rem;
  color: hsl(0deg 0% 39%);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Emoji;
