import React from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { updateChatStep } from '@guuru/chat-web';
import { ChatStep } from '@guuru/constants-common';
import ReopenChatCtaTeaser from './ReopenChatCtaTeaser';
import DELETE_CHAT_RATING from './mutations/deleteChatRating';

const ReviewRateChatInput = function ({ isVisible, chatId = '' }) {
  const { t } = useTranslation();

  const [deleteChatRating] = useMutation(DELETE_CHAT_RATING, {
    variables: { id: chatId },
  });

  const handleGoBackClick = async () => {
    await deleteChatRating();
    updateChatStep(ChatStep.closeChat);
  };

  if (!isVisible) { return null; }

  return (
    <ReopenChatCtaTeaser
      align="middle"
      justify="center"
      type="flex"
      className="chat-input"
      onClick={handleGoBackClick}
    >
      <LeftOutlined />
      <span>
        {' '}
        {t('chat:::rateExpertBadGoBack')}{' '}
      </span>
    </ReopenChatCtaTeaser>
  );
};

ReviewRateChatInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  chatId: PropTypes.string,
};

export default ReviewRateChatInput;
