import { gql } from '@apollo/client';
import communityExpertFragment
  from '../../../../../graphql/fragments/communityExpertFragment';

export default gql`
  ${communityExpertFragment}
  query GetPersistedBestExperts($partnerId: ID!, $first: Int) {
    community(id: $partnerId) {
      id
      experts(first: $first){
        edges {
          node {
            ...CommunityExpertFragment
          }
        }
      }
    }
  }
`;
