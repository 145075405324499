import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import events from '@guuru/events-web';
import { delay as sleep } from '@guuru/helpers-common';
import { messageTypes } from '@guuru/constants-common';

const { MESSAGE_TYPE_CATEGORY } = messageTypes;
const requestCategory = 'bot:::requestCategory';

const addBotRequestCategory = (categories, delay = true) => (
  sleep(delay ? ChatAPI.getBotEllipsisMessageDelay() : 0).then(() => {
    const message = ChatAPI.messages.createBotMessage(
      new Date().getTime(),
      requestCategory,
      MESSAGE_TYPE_CATEGORY,
      { categories },
      true,
      false,
    );
    if (
      Object.keys(categories).length === 0
      && categories.constructor === Object
    ) {
      ChatAPI.pushPendingMessages(message);
    }
    return message;
  })
);

export default {
  * validate(partnerId) {
    const { priority } = ChatAPI.storeRetrieveParams();
    if (priority === 'bot') {
      return false;
    }

    const {
      category: validCategory,
    } = yield ChatAPI.getChatSettings(partnerId);
    if (validCategory?.id) {
      ChatAPI.storeCategory(partnerId, validCategory.id);
      return false;
    }

    const categorySelected = ChatAPI.storeRetrieveCategorySelected();
    if (categorySelected) {
      return false;
    }

    const category = ChatAPI.storeRetrieveCategory();
    return !category;
  },
  * render(partnerId) {
    const { categories } = yield ChatAPI.getChatSettings(partnerId);
    const enabledCategories = categories.filter((cat) => cat.isActive);

    events.requestCategory();
    const requestCategoryMessage = yield addBotRequestCategory(
      enabledCategories,
    );
    yield put({
      type: types.CHAT_NEW_TEXT_SUCCESS,
      message: requestCategoryMessage,
    });
    return {};
  },

  * processResponse(partnerId, {
    category,
    categoryLabel,
  }) {
    const requestCategoryMessage = (
      yield addBotRequestCategory({}, false)
    );
    yield put({
      type: types.CHAT_NEW_TEXT_REPLACE_LAST,
      message: requestCategoryMessage,
    });
    const message = (
      yield ChatAPI.addCategory(partnerId, category, categoryLabel)
    );
    events.categorySubmitted(category);

    yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
  },
};
