const generateRandomId = function () {
  return (
    Math.random().toString(16)
    + Math.random().toString(16)
    + Math.random().toString(16)
  )
    .replace(/\./g, '')
    .replace('0', '')
    .substr(0, 32);
};

export default generateRandomId;
