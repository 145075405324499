import styled from 'styled-components';
import { ChatRowWrapper } from '@guuru/react-chat-messages';

const Section = styled(ChatRowWrapper)`
  text-align: center;
  width: 100%;
  z-index: 1;
`;

export default Section;
