import { gql } from '@apollo/client';

export default gql`
  mutation UploadChatAttachment($id: ID!, $input: UploadChatAttachmentInput!) {
    uploadChatAttachment(id: $id, input: $input) {
      writeUrl
      readUrl
      maxBytes
      acl
    }
  }
`;
