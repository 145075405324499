import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { flow as flowConstants } from '@guuru/chat-web';
import ChatRateExpert from './ChatRateExpert';
import ChatRateBadExpert from './ChatRateBadExpert';
import BottomBar from '../BottomBar';

const RateSection = function ({
  isUser,
  flowStep = null,
  doBefore = () => {},
}) {
  const isRateStep = isUser && [
    flowConstants.STEP_RATE,
    flowConstants.STEP_RATE_BAD,
  ].includes(flowStep);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (isRateStep) { doBefore(); } }, [isRateStep]);

  if (!isRateStep) {
    return null;
  }

  return (
    <BottomBar isUser={isUser}>
      <Suspense>
        {flowStep === flowConstants.STEP_RATE
          ? <ChatRateExpert />
          : <ChatRateBadExpert />}
      </Suspense>
    </BottomBar>
  );
};

RateSection.propTypes = {
  isUser: PropTypes.bool.isRequired,
  flowStep: PropTypes.string,
  doBefore: PropTypes.func,
};

export default RateSection;
