import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { DEFAULT_AVATAR_PHOTO } from '@guuru/constants-common';

const RelativeWrapperDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const AbsoluteAvatarDiv = styled.div`
  position: absolute;
  transform: translateY(-85px);
`;

const StyledAvatar = styled(Avatar)`
  box-shadow: 0 1px 7px rgba(0 0 0 / 25%);
  border: ${({ theme }) => `1px solid ${theme.onPrimaryColor}`};
  bottom: -20px;

  img {
    width: inherit !important;
    height: inherit !important;
  }
`;

const ExpertFeedbackAvatar = function ({ expertPhoto }) {
  if (expertPhoto === DEFAULT_AVATAR_PHOTO) return null;
  return (
    <RelativeWrapperDiv>
      <AbsoluteAvatarDiv>
        <StyledAvatar
          size={90}
          src={expertPhoto}
        />
      </AbsoluteAvatarDiv>
    </RelativeWrapperDiv>
  );
};

ExpertFeedbackAvatar.propTypes = {
  expertPhoto: PropTypes.string.isRequired,
};

export default ExpertFeedbackAvatar;
