import React, { useMemo } from 'react';
import { Badge, Avatar } from 'antd';
import styled from 'styled-components';
import { useChatSettings } from '@guuru/chat-web';

const HeroAvatar = styled(Avatar)`
  box-shadow: rgb(0 0 0 / 35%) 2px 2px 3px -1px;

  &.ant-avatar {
    border: 2px solid white;
  }

  &.ant-avatar:not(:first-child) {
    margin-left: -30px;
  }

  :first-child:nth-last-child(n+2) {
    box-shadow: rgb(0 0 0 / 35%) 0 1px 4px -1px;
  }
`;

const HeroesAvatar = function () {
  const {
    data: {
      chatSettings: {
        chatWidgetProfilesPictures = [],
      },
    } = {},
    loading,
  } = useChatSettings();

  const randomizedHeroes = useMemo(() => (
    [...chatWidgetProfilesPictures]
      .sort(() => 0.5 - Math.random())
      .slice(0, 3)
  ), [chatWidgetProfilesPictures]);

  if (loading) return null;

  return (
    <Badge dot status="success" size="small" offset={[-5, 30]}>
      <Avatar.Group size={36}>
        {randomizedHeroes.map((url) => (
          <HeroAvatar
            key={url}
            src={url}
          />
        ))}
      </Avatar.Group>
    </Badge>
  );
};

export default HeroesAvatar;
