import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SendButtonOutlined } from '@guuru/react-icon';
import { CloseOutlined } from '@ant-design/icons';
import SendButton from './style';

const ChatMessageSubmitButton = function ({
  onClick,
  isValid = false,
  isEnabled,
}) {
  const handleSubmitClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <SendButton
      role="button"
      $isActive={isEnabled}
      onClick={handleSubmitClick}
      onKeyPress={handleSubmitClick}
      tabIndex="-1"
    >
      {isValid ? <SendButtonOutlined /> : <CloseOutlined />}
    </SendButton>
  );
};

ChatMessageSubmitButton.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

export default ChatMessageSubmitButton;
