import { Ability } from '@casl/ability';
import {
  possibleRoles,
  membersRoles,
  allRoles,
  globalRoles,
  permissionsRoles,
  baseRoles,
  noPartnerRoles,
} from './src/roles';

const userRules = (partnerId, globalAbilities = [], partnerAbilities = {}) => {
  const rules = [];

  if (globalAbilities.length) {
    rules.push(...globalAbilities);
  }

  if (partnerId && partnerAbilities[partnerId]) {
    rules.push(...partnerAbilities[partnerId]);
  }

  return rules;
};

export default {
  forUser(globalAbilities, partnerAbilities, partnerId) {
    return new Ability(userRules(partnerId, globalAbilities, partnerAbilities));
  },

  forRole(abilities) {
    return new Ability(abilities);
  },
};

export {
  possibleRoles,
  membersRoles,
  allRoles,
  globalRoles,
  permissionsRoles,
  baseRoles,
  noPartnerRoles,
};
