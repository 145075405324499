import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import ZendeskSvg from './ZendeskOutlined.svg';

const ZendeskOutlined = function (props) {
  return (
    <Icon component={ZendeskSvg} {...props} />
  );
};

export default ZendeskOutlined;
