import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { Button } from './style';

const LinkOption = function ({
  id,
  name,
  onClick,
}) {
  return (
    <Row justify="center" style={{ marginTop: '5px' }}>
      <Button
        type="link"
        onClick={() => onClick(id, name)}
      >
        {name}
      </Button>
    </Row>
  );
};

LinkOption.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LinkOption;
