import ms from 'ms';
import { matchingConfig } from './MatchingConfig';

const { expertInactiveDays } = matchingConfig;

const mapBucket = (bucket, lastAcceptedAt) => {
  if (bucket === 'aboveThreshold') {
    if (!lastAcceptedAt) {
      return 'inactive';
    }
    const isInactive = (
      lastAcceptedAt.getTime() + expertInactiveDays * ms('1d') < Date.now()
    );
    return isInactive ? 'inactive' : 'active';
  }
  return bucket;
};

// eslint-disable-next-line import/prefer-default-export
export { mapBucket };
