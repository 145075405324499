const TransferTargetChannel = {
  chat: {
    label: 'Chat',
    permission: 'partner.chat',
  },
  form: {
    label: 'Form',
    permission: 'partner.form',
  },
};

module.exports = TransferTargetChannel;
