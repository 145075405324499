import { gql } from '@apollo/client';

export default gql`
  subscription ChatUpdated(
    $id: ID!,
    $isVisibleByExpert: Boolean,
    $isVisibleByUser: Boolean,
  ) {
    chatUpdated(id: $id) {
      id
      ...ChatPublicFields
      messages(
        isVisibleByExpert: $isVisibleByExpert
        isVisibleByUser: $isVisibleByUser
        orderBy: {
          direction: asc
          field: createdAt
        }
      ) {
        edges {
          node {
            ...MessageFields
          }
        }
      }
    }
  }
`;
