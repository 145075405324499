import { gql } from '@apollo/client';

export default gql`
  query GetCannedResponses(
    $partnerId: ID!,
    $prefix: String!,
    $first: Int,
    $after: String,
  ) {
    partner(id: $partnerId) {
      id
      cannedResponses(prefix: $prefix, first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            command
            response
            isCreatedByMe
          }
        }
      }
    }
  }
`;
