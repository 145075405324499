import cloneDeep from 'lodash/cloneDeep';

const CONFIG = 'Config';

const memLocalStorage = {};

const localStorageHelper = {
  save: (partnerId, value) => {
    try {
      localStorage[partnerId] = JSON.stringify(value);
    } catch (e) {
      memLocalStorage[partnerId] = value;
    }
  },

  get: (key) => {
    try {
      return (
        JSON.parse(localStorage[key] || '{}')
      );
    } catch (e) {
      return cloneDeep(memLocalStorage[key] || {});
    }
  },

  getConfig: (key) => {
    const config = localStorageHelper.get(CONFIG);
    return config[key];
  },

  setConfig: (key, value) => {
    const config = localStorageHelper.get(CONFIG);
    config[key] = value;
    localStorageHelper.save(CONFIG, config);
  },

  setObject: (partnerId, key, value) => {
    const partner = localStorageHelper.getAll(partnerId);
    partner[key] = value;
    localStorageHelper.save(partnerId, partner);
  },

  removeObject: (partnerId, key) => {
    const partner = localStorageHelper.getAll(partnerId);
    delete partner[key];
    localStorageHelper.save(partnerId, partner);
  },

  pushObject: (partnerId, key, value) => {
    const partner = localStorageHelper.getAll(partnerId);
    const list = partner[key] || [];
    list.push(value);
    partner[key] = [...list];
    localStorageHelper.save(partnerId, partner);
  },

  getObjectForChat: (partnerId, chatId, key) => {
    const partner = localStorageHelper.getAll(partnerId);
    const chats = partner.chatHistory || [];
    const chat = chats.find((c) => c.chatId === chatId);
    return chat && chat[key];
  },

  pushObjectForChat: (partnerId, chatId, values) => {
    const partner = localStorageHelper.getAll(partnerId);
    const chats = partner.chatHistory || [];
    const chat = chats.find((c) => c.chatId === chatId);
    if (!chat) {
      chats.push(values);
    } else {
      Object.assign(chat, values);
    }
    partner.chatHistory = chats;
    localStorageHelper.save(partnerId, partner);
  },

  getObject: (partnerId, key) => localStorageHelper.getAll(partnerId)[key],

  getAll: (partnerId) => localStorageHelper.get(partnerId),

  clear: (partnerId) => {
    localStorageHelper.save(partnerId, null);
  },
};

export default localStorageHelper;
