import { gql } from '@apollo/client';

export default gql`
  mutation TransferChat($chatId: ID!, $targetId: ID) {
    transferChat(id: $chatId, targetId: $targetId) {
      id
      status
      createdAt
      transfer {
        id
        confirmedAt
      }
    }
  }
`;
