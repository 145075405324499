import { gql } from '@apollo/client';

export default gql`
  query GetPersistedTotalMembers($partnerId: ID!) {
    community(id: $partnerId) {
      id
      stats {
        totalMembers
      }
    }
  }
`;
