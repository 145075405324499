// polyfill still needed for Edge 18
import 'fast-text-encoding';

const bufferToHex = (buffer) => {
  const view = new DataView(buffer);

  let hexCodes = '';
  for (let i = 0; i < view.byteLength; i += 4) {
    hexCodes += view.getUint32(i).toString(16).padStart(8, '0');
  }

  return hexCodes;
};

const create = (algorithm) => async (buffer, options) => {
  if (typeof buffer === 'string') {
    // eslint-disable-next-line no-param-reassign
    buffer = new TextEncoder().encode(buffer);
  }

  // eslint-disable-next-line no-param-reassign
  options = {
    outputFormat: 'hex',
    ...options,
  };

  const hash = await crypto.subtle.digest(algorithm, buffer);

  return options.outputFormat === 'hex' ? bufferToHex(hash) : hash;
};

const sha256 = create('SHA-256');

export default sha256;
