import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import Compressor from 'compressorjs';
import UPLOAD_PAGE_ATTACHMENT from './mutations/uploadFileAttachment';
import uploadGCSSignedUrl from '../uploadGCSSignedUrl';

const useFileAttachmentSubmit = function ({
  partnerId,
  onProgress = () => {},
  onUpload = () => {},
  onError = () => {},
  onFinish = () => {},
  allowedTypes = [
    /application\/*/,
    /image\/*/,
    /text\/*/,
    /video\/mp4/,
    /audio\/mpeg/,
  ],
}) {
  const [uploadFileAttachment] = useMutation(UPLOAD_PAGE_ATTACHMENT);
  const handleSubmit = useCallback(async ({ target: { files: [file] } }) => {
    if (!file) return null;

    let blob = file;
    if (!allowedTypes.some((type) => type.test(file.type))) {
      onError('Oops, you can only upload images, videos and documents.');
      return null;
    }

    onProgress();

    try {
      if (/image\/*/.test(file.type)) {
        blob = await new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(file, {
            quality: 0.8,
            mimeType: file.type,
            maxWidth: 2000,
            maxHeight: 1725,
            success: resolve,
            error: reject,
          });
        });
      }

      const filename = file.name;
      const mimetype = file.type;

      const {
        data: {
          uploadFileAttachment: {
            writeUrl,
            readUrl,
            maxBytes,
            acl,
          },
        },
      } = await uploadFileAttachment({
        variables: {
          id: partnerId,
          input: {
            filename,
            mimetype,
          },
        },
      });

      if (blob.size > maxBytes) {
        const maxMegaBytes = maxBytes / 1024 / 1024;
        throw new Error(`The file is too big, maximum size is ${maxMegaBytes}`);
      }

      await uploadGCSSignedUrl({
        contentType: mimetype,
        writeUrl,
        blob,
        maxBytes,
        acl,
      });

      await onUpload(readUrl, filename, mimetype);
      onFinish();
      return readUrl;
    } catch (err) {
      onError(err.message);
      return null;
    }
  }, [
    partnerId,
    uploadFileAttachment,
    allowedTypes,
    onError,
    onUpload,
    onFinish,
    onProgress,
  ]);

  return handleSubmit;
};

export default useFileAttachmentSubmit;
