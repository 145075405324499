import { generate, grey } from '@ant-design/colors';
import { getContrastingColor, hex2rgb } from '@guuru/theme-common';

export default (primaryColor) => {
  const primaryVariants = generate(primaryColor);
  const primaryColorRgb = hex2rgb(primaryColor);
  const contrastColor = getContrastingColor(primaryVariants);

  return {
    primaryColor,
    primaryColorRgb,
    primaryVariants,
    secondaryColor: primaryColor,
    secondaryVariants: primaryVariants,
    neutralVariants: grey,
    backgroundColor: 'white',
    borderColor: '#f0f0f0',
    surfaceColor: '#f0f0f0',
    secondarySurfaceColor: 'rgba(255, 255, 255, 0.8)',
    errorColor: '#ff4d4f',
    successColor: '#52c41a',
    onPrimaryColor: 'white',
    onSecondaryColor: contrastColor,
    onLightColor: '#424245',
    onBackgroundColor: primaryVariants[6],
    onSurfaceColor: grey[9],
    onErrorColor: 'white',
    smallRadius: '5px',
    mediumRadius: '20px',
    shadow: 'none',
    disabledBackgroundColor: '#f5f5f5',
    disabledBorderColor: '#d9d9d9',
    disabledTextColor: 'rgba(0, 0, 0, 0.25)',
  };
};
