import { gql } from '@apollo/client';

export default gql`
  query GetPersistedTotalChats($partnerId: ID!) {
    community(id: $partnerId) {
      id
      stats {
        totalChats
      }
    }
  }
`;
