import React from 'react';
import PropTypes from 'prop-types';
import OptionsList from '../../OptionsList';
import ChatMessageBubble from '../../ChatMessageBubble';

const ChatIntentOptions = function ({
  intents,
  handleSelection,
  languageCode,
  message,
  showOptions,
  readOnly = false,
}) {
  return (
    <ChatMessageBubble>
      <OptionsList
        message={message.text || message.i18n}
        options={intents}
        onSelection={handleSelection}
        showOptionChildren={false}
        languageCode={languageCode}
        showOptions={showOptions}
        readOnly={readOnly}
      />
    </ChatMessageBubble>
  );
};

ChatIntentOptions.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    i18n: PropTypes.string.isRequired,
  }).isRequired,
  intents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelection: PropTypes.func.isRequired,
  languageCode: PropTypes.string.isRequired,
  showOptions: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
};

export default ChatIntentOptions;
