import React from 'react';
import PropTypes from 'prop-types';
import { GuuruFilled } from '@guuru/react-icon';
import Logo from './Logo';

const LoadingView = function ({ loadingMessage = '', motion = true }) {
  return (
    <Logo $motion={motion}>
      <GuuruFilled style={{ fontSize: 78 }} />
      <h2>{loadingMessage}</h2>
    </Logo>
  );
};

LoadingView.propTypes = {
  loadingMessage: PropTypes.string,
  motion: PropTypes.bool,
};

export default LoadingView;
