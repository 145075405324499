import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  flow, setInputType, types, useLocalChat,
} from '@guuru/chat-web';
import { useMutation } from '@apollo/client';
import { ChatStep } from '@guuru/constants-common';
import ChatMessageBubble from '../../ChatMessageBubble';
import OptionsList from '../../OptionsList';
import SUBMIT_CHAT_BUTTON_MESSAGE from './mutations/submitChatButtonMessage';

const { INPUT_TYPE_CHAT, INPUT_TYPE_NONE } = flow;

const ChatRenderButton = function ({
  chatId,
  message,
  dispatch = () => {},
  readOnly = false,
}) {
  const { currentChatStep } = useLocalChat();
  const [submitChatButtonMessage] = useMutation(SUBMIT_CHAT_BUTTON_MESSAGE);
  const [disabled, setDisabled] = useState(message.metadata.submitted
    || readOnly
    || currentChatStep !== ChatStep.chat);

  const messageId = useMemo(() => message.id.split('.')[0], [message.id]);

  useEffect(() => {
    if (!disabled) { setInputType(INPUT_TYPE_NONE); }
    if (currentChatStep !== ChatStep.chat) { setDisabled(true); }
  }, [disabled, currentChatStep]);

  const onClick = useCallback(async (button) => {
    setDisabled(true);
    await submitChatButtonMessage({ variables: { chatId, messageId } });
    dispatch({
      type: types.CHAT_NEW_TEXT,
      text: button.name,
      step: 'STEP_CHAT',
      chatId,
    });
    setInputType(INPUT_TYPE_CHAT);
  }, [
    chatId, dispatch, messageId, submitChatButtonMessage,
  ]);

  return (
    <ChatMessageBubble fullWidth>
      <OptionsList
        message=""
        options={message.metadata.buttons}
        onSelection={onClick}
        readOnly={disabled}
      />
    </ChatMessageBubble>
  );
};

ChatRenderButton.propTypes = {
  chatId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.number,
    text: PropTypes.string,
    metadata: PropTypes.shape({
      submitted: PropTypes.bool,
      buttons: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  dispatch: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default withTranslation()(ChatRenderButton);
