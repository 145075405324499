import { gql } from '@apollo/client';

const GET_CHAT_MESSAGES = gql`
  query GetChatMessages(
    $id: ID!,
    $isVisibleByExpert: Boolean!,
    $isVisibleByUser: Boolean!,
  ) {
    chat(id: $id) {
      id
      rating
      status
      acceptedAt
      partner {
        id
        settings {
          id
          appearance {
            isUnreadMessagesEnabled
          }
        }
      }
      expert {
        id
        name
      }
      messages(
        isVisibleByExpert: $isVisibleByExpert
        isVisibleByUser: $isVisibleByUser
        orderBy: {
          direction: asc
          field: createdAt
        }
      ) {
        edges {
          node {
            ...MessageFields
          }
        }
      }
    }
  }
`;

export default GET_CHAT_MESSAGES;
