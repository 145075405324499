import React from 'react';
import { Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled(Typography.Text)`
  line-height: 2.0;
  font-size: 16px;
`;

const Bio = function ({ expert }) {
  const { bio } = expert;

  return bio && (
    <Row>
      <Text>{bio}</Text>
    </Row>
  );
};

Bio.propTypes = {
  expert: PropTypes.shape({
    bio: PropTypes.string,
  }).isRequired,
};

export default Bio;
