import { gql } from '@apollo/client';

export default gql`
  mutation UploadPartnerPicture($id: ID!, $input: UploadPartnerPictureInput!) {
    uploadPartnerPicture(id: $id, input: $input) {
      writeUrl
      readUrl
      maxBytes
      acl
    }
  }
`;
