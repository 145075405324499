import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { ConfigProvider } from 'antd';
import {
  StyleSheetManager,
  ThemeProvider as StyledProvider,
} from 'styled-components';
import classic from './src/variants/classic';
import clean from './src/variants/clean';

const ThemeProvider = function ({
  children,
  theme: {
    color,
    fontFamily,
    colorScheme = 'Clean',
    ...theme
  } = {},
  configProvider = {},
}) {
  const colors = useMemo(() => {
    switch (colorScheme) {
      case 'Clean':
        return clean(color);
      default:
        return classic(color);
    }
  }, [color, colorScheme]);

  const font = useMemo(() => (
    fontFamily ? { token: { fontFamily } } : {}
  ), [fontFamily]);

  const config = useMemo(() => (
    merge(
      {
        token: {
          borderRadius: parseInt(colors.smallRadius, 10),
          colorPrimary: colors.primaryColor,
          colorBgBase: colors.backgroundColor,
          colorError: colors.errorColor,
          colorSuccess: colors.successColor,
          colorTextBase: colors.onSurfaceColor,
          colorBorderSecondary: colors.borderColor,
        },
        components: {
          Layout: {
            headerBg: colors.secondarySurfaceColor,
          },
          Collapse: {
            colorBorder: colors.borderColor,
            colorFillAlter: colors.backgroundColor,
          },
          Avatar: {
            controlHeight: 40,
            controlHeightLG: 48,
          },
          Select: {
            controlItemBgActive: 'rgba(0 0 0 / 10%)',
          },
        },
      },
      font,
      configProvider,
    )
  ), [font, colors, configProvider]);

  return (
    <StyleSheetManager enableVendorPrefixes shouldForwardProp={() => true}>
      <StyledProvider theme={{ ...theme, ...colors }}>
        <ConfigProvider theme={config}>
          {children}
        </ConfigProvider>
      </StyledProvider>
    </StyleSheetManager>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  theme: PropTypes.shape({
    color: PropTypes.string.isRequired,
    colorScheme: PropTypes.string,
  }).isRequired,
  configProvider: PropTypes.shape({
    token: PropTypes.shape({}),
    components: PropTypes.shape({}),
  }),
};

export default ThemeProvider;
