import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const StickyMessage = function ({ children }) {
  const currentNode = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  }, []);

  return (
    <div ref={currentNode}>
      {children}
    </div>
  );
};

StickyMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StickyMessage;
