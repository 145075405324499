import React from 'react';
import { Flex, Typography } from 'antd';
import PropTypes from 'prop-types';
import QuoteSymbol from '../../QuoteSymbol';

const { Text } = Typography;

const Comment = function ({ comment }) {
  return (
    <Flex wrap={false} gap="small">
      <QuoteSymbol />
      <Text italic type="secondary">{comment}</Text>
    </Flex>
  );
};

Comment.propTypes = {
  comment: PropTypes.string.isRequired,
};

export default Comment;
