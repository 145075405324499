import { gql } from '@apollo/client';

export default gql`
  query GetBadRateInfo($id: ID!) {
    chat(id: $id){
      id
      user {
        id
        email
      }
      expert {
        id
        name
        photoUrl
      }
    }
  }
`;
