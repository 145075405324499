const HELPER = {
  randomInt: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),

  getParams: (props) => {
    const paramsString = props.location.search.replace('?', '');
    const paramsList = paramsString.split('&');
    const params = {};

    paramsList.forEach((param) => {
      const [key, value] = param.split('=');
      if (key !== '') {
        params[key] = value;
      }
    });
    return params;
  },

  filtersEquals: (filters, newFilters) => {
    const filterKeys = Object.keys(filters || {});
    const newFilterKeys = Object.keys(newFilters || {});
    if (filterKeys.length !== newFilterKeys.length) {
      return false;
    }

    if (filterKeys.length === 0 && newFilterKeys.length === 0) {
      return true;
    }

    if (filterKeys.length !== newFilterKeys.length) {
      return false;
    }

    return filterKeys.every(key => filters[key] === newFilters[key]);
  },
};

export default HELPER;
