import { gql } from '@apollo/client';

export default gql`
  query GetQuickQuestions($id: ID!, $autoMessageId: ID!, $locale: String!) {
    partner(id: $id) {
      id
      quickQuestions(id: $autoMessageId, locale: $locale) {
        id
        questions
      }
    }
  }
`;
