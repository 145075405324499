import React from 'react';
import PropTypes from 'prop-types';
import { SyncOutlined } from '@ant-design/icons';
import { ChatCta } from '@guuru/react-chatwidget';
import { withTranslation } from 'react-i18next';

const initialState = { spin: false, text: 'chat:::retry' };
let retrying = false;

class ChatConnectionRetryCta extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (retrying) {
      retrying = false;
      return prevState;
    }
    return initialState;
  }

  handleConnectionRetryCtaClick = () => {
    const { action } = this.props;
    action();
    retrying = true;
    this.setState({ spin: true, text: 'chat:::retrying' });
  };

  render() {
    const { t, visible } = this.props;
    const { spin, text } = this.state;
    if (!visible) {
      return null;
    }
    return (
      <ChatCta onClick={this.handleConnectionRetryCtaClick}>
        <SyncOutlined spin={spin} style={{ verticalAlign: 'middle' }} />
        {' '}
        <strong>{t(text)}</strong>
      </ChatCta>
    );
  }
}

ChatConnectionRetryCta.propTypes = {
  action: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ChatConnectionRetryCta.defaultProps = {
  visible: false,
};

export default withTranslation()(ChatConnectionRetryCta);
