import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { i18n } from '@guuru/translation-web';
import { botMessagesTypes } from '@guuru/chat-web';
import ChatRenderText from '../../Text';
import { Contacts } from './style';

const { Text } = Typography;

const ContactsSubmited = function ({
  message,
  readOnly = false,
  phone = null,
  email = null,
}) {
  const { smartFormStyle = false } = message;

  const renderContactInformation = function () {
    const { contacts = {} } = message?.metadata || {};
    contacts.phone = phone;
    contacts.email = email;
    return (
      <Contacts direction="vertical" size={4}>
        {['email', 'phone']
          .filter((type) => contacts[type])
          .map((type) => (
            <Space key={type} size={6} align="center">
              {type === 'email' ? <MailOutlined /> : <PhoneOutlined />}
              <Text ellipsis>{contacts[type]}</Text>
              <CheckOutlined />
            </Space>
          ))}
      </Contacts>
    );
  };

  let confirmedMessage;
  if (readOnly) {
    confirmedMessage = message.text;
  } else {
    confirmedMessage = smartFormStyle
      ? i18n.t(botMessagesTypes.requestSentSmartForm)
      : i18n.t(botMessagesTypes.transferContactInformationConfirmed);
  }
  return (
    <ChatRenderText
      message={{ ...message, i18n: confirmedMessage }}
      note={smartFormStyle}
      tooltip={!smartFormStyle}
    >
      {!readOnly && renderContactInformation()}
    </ChatRenderText>
  );
};

ContactsSubmited.propTypes = {
  readOnly: PropTypes.bool,
  phone: PropTypes.string,
  email: PropTypes.string,
  message: PropTypes.shape({
    createdAt: PropTypes.number,
    metadata: PropTypes.shape({
      contacts: PropTypes.shape({
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
    smartFormStyle: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
};

export default withTranslation()(ContactsSubmited);
