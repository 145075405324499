const USER_CHAR = '[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]';
const USER = `(?:${USER_CHAR}\\.?)*${USER_CHAR}`;
const DOMAIN_PART = '(?:[a-zA-Z0-9]-?)*[a-zA-Z0-9]';
const TLD = '[a-zA-Z](?:-?[a-zA-Z0-9])*';
const DOMAIN = `(?:${DOMAIN_PART}\\.)+${TLD}`;
const EMAIL_PATTERN = new RegExp(`^${USER}@${DOMAIN}$`);

const Email = {
  isValid: (address) => EMAIL_PATTERN.test(address),
};

export default Email;
