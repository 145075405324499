import { gql } from '@apollo/client';

export default gql`
  mutation TransferByEmail($chatId: ID!, $targetId: ID) {
    transferByEmail(chatId: $chatId, targetId: $targetId) {
      id
      status
    }
  }
`;
