import i18n from 'i18next';

const EXPERT_DEFAULT_ALIAS = 'guuru';

i18n.init({
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
  debug: false,
  ns: ['chat', 'bot', 'expert'],
  defaultNS: 'chat',
  interpolation: {
    defaultVariables: { expertAlias: EXPERT_DEFAULT_ALIAS },
  },
  resources: {},
  // allow keys to be phrases having `:`, `.`
  nsSeparator: ':::',
  // react i18next special options (optional)
  react: {
    useSuspense: false,
  },
});

export default i18n;
