import styled from 'styled-components';
import { ChatRowWrapper } from '@guuru/react-chat-messages';

export default styled(ChatRowWrapper)`
  background-color: ${({ theme }) => (theme.chatLayout ? theme.primaryColor : null)};
  color: ${({ theme }) => (theme.chatLayout ? theme.onPrimaryColor : '#686868')};
  margin-top: ${({ theme }) => (theme.chatLayout ? '1rem' : '0')};
  padding: 1rem 0;
  position: static;
  width: 100%;
  bottom: 0;
  box-shadow: ${({ theme }) => (theme.chatLayout ? '0 1px 7px rgba(0, 0, 0, 0.3)' : 'none')};

  > div {
    display: flex;
    justify-content: center;
  }
`;
