import { Space } from 'antd';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Spacer = styled(Space)`
  padding-bottom: 0.5rem;
  align-self: end;
  align-items: flex-start;

  .ant-avatar-group {
    flex-flow: row wrap;

    & .ant-avatar {
      border: none;
      box-shadow: 0 0 0 2px #fff;
      background: transparent;

      &:not(:first-child) {
        margin-left: 0;
      }

      .ant-avatar-string {
        font-size: 1.2em;
      }
    }
  }
`;
