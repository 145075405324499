import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ContactWrapper = styled.span`
  ${({ $showShadow }) => !!$showShadow && css`
    .chat-message.chat-message.chat-message.chat-message {
      box-shadow: rgba(0 0 0 / 10%) 0 4px 15px 0, rgba(0 0 0 / 10%) 0 1px 2px 0;
      border: 2px solid ${({ theme }) => theme.primaryVariants[3]};
    }
  `}

  button[type="submit"] {
    font-weight: bold;
    padding: 1.2rem 2.5rem;
    border: none;
    color: ${({ theme }) => theme.onSecondaryColor};

    &:hover {
      box-shadow: rgba(0 0 0 / 10%) 0 3px 5px 0, rgba(0 0 0 / 6%) 0 2px 4px 0;
    }
  }
`;
