import { gql } from '@apollo/client';

export default gql`
  mutation UploadProfilePicture($id: ID!) {
    uploadProfilePicture(id: $id) {
      writeUrl
      readUrl
      maxBytes
      acl
    }
  }
`;
