import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HeadlineText = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1.125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

const FeedbackHeadline = function ({ expertName, rating }) {
  const translationKey = rating === 'good' ? 'feedbackFormHeadlinePositive' : 'feedbackFormHeadlineNotPositive';
  const { t } = useTranslation();
  return (
    <HeadlineText>
      {t(`chat:::${translationKey}`, { expertName })}
    </HeadlineText>
  );
};

FeedbackHeadline.propTypes = {
  expertName: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
};

export default FeedbackHeadline;
