import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import ms from 'ms';
import { useMutation } from '@apollo/client';
import SET_EXPERT_IS_TYPING from './mutations/setExpertIsTyping';
import SET_USER_IS_TYPING from './mutations/setUserIsTyping';

const useUserIsTyping = function (chatId, isUser) {
  const [setTyping] = useMutation(
    isUser
      ? SET_USER_IS_TYPING
      : SET_EXPERT_IS_TYPING,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkIfTyping = useCallback(debounce(() => {
    if (!chatId) return;
    setTyping({ variables: { id: chatId, value: true } });
  }, ms('6s'), { leading: true, trailing: false }), [chatId, setTyping]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkIfNotTyping = useCallback(debounce(() => {
    if (!chatId) return;
    setTyping({ variables: { id: chatId, value: false } });
  }, ms('6s')), [chatId, setTyping]);

  const initIsTyping = useCallback(() => {
    checkIfTyping();
    checkIfNotTyping();
  }, [checkIfNotTyping, checkIfTyping]);

  const stopIsTyping = useCallback(() => {
    checkIfTyping.cancel();
    checkIfNotTyping.cancel();
  }, [checkIfNotTyping, checkIfTyping]);
  return { initIsTyping, stopIsTyping };
};

export default useUserIsTyping;
