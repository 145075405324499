import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

export default styled(Title)`
  font-size: 13px !important;
  opacity: 0.8;
  text-shadow: rgba(255 255 255 / 30%) 0 0 30px;
`;
