import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form as AntForm,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  destroy,
  error,
  loading,
  success,
} from '@guuru/react-message';
import handleFormErrors from './handleFormErrors';

const AntField = AntForm.Item;
const FormList = AntForm.List;

const { useForm, useWatch } = AntForm;

const ConditionalFields = function ({
  field,
  visible = (value) => value,
  children,
}) {
  return (
    <AntForm.Item shouldUpdate noStyle>
      {({ getFieldValue }) => (visible(getFieldValue(field)) && children)}
    </AntForm.Item>
  );
};

ConditionalFields.propTypes = {
  field: PropTypes.string.isRequired,
  visible: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const Field = function ({
  children,
  orientation = 'vertical',
  ...fieldProps
}) {
  return (
    <AntField
      {...fieldProps}
      className={orientation !== 'horizontal' && 'ant-form-vertical'}
    >
      {children}
    </AntField>
  );
};

Field.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  orientation: PropTypes.string,
};

const FormDivider = function ({ text }) {
  return (
    <Row style={{ fontSize: 15, padding: '8px 0 12px 0' }}>
      <Typography.Text strong>{text}</Typography.Text>
    </Row>
  );
};

FormDivider.propTypes = {
  text: PropTypes.string.isRequired,
};

const Form = function ({
  form,
  onSave,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  isSaving = false,
  savingMessage = '',
  savedMessage = '',
  children,
  extra = undefined,
  extraAction = undefined,
  canSubmit = true,
  ...formProps
}) {
  const onFinish = useCallback(async (values) => {
    if (savingMessage) {
      loading(savingMessage);
    }

    try {
      await onSave(values);
      form.setFields(
        Object.keys(values).map((name) => ({
          name,
          touched: false,
          errors: [],
        })),
      );

      if (savedMessage) {
        success(savedMessage);
      }
    } catch (err) {
      if (savedMessage || savingMessage) {
        destroy();
      }
      handleFormErrors(err, form, { showError: error });
    }
  }, [
    onSave,
    savingMessage,
    savedMessage,
    form,
  ]);

  const validateMessages = {
  // eslint-disable-next-line no-restricted-globals,no-template-curly-in-string
    required: "'${label}' is required",
  };

  return (
    <AntForm
      form={form}
      layout="vertical"
      onFinish={onFinish}
      {...formProps}
      validateMessages={validateMessages}
    >
      {children}
      {canSubmit && (
        <Field shouldUpdate>
          {({ isFieldsTouched, resetFields }) => (
            <Space style={{ paddingTop: 16 }}>
              <Button
                loading={isSaving}
                type="primary"
                htmlType="submit"
                disabled={!isFieldsTouched()}
              >
                {saveLabel}
              </Button>
              <Button
                danger
                onClick={() => resetFields()}
                disabled={isSaving || !isFieldsTouched()}
              >
                {cancelLabel}
              </Button>
              {extraAction}
            </Space>
          )}
        </Field>
      )}
      {extra}
    </AntForm>
  );
};

Form.propTypes = {
  form: PropTypes.shape({
    setFields: PropTypes.func.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  saveLabel: PropTypes.string,
  savingMessage: PropTypes.string,
  savedMessage: PropTypes.string,
  cancelLabel: PropTypes.string,
  isSaving: PropTypes.bool,
  canSubmit: PropTypes.bool,
  extra: PropTypes.node,
  extraAction: PropTypes.node,
};

export default Form;
export {
  Field,
  FormList,
  ConditionalFields,
  useForm,
  useWatch,
  FormDivider,
};
