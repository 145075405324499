import { put, cancel } from 'redux-saga/effects';
import { parse, stringify } from '@guuru/url-common';
import { ChatAPI } from '@guuru/api-web';
import log from '@guuru/logger-web';
import events from '@guuru/events-web';
import { types, flow } from '@guuru/chat-web';
import runNextStep from './chatSteps';
import buildChatSteps from './chatActions/buildChatSteps';
import submitChatMessage from './chatActions/submitChatMessage';
import createChat from './chatActions/createChat';
import handleChatStatusChange from './chatActions/handleChatStatusChange';

/**
 * Initializes a new Chat.
 * This method is used when no chat data is found so a new conversation
 * will be created from scratch.
 */
export const chatInitSaga = function* (action) {
  yield put({ type: types.CHAT_LOADING });

  yield buildChatSteps(action);
  yield runNextStep({ action });
};

/**
 * Handles a new Text message.
 * This method interprets the type of message based on the current flow
 */
export const chatNewText = function* (payload) {
  // Prepare flow params
  const flowParams = { ...payload };
  switch (payload.step) {
    case flow.STEP_QUESTION:
    case flow.STEP_NAME:
    case flow.STEP_EMAIL:
      flowParams.type = types.CHAT_RUN_NEXT_STEP;
      flowParams.processArgs = {
        text: payload.text,
        skipAutomation: payload.skipAutomation,
      };
      break;
    case flow.STEP_CHAT:
    case flow.STEP_CONNECTING_TO_EXPERT:
      yield submitChatMessage(payload);
      return;
    default:
      throw Error(`Unexpected chat flow step caught: ${payload.step}`);
  }
  yield put(flowParams);
};

/** Starts a new chat process reseting existing data */
export const chatNewQuestion = function* (action) {
  try {
    const {
      partnerId, navigate, location, options,
    } = action;
    events.newChatClick();

    const parameters = parse(location.search);
    delete parameters.payloadQuestion;
    const channel = options?.channel || 'chat';
    const chatUrl = ChatAPI.getChatURL(partnerId, channel, {
      qs: `?${stringify(parameters)}`,
    });
    navigate(chatUrl, { replace: true });

    ChatAPI.storeReset(partnerId);

    // Restart chat
    const locale = ChatAPI.storeRetrieveLocale();
    const { payloadQuestion, ...params } = ChatAPI.storeRetrieveParams();
    yield put({
      type: types.CHAT_INIT,
      partnerId,
      chatId: null,
      locale,
      params,
      options,
    });
  } catch (error) {
    log.error(error);
  }
};

// Chat Error
export const handleChatError = function* ({ partnerId }) {
  try {
    const {
      chatId,
      chatControlToken,
      updatedChat,
    } = yield createChat(partnerId);

    if (!chatId) yield cancel();

    yield put({
      type: types.CHAT_READY,
      chatId,
      chatControlToken,
      messages: updatedChat?.messages?.edges,
    });
    handleChatStatusChange({ chatId, ...updatedChat });
  } catch (error) {
    log.error(error);
  }
};
