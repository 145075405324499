import styled from 'styled-components';

const LinkPreviewCard = styled.div`
  max-width: 350px;

  && a {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    color: initial;

    &:hover {
      filter: brightness(1.05) contrast(0.95);
    }
  }

  .chat-message-link-preview-body {
    width: 70%;
    flex-grow: 1;

    h3,
    p {
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 0;
    }

    h3 {
      font-weight: bold;
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
    }

    p {
      padding-bottom: 0.75rem;
    }
  }
`;

const LinkPreviewCover = styled.div`
  filter: contrast(0.95);
  width: ${(props) => (props.orientation === 'portrait' ? '25%' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  img {
    max-width: 100%;
  }
`;

export { LinkPreviewCard, LinkPreviewCover };
