const regResult = /\[([\w\W]+)]\s*\(([\w\\/.,%&#?=:-]+)\)/;

export default (text, pos = 1) => {
  const tail = text.slice(pos - 1);

  if (!regResult.test(tail)) return 0;
  const [match] = tail.match(regResult);

  return match.length - 1 || false;
};
