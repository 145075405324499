import styled from 'styled-components';
import { Button } from 'antd';

const AskAnotherQuestionButton = styled(Button)`
  && {
    font-weight: bold;
    padding: 1.2rem 1rem;
    border: none;
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.onSecondaryColor};

    &&:hover {
      box-shadow: rgba(0 0 0 / 10%) 0 3px 5px 0, rgba(0 0 0 / 6%) 0 2px 4px 0;
      background-color: ${({ theme }) => theme.primaryVariants[4]};
      color: ${({ theme }) => theme.onSecondaryColor};
    }
  }

`;

export default AskAnotherQuestionButton;
