import React from 'react';
import {
  Button, Col, Flex, Grid, Image, Row, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import events from '@guuru/events-web';
import { useCommunitySettings } from '@guuru/community-web';
import Paragraph from '../../Paragraph';

const { useBreakpoint } = Grid;

const getInvitationLink = (partnerId) => (
  `https://guuru.app.link/?$deeplink_path=community/${partnerId}&~customer_campaign=${partnerId}`
);

const AboutCommunity = function () {
  const {
    partnerName,
    partnerId,
    invitationHeroUrl,
  } = useCommunitySettings();
  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  return (
    <Row gutter={[64, 36]} align="middle">
      <Col
        xs={24}
        md={10}
        xxl={6}
        align="middle"
      >
        <Image
          preview={false}
          src={invitationHeroUrl}
          fallback="https://cdn.guuru.com/assets/images/pages/invitation_hero.png"
          style={{ maxWidth: '500px' }}
        />
      </Col>
      <Col xs={24} md={14} xxl={18}>
        <Flex gap={24} vertical>
          <Space direction="vertical" size={12}>
            <Typography.Title level={3}>
              {t('pages:::descriptionTitle', { partnerName })}
            </Typography.Title>
            <Paragraph type="secondary">
              {t('pages:::descriptionIntro', { partnerName })}
            </Paragraph>
          </Space>
          <Row>
            <Button
              block={xs}
              size="large"
              type="primary"
              shape="round"
              href={getInvitationLink(partnerId)}
              onClick={events.openInvitationLink}
            >
              {t('pages:::joinButton')}
            </Button>
          </Row>
        </Flex>
      </Col>
    </Row>
  );
};

export default AboutCommunity;
