import React from 'react';
import PropTypes from 'prop-types';
import Title from './styles';

const SubTitle = function ({ text = null }) {
  if (!text) return null;
  return (
    <Title level={3}>{text}</Title>
  );
};

SubTitle.propTypes = {
  text: PropTypes.string,
};

export default SubTitle;
