import { colors } from '@guuru/constants-common';
import styled from 'styled-components';
import Item from './Item';

const background = function ({ theme, $disabled }) {
  if ($disabled) return colors.backgroundOptionDisabled;
  return theme.backgroundColor;
};

const color = function ({ theme, $disabled, $highlighted }) {
  if ($disabled) return colors.textOptionDisabled;
  if ($highlighted) return theme.primaryVariants[7];
  return theme.onSurfaceColor;
};

const border = function ({ theme, $disabled, $highlighted }) {
  if ($disabled) return `1px dotted ${theme.neutralVariants[0]}`;
  if ($highlighted) return `2px solid ${theme.primaryColor}`;
  return `1px solid ${theme.neutralVariants[0]}`;
};

export default styled(Item)`
  padding: 0.3rem;
  border: ${(props) => border(props)};
  margin: 5px 12px 5px 0;
  border-radius: ${({ theme }) => theme.smallRadius};
  width: 100%;
  min-width: inherit;
  font-size: 0.82rem;
  font-weight: ${({ $highlighted }) => ($highlighted ? 600 : 'inherit')};
  background-color: ${(props) => background(props)};
  color: ${(props) => color(props)};
  cursor: ${({ $disabled }) => (!$disabled ? 'pointer' : 'not-allowed')};

  h4 {
    font-weight: 400;
  }

  &:hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.secondaryColor};
    border-color: ${({ theme }) => theme.primaryVariants[6]};

    &,
    h4,
    div,
    svg {
      color: ${({ theme }) => theme.onSecondaryColor};
    }
  }
`;
