import styled from 'styled-components';
import { Col as AntCol, Row as AntRow } from 'antd';
import Item from '../../Option';

const SmartFormIntentOption = styled(Item)`
  padding: 5px 15px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: inherit;

  &:hover {
    background-color: ${({ theme }) => theme.primaryVariants[4]};
  }
`;

const Col = styled(AntCol)`
  text-align: center;
`;

const Row = styled(AntRow)`
  margin-top: 25px;
  justify-content: center;
`;

export { SmartFormIntentOption, Col, Row };
