import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Typography,
  Avatar as AntAvatar,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Avatar from './Avatar';
import ExpertNotificationsTags from './ExpertNotificationsTags';
import ExpertName from './ExpertName';

const ProfilerWrapper = styled('span')`
  button {
    visibility: hidden;
    margin-left: 4px;
  }

  &:hover {
    button {
      visibility: visible;
    }
  }
`;

const TextWrap = styled.span`
  word-wrap: anywhere;
`;

const ExpertAvatar = function ({
  expert = false,
  hideEmail = false,
  description = null,
  showTags = false,
  showEmailVerified = false,
  extraTitle = undefined,
  tooltipTitle = '',
  showAvatarOnly = false,
  style = {},
  endTitle = null,
  size = undefined,
}) {
  const isExternal = expert?.id.length !== 28;
  const descriptionNode = description || (
    !hideEmail && (<TextWrap>{expert?.email}</TextWrap>)
  );

  const title = description
    ? (
      <>
        {expert === null ? '<not available>' : null}
        <ExpertName expert={expert} isExternal={isExternal} />
        <Typography.Text type="secondary">
          {' '}
          {!hideEmail ? expert?.email : ''}
          {extraTitle ? <>{' ('}{extraTitle}{')'}</> : ''}
        </Typography.Text>
      </>
    )
    : <ExpertName expert={expert} isExternal={isExternal} />;

  if (showAvatarOnly) {
    return (
      <Tooltip title={tooltipTitle} key={expert?.uid || expert?.id || 0}>
        <div style={style}>
          {expert === null
            ? <AntAvatar size={size} icon={<QuestionOutlined />} />
            : (
              <Link
                to={`/experts/${expert?.id}`}
                href={`/experts/${expert?.id}`}
              >
                <TextWrap>
                  <Avatar
                    src={expert?.photoUrl}
                    text={expert?.name}
                    key={expert.id}
                    size={size}
                  />
                </TextWrap>
              </Link>
            )}
        </div>
      </Tooltip>
    );
  }
  return ([
    (<List.Item.Meta
      key={expert?.uid || expert?.id || 0}
      avatar={(
        expert === null
          ? <AntAvatar size={size} icon={<QuestionOutlined />} />
          : <Avatar src={expert?.photoUrl} size={size} text={expert?.name} />
      )}
      title={(
        expert !== null && !isExternal
          ? (
            <ProfilerWrapper>
              <Link
                to={`/experts/${expert?.id}`}
                href={`/experts/${expert?.id}`}
              >
                <TextWrap>{title}</TextWrap>
              </Link>
              <span style={{ paddingLeft: 8 }}>
                {endTitle}
              </span>
            </ProfilerWrapper>
          )
          : (<TextWrap>{title}</TextWrap>)
      )}
      description={descriptionNode}
    />
    ),
    (
      showTags
      && (
        <div
          key={`${expert?.uid || expert?.id}-tags`}
        >
          <ExpertNotificationsTags
            expert={expert || null}
            showEmailVerified={showEmailVerified}
          />
        </div>
      )
    ),
  ]
  );
};

ExpertAvatar.propTypes = {
  expert: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    uid: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    email: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
  }),
  hideEmail: PropTypes.bool,
  showTags: PropTypes.bool,
  showEmailVerified: PropTypes.bool,
  description: PropTypes.node,
  extraTitle: PropTypes.node,
  tooltipTitle: PropTypes.string,
  endTitle: PropTypes.node,
  showAvatarOnly: PropTypes.bool,
  style: PropTypes.shape(),
  size: PropTypes.number,
};

export default ExpertAvatar;
