import { gql } from '@apollo/client';

export default gql`
  mutation UploadFileAttachment($id: ID!, $input: UploadFileAttachmentInput!) {
    uploadFileAttachment(id: $id, input: $input) {
      writeUrl
      readUrl
      maxBytes
      acl
    }
  }
`;
