import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PoweredBy } from '@guuru/react-powered-by';
import Section from './Section';

const ChatPoweredBy = function ({ visible = false, isUser, partnerId }) {
  const { t } = useTranslation();

  if (!visible) {
    return null;
  }
  const poweredByCampaignUrl = `https://www.guuru.com/?utm_source=partnerWebsite&utm_medium=chatWidget&utm_campaign=partnerChatWidget&utm_term=${partnerId}&utm_content=${partnerId}`;
  return (
    <Section isUser={isUser}>
      <PoweredBy
        text={t('chat:::poweredBy')}
        url={poweredByCampaignUrl}
      />
    </Section>
  );
};

ChatPoweredBy.propTypes = {
  visible: PropTypes.bool,
  partnerId: PropTypes.string.isRequired,
  isUser: PropTypes.bool.isRequired,
};

export default ChatPoweredBy;
