import { ChatAPI } from '@guuru/api-web';
import { getCurrentUserId } from '@guuru/auth-web';
import log from '@guuru/logger-web';
import events from '@guuru/events-web';

export default async function ({
  id,
  text,
  chatId,
  textType,
  isUser = true,
}) {
  try {
    const uid = await getCurrentUserId();
    if (!uid) {
      log.warn('No user found');
      window.location.reload();
    }
    events.chatWithExpert(text);
    const isBot = !isUser;
    await ChatAPI.addMessage(
      id,
      uid,
      chatId,
      isUser,
      isBot,
      text,
      null,
      null,
      textType,
    );
  } catch (error) {
    log.error(error);
  }
}
