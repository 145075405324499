import styled from 'styled-components';
import { InputNumber } from 'antd';

export default styled(InputNumber)`
  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 812px) {
    font-size: 1rem;
  }
`;
