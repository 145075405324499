import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { types, useLocalChat } from '@guuru/chat-web';
import { ChatStep } from '@guuru/constants-common';
import SmartFormPredictOptions from './SmartFormPredictOptions';
import ChatPredictOptions from './ChatPredictOptions';
import ChatRenderText from '../Text';

const ChatRenderPredictionConfirmation = function ({
  message,
  readOnly = false,
  preview = false,
  dispatch = () => {},
}) {
  const { makePrediction } = ChatStep;
  const { currentChatStep } = useLocalChat();
  const requestIntentOption = currentChatStep === makePrediction;
  const [
    showOptions,
    setShowOptions,
  ] = useState(preview || requestIntentOption);

  const {
    smartFormStyle = false,
    metadata: {
      subscriptionPriority,
      subscriptionTarget,
    } = {},
  } = message;

  const handleSelection = useCallback((option, optionTranslated) => {
    setShowOptions(false);
    return dispatch({
      type: types.CHAT_RUN_NEXT_STEP,
      processArgs: {
        response: option,
        text: optionTranslated,
        smartFormStyle,
        subscriptionPriority,
        subscriptionTarget,
      },
    });
  }, [
    dispatch,
    smartFormStyle,
    subscriptionPriority,
    subscriptionTarget,
  ]);

  if (readOnly) {
    return <ChatRenderText message={message} />;
  }

  const PredictOptions = smartFormStyle
    ? SmartFormPredictOptions
    : ChatPredictOptions;
  return (
    <PredictOptions
      message={message}
      onSelect={handleSelection}
      showOptions={showOptions && currentChatStep === ChatStep.makePrediction}
    />
  );
};

ChatRenderPredictionConfirmation.propTypes = {
  message: PropTypes.shape({
    smartFormStyle: PropTypes.bool,
    metadata: PropTypes.shape({}),
  }).isRequired,
  readOnly: PropTypes.bool,
  preview: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default ChatRenderPredictionConfirmation;
