import React from 'react';
import { Form, Input } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const StyledInput = styled(TextArea)`
  border-radius: 5px;
  margin-top: 12px;
  border-color: transparent;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 3px 0, rgb(0 0 0 / 6%) 0 1px 2px 0;
  width: 99%;

  &.ant-input {
    &:focus,
    &:active,
    &:hover {
      box-shadow: rgba(0 0 0 / 10%) 0 1px 3px 0, rgb(0 0 0 / 6%) 0 1px 2px 0;
    }
  }
`;

const FeedbackInput = function () {
  const FormItem = Form.Item;
  const { t } = useTranslation();
  return (
    <FormItem name="feedbackInput" style={{ margin: 0, textAlign: 'center' }}>
      <StyledInput
        placeholder={t('chat:::feedbackFormInputPlaceholder')}
        autoSize={{ minRows: 1, maxRows: 4 }}
        maxLength={256}
      />
    </FormItem>
  );
};

export default FeedbackInput;
