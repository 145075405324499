import {
  shape,
  number,
  string,
  bool,
  object,
  func,
  array,
  oneOf,
  oneOfType,
  arrayOf,
  instanceOf,
  objectOf,
} from 'prop-types';
import moment from 'moment';

export const userType = shape({
  uid: string,
  name: string,
  photoURL: string,
  email: string,
});

export const partnerType = shape({
  uid: string,
  name: oneOfType([
    object,
    string,
  ]),
  photoURL: string,
  companyURL: string,
  id: string,
  logo: string,
  displayName: string,
  agentFeaturesEnabled: bool,
  settings: shape({}),
});

export const chatType = shape({
  uid: string,
  id: string,
  question: string,
  status: string,
  rating: oneOfType([number, string]),
  language: string,
  ratedAt: number,
  createdAt: number,
  lastMessage: shape({}),
  hotTransfers: arrayOf(shape({})),
  user: userType,
  topic: string,
  partner: partnerType,
  lastSeenByExpertAt: number,
});

export const chatMetaType = shape({
  meta: chatType.isRequired,
});

export const expertType = shape({
  uid: string,
  name: string,
  photoURL: string,
  disabled: oneOfType([bool, number]),
  email: string,
  createdAt: number,
  buckets: shape({}),
});

export const notificationType = shape({
  sentBy: string,
  sentAt: number,
  message: string,
});

export const noteType = shape({
  createdBy: string,
  createdAt: number,
  message: string,
});

export const categoryType = shape({
  id: string,
  priority: number,
});

export const paginationType = shape({
  hasMore: bool,
  isLoading: bool.isRequired,
});

export const matchType = shape({
  params: shape({
    partnerId: string,
    chatId: string,
    pageId: string,
  }).isRequired,
});

export const locationType = shape({
  pathname: string.isRequired,
  search: string.isRequired,
  hash: string.isRequired,
  state: string,
});

export const systemType = shape({
  params: object,
  locale: string,
  isBooting: bool,
});

export const sessionType = shape({
  isSigningIn: false,
  partner: shape({
    id: string,
  }),
});

export const historyType = shape({
  length: number.isRequired,
  action: string.isRequired,
  location: locationType.isRequired,
});

export const authorType = shape({
  isUser: bool.isRequired,
  coverImage: string,
  name: string,
});

export const messageType = shape({
  message: string,
  i18nData: object,
  author: oneOfType([
    authorType,
    string,
  ]),
  timestamp: number,
  type: string,
  data: object,
});

export const expertiseType = shape({
});

const billingResponseTimesType = shape({
  average: shape({
    P95: 0,
  }),
});

const billingPerRatingType = shape({
  yes: number,
  partial: number,
  no: number,
});

const billingTotalType = shape({
  totalAnswered: number,
  perRating: billingPerRatingType,
  responseTimes: billingResponseTimesType,
});

export const billingType = shape({
  total: billingTotalType,
});

export const expertReportType = shape({
  totalExperts: number,
});

export const formType = shape({
  getFieldDecorator: func,
  validateFields: func,
  isFieldsTouched: func,
});

export const graphqlDataType = shape({
  loading: bool,
  variables: shape({}),
});

export const graphqlClientType = shape({
  query: func,
});

export const cubeClientType = shape({
  apiToken: oneOfType([string, func]).isRequired,
  apiUrl: string.isRequired,
  load: func.isRequired,
  request: func.isRequired,
  sql: func.isRequired,
});

export const periodType = oneOfType([
  arrayOf(instanceOf(moment).isRequired),
  arrayOf(string.isRequired),
]);

export const cubeQueryType = shape({
  measures: array,
  timeDimensions: array,
  filters: array,
  dimensions: array,
  segments: array,
});

export const periodStringType = oneOf([
  'Today',
  'Yesterday',
  'This week',
  'This quarter',
  'This month',
  'This year',
  'Last 7 days',
  'Last month',
  'Last 30 days',
  'Last quarter',
  'Last year',
]);

export const configurableCubeFilterType = shape({
  dimension: string.isRequired,
  operator: string.isRequired,
  values: array.isRequired,
  possibleValues: arrayOf(
    shape({
      name: string.isRequired,
      value: string,
    }),
  ),
});

export const configurableCubeOptionType = shape({
  name: string.isRequired,
  value: arrayOf(string),
  colors: objectOf(string),
  showColorOnly: arrayOf(string),
  order: arrayOf(string.isRequired),
});

const alertType = shape({
  id: string,
  expiresAt: number,
  title: objectOf(string),
  message: objectOf(string),
});

export const customChartType = shape({
  id: string.isRequired,
  title: string.isRequired,
  description: string,
  granularity: string.isRequired,
  fields: arrayOf(string.isRequired).isRequired,
});
