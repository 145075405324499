const AutoMessagesActions = {
  hasNewMessage: 'hasNewMessage',
  chatOpen: 'chatOpen',
  attentionGrabber: 'attentionGrabber',
  contentEmbed: 'contentEmbed',
  communityLoader: 'communityLoader',
  communityEmbedExtended: 'communityEmbedExtended',
  communityEmbed: 'communityEmbed',
  quickQuestions: 'quickQuestions',
  invitationGrabber: 'invitationGrabber',
};

module.exports = AutoMessagesActions;
