import { useState } from 'react';
import { useSubscription } from '@apollo/client';
import TIMEOUT_STATUS from '../subscriptions/timeoutStatus';

export default (messageId, chatId, skip) => {
  const [waitTimeoutUpdate, setWaitTimeoutUpdate] = useState();

  useSubscription(TIMEOUT_STATUS, {
    variables: { id: chatId },
    skip: !messageId || skip,
    onData: ({
      data: {
        data: {
          waitTimeoutMessage: {
            metadata: {
              waitTimeoutStatus,
            },
            text,
          },
        },
      },
    }) => {
      setWaitTimeoutUpdate({ text, waitTimeoutStatus });
    },
  });

  return waitTimeoutUpdate;
};
