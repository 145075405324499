import React from 'react';
import PropTypes from 'prop-types';
import { Form, Space } from 'antd';
import { useMutation } from '@apollo/client';
import { setFeedbackSubmitted } from '@guuru/chat-web';
import styled from 'styled-components';
import { errorCode, errorType } from '@guuru/graphql-common';
import FeedbackInput from './FeedbackInput';
import FeedbackNext from './FeedbackNext';
import FeedbackTagsList from './FeedbackTagsList';
import CREATE_CHAT_FEEDBACK from './mutations/createChatFeedback';

const FormElements = styled(Space)`
  ${({ theme }) => !theme.chatLayout && `
    max-width: 400px;
  `};
`;

const submitFeedback = async (chatId, {
  mutationFn,
  feedbackInput,
  feedbackTags,
}) => {
  try {
    await mutationFn({
      variables: {
        input: {
          chatId,
          feedbackTopics: feedbackTags,
          comment: feedbackInput,
        },
      },
    });
    setFeedbackSubmitted(true);
  } catch (err) {
    if (errorCode(err) === errorType.FORBIDDEN) {
      window.location.reload();
      return;
    }
    if (errorCode(err) === errorType.BAD_USER_INPUT
      && err.graphQLErrors?.[0]?.extensions?.hasAlreadyFeedback
    ) {
      setFeedbackSubmitted(true);
      return;
    }
    throw err;
  }
};

const FeedbackForm = function ({ chatId, topics }) {
  const [form] = Form.useForm();
  const [createChatFeedback] = useMutation(CREATE_CHAT_FEEDBACK);

  return (
    <Form
      name="feedbackForm"
      layout="vertical"
      form={form}
      onFinish={({ feedbackInput, feedbackTags }) => (
        submitFeedback(chatId, {
          feedbackInput,
          feedbackTags,
          mutationFn: createChatFeedback,
        })
      )}
    >
      <FormElements direction="vertical">
        <FeedbackTagsList topics={topics} />
        <FeedbackInput />
        <FeedbackNext />
      </FormElements>
    </Form>
  );
};

FeedbackForm.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.objectOf(
      PropTypes.string,
    ).isRequired,
  })).isRequired,
  chatId: PropTypes.string.isRequired,
};

export default FeedbackForm;
