import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { Button, NpsNumber } from './style';

const NpsButton = function ({ number, color, onSubmit }) {
  return (
    <Col>
      <Button
        onClick={() => onSubmit(number)}
        $color={color}
        size="large"
        type="primary"
      >
        <NpsNumber>{number}</NpsNumber>
      </Button>
    </Col>
  );
};

NpsButton.propTypes = {
  number: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NpsButton;
