import { useContext, useEffect } from 'react';
import HistoryContext from '../context/historyContext';

const useSessionHistory = function (pointerName) {
  const context = useContext(HistoryContext);
  const { setHistoryPointer } = context;

  useEffect(() => {
    if (pointerName) {
      setHistoryPointer(pointerName);
    }
  }, [pointerName, setHistoryPointer]);

  return context;
};

export default useSessionHistory;
