import { Sentry, generateRandomId } from '@guuru/sentry-web';

const sessionId = generateRandomId();

const tracingHeaders = () => {
  const requestId = generateRandomId();
  Sentry.getCurrentScope()
    .setTag('transaction_id', sessionId)
    .setTag('request_id', requestId);

  return {
    'X-Request-ID': requestId,
    'X-Session-ID': sessionId,
  };
};

export default tracingHeaders;
