import { useEffect } from 'react';
import {
  useApolloClient,
  useLazyQuery,
  useReactiveVar,
  useSubscription,
} from '@apollo/client';
import { errorCode } from '@guuru/graphql-common';

const makeErrorHandler = (onError) => (err) => {
  if (onError) {
    onError(err);
    return;
  }
  if (!['FORBIDDEN', 'NOT_FOUND'].includes(errorCode(err))) {
    throw err;
  }
};

const useLiveQuery = function (queryDocument, subscriptionDocument, {
  onError,
  onData,
  skip = false,
  variables = {},
  queryOptions = {},
  subscriptionOptions = {},
} = {}) {
  const client = useApolloClient();
  const connectedAt = useReactiveVar(client.connectedAt);
  const { skip: skipSubscription } = subscriptionOptions;

  const [execute, query] = useLazyQuery(queryDocument, {
    ...queryOptions,
    variables: {
      ...variables,
      ...(queryOptions.variables ?? {}),
    },
  });

  useSubscription(subscriptionDocument, {
    ...subscriptionOptions,
    skip: subscriptionOptions.skip || skip,
    variables: {
      ...variables,
      ...(subscriptionOptions.variables ?? {}),
    },
  });

  const {
    loading,
    data,
    called,
    refetch,
  } = query;

  useEffect(() => {
    if (!data || !onData) return;
    onData(data);
  }, [data, onData]);

  useEffect(() => {
    if (!skip && (connectedAt || skipSubscription)) {
      const fetchFn = called ? refetch : execute;
      fetchFn().catch(makeErrorHandler(onError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, connectedAt, skipSubscription]);

  return { ...query, loading: !called || loading };
};

export default useLiveQuery;
