const ExpertType = {
  Bot: {
    description: 'Interactions where the last assigned expert is the bot, which is whenever the bot is involved and the interaction does not go to a Guuru or Agent afterwards',
  },
  Guuru: {
    description: 'Interactions where last assigned expert is a Guuru',
  },
  Agent: {
    description: 'Interactions where last assigned expert is an agent',
  },
  Unassigned: {
    description: 'Interactions that have not been accepted by any expert, for example, missed or handover interactions',
  },
};

module.exports = ExpertType;
