import { gql } from '@apollo/client';

export default gql`
  mutation CreateChat(
    $input: ChatInput!,
    $isVisibleByExpert: Boolean = false,
    $isVisibleByUser: Boolean = true,
  ) {
    createChat(input: $input) {
      ...ChatPublicFields
      token
      messages(
        isVisibleByExpert: $isVisibleByExpert
        isVisibleByUser: $isVisibleByUser
        orderBy: {
          direction: asc
          field: createdAt
        }
      )
      {
        edges {
          node {
            ...MessageFields
          }
        }
      }
    }
  }
`;
