import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setTranslations } from '@guuru/translation-web';
import { useChatSettings } from '@guuru/chat-web';

const useSetLanguage = function (partnerId) {
  const { i18n } = useTranslation();
  const {
    data: {
      chatSettings: {
        translations,
        language,
      } = {},
    } = {},
  } = useChatSettings(partnerId);
  useEffect(() => {
    if (!partnerId || !translations) {
      return;
    }
    i18n.changeLanguage(language);
    setTranslations(language, translations);
  }, [partnerId, i18n, translations, language]);
};

export default useSetLanguage;
