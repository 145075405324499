import React from 'react';
import PropTypes from 'prop-types';
import { convertToHtml } from '@guuru/markdown-common';
import ChatMessageBubble from '../ChatMessageBubble';
import { AnswerText } from './style';
import TextMessage from '../TextMessage';

const HTMLMessage = function ({ message }) {
  return (
    <ChatMessageBubble message={message}>
      <TextMessage>
        <AnswerText
          dangerouslySetInnerHTML={{ __html: convertToHtml(message.i18n) }}
        />
      </TextMessage>
    </ChatMessageBubble>
  );
};

HTMLMessage.propTypes = {
  message: PropTypes.shape({
    i18n: PropTypes.string,
  }).isRequired,
};

export default HTMLMessage;
