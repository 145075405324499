import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@guuru/react-expert-avatar';

const Cover = function ({
  src = null,
  size = undefined,
  text = undefined,
  style = {},
}) {
  return (
    <Avatar
      size={size}
      src={src}
      text={text}
      shape="square"
      style={{
        objectFit: 'cover',
        aspectRatio: '1 / 1',
        height: '100%',
        width: '100%',
        border: 'inherit',
        borderRadius: '6px 6px 0 0',
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        fontSize: 'clamp(42px, calc(2rem + 2vw + 2vh), 64px)',
        ...style,
      }}
    />
  );
};

Cover.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.shape({}),
};

export default Cover;
