import { Sentry } from '@guuru/sentry-web';
import PropTypes from 'prop-types';
import ErrorPage from './500';

class ErrorBoundary extends Sentry.ErrorBoundary {
  componentDidCatch(error, { componentStack }) {
    const { ignoreErrors } = this.props;
    const shouldIgnore = error.graphQLErrors?.every((err) => (
      ignoreErrors.includes(err?.extensions?.code)
    ));
    if (shouldIgnore) return;
    super.componentDidCatch(error, { componentStack });
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.elementType,
  ignoreErrors: PropTypes.arrayOf(PropTypes.string),
};

ErrorBoundary.defaultProps = {
  fallback: ErrorPage,
  ignoreErrors: [],
};

export default ErrorBoundary;
