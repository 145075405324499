import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { LocaleHelper } from '@guuru/helpers-common';
import { useLocation } from 'react-router-dom';
import ChatRenderText from '../Text';

const ChatRenderPartners = function ({ message }) {
  const { search } = useLocation();

  const { partners } = message.metadata;

  if (!partners.length) {
    return (
      <ChatRenderText
        message={{ i18n: 'Partner not found' }}
        tooltip={false}
      />
    );
  }

  const getChatUrl = (partnerId) => (
    `/${partnerId}${search}`
  );

  return (
    <ChatRenderText message={message} tooltip={false}>
      <div className="message-partners">
        <ul className="message-partners-list">
          {partners.map((partner) => (
            <li key={partner.id} className="message-partners-list-item">
              <a
                className="message-partners-list-item-anchor"
                href={getChatUrl(partner.id)}
              >
                <img
                  className="message-partners-list-item-logo"
                  src={partner?.settings?.appearance?.chatWidgetPhotoUrl}
                  alt="partner logo"
                />
                <span className="message-partners-list-item-name">
                  {LocaleHelper.localize(partner?.name)}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </ChatRenderText>
  );
};

ChatRenderPartners.propTypes = {
  message: PropTypes.shape({
    metadata: PropTypes.shape({
      partners: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default withTranslation()(ChatRenderPartners);
