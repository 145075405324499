import { locales } from '@guuru/constants-common';
import isString from 'lodash/isString';
import localStorageHelper from './localStorageHelper';

const CONFIG_SYSTEM_LOCALE = 'SYSTEM_LOCALE';

const HELPER = {

  isValidLanguage: (language, configuredLanguages = locales) => {
    let validLanguages = configuredLanguages;
    if (validLanguages === null) {
      validLanguages = locales;
    }
    return validLanguages.includes(language);
  },

  getValidSystemLocale: ({
    defaultLanguage,
    configuredLanguages,
    locale = '',
  }) => {
    const language = locale.toLowerCase();
    if (HELPER.isValidLanguage(language, configuredLanguages)) {
      return language;
    }
    if (HELPER.isValidLanguage(defaultLanguage, configuredLanguages)) {
      return defaultLanguage;
    }
    if (configuredLanguages?.length) {
      return configuredLanguages[0];
    }
    return 'en';
  },

  storeSystemLocale: (locale) => {
    localStorageHelper.setConfig(CONFIG_SYSTEM_LOCALE, locale);
  },

  getSystemLocale: () => localStorageHelper.getConfig(CONFIG_SYSTEM_LOCALE),

  localize: (m, locale, defaultLocale = 'en') => {
    if (isString(m)) {
      return m;
    }
    if (!Object.keys(m || {}).length) {
      return '';
    }
    const someLocale = Object.keys(m).find((l) => m[l]);
    return m[locale] || m[defaultLocale] || m.de || m.fr
      || m.it || m.nl || m[someLocale];
  },

};

export default HELPER;
