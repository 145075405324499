import { gql } from '@apollo/client';

export default gql`
  query GetPersistedPartnerPriority($id: ID!) {
    partner(id: $id) {
      id
      state {
        priority
      }
    }
  }
`;
