import styled from 'styled-components';

export default styled.div`
  text-align: center;
  margin: 8px 0 8px 8px;
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'not-allowed')};
  color: ${({ $isActive, theme }) => ($isActive ? theme.primaryColor : '#cacaca')};

  &:hover {
    color: ${({ $isActive, theme }) => $isActive && theme.primaryVariants[4]};
  }
`;
