const {
  EXTCDN_URL = 'https://extcdn.guuru.com/cdn-cgi/image',
} = process.env;

const [ourName, ourTLD] = (new URL(EXTCDN_URL)).hostname.split('.').slice(-2);

const isExternalURL = function (address) {
  try {
    const url = new URL(address);
    if (!url?.hostname) return false;
    const [name, tld] = url.hostname.split('.').slice(-2);
    return name !== ourName || tld !== ourTLD;
  } catch {
    return false;
  }
};

const proxyImageURL = function (address, {
  width,
  shouldProxyFn = isExternalURL,
} = {}) {
  if (!shouldProxyFn(address)) {
    return address;
  }
  const options = [
    'format=auto',
    width ? `width=${width * 2}` : '',
  ].filter(Boolean).join(',');
  return `${EXTCDN_URL}/${options}/${address}`;
};

// eslint-disable-next-line import/prefer-default-export
export { proxyImageURL };
