import React from 'react';
import PropTypes from 'prop-types';
import { PaperClipOutlined } from '@ant-design/icons';
import Label from './Label';

const ChatAttachButton = function ({ onAttachmentSubmit }) {
  return (
    <Label htmlFor="chat-message-bar-attachment-input">
      <PaperClipOutlined
        type="paper-clip"
        style={{
          fontSize: '20px',
          color: '#737376',
          verticalAlign: 'middle',
        }}
      />
      <input
        type="file"
        id="chat-message-bar-attachment-input"
        onChange={onAttachmentSubmit}
      />
    </Label>
  );
};

ChatAttachButton.propTypes = {
  onAttachmentSubmit: PropTypes.func.isRequired,
};

export default ChatAttachButton;
