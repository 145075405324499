import styled from 'styled-components';
import { Row } from 'antd';

const StyledHeader = styled(Row)`
  @keyframes open {
    from {
      transform: translateY(-99%);
      opacity: 0;
    }
  }

  z-index: 9;
  box-shadow: 0 1px 3px -3px #2f2f2f;
  animation: open 0.7s ease-in-out;
  background-size: cover;
  background-position: center;
  background-color: ${({ $backgroundImage, theme }) => ($backgroundImage && theme.primaryColor)};
  background-image:
    ${({ $backgroundImage, theme: { primaryColor, primaryVariants } }) => (
    $backgroundImage
      ? `linear-gradient(to right, ${primaryColor} 50%, ${primaryColor}88), url(${$backgroundImage})`
      : `linear-gradient(to right, ${primaryColor} 50%, ${primaryVariants[4]})`
  )};
  min-height: 170px;
  padding: 1.25rem 1.5rem;
  color: ${({ theme }) => theme.onSecondaryColor};

  .ant-typography {
    line-height: 1.2em;
    font-weight: normal;
    margin: 0;
    color: ${({ theme }) => theme.onSecondaryColor};
  }

  .ant-typography-secondary {
    font-size: 0.8rem;
    opacity: 0.75;
  }
`;

export default StyledHeader;
