import { firebase } from '@guuru/firebase-web';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signInAnonymously,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';

const auth = getAuth(firebase);

export default {
  auth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signInAnonymously,
  signOut,
  onAuthStateChanged,
};
