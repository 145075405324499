import React from 'react';
import { STEP_QUESTION } from '@guuru/chat-web/src/constants/flow';
import { useLocalChat } from '@guuru/chat-web';
import { parse } from '@guuru/url-common';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ChatAPI } from '@guuru/api-web';
import QuestionButton from './QuestionButton';
import QuestionsList from './QuestionsList';
import GET_QUICK_QUESTIONS from './queries/getQuickQuestions';

const stepsWhereShouldAppear = [
  STEP_QUESTION,
];

const ChatQuickQuestions = function () {
  const { step } = useLocalChat();
  const { partnerId } = useParams();
  const { chatAutoMessageId } = parse(window.location.search);
  const locale = ChatAPI.storeRetrieveLocaleParameter();
  const {
    data: {
      partner: {
        quickQuestions: {
          questions = [],
        } = {},
      } = {},
    } = {},
    loading,
  } = useQuery(GET_QUICK_QUESTIONS, {
    variables: {
      locale,
      id: partnerId,
      autoMessageId: chatAutoMessageId,
    },
    skip: !chatAutoMessageId || !stepsWhereShouldAppear.includes(step),
  });

  if (
    loading
    || questions?.length < 1
    || !stepsWhereShouldAppear.includes(step)
  ) return null;

  return (
    <QuestionsList>
      {questions?.map((question) => (
        <QuestionButton key={question} question={question} />
      ))}
    </QuestionsList>
  );
};

export default ChatQuickQuestions;
