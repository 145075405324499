import { ChatAPI } from '@guuru/api-web';
import { isGreeting } from '@guuru/helpers-common';
import handleChatGreeting from '../../chatActions/handleChatGreeting';

export default {
  validate() {
    return true;
  },

  render(partnerId) {
    const question = ChatAPI.storeRetrieveQuestion();
    const channel = ChatAPI.storeRetrieveChannel(partnerId);
    if (channel === 'form') {
      return { goToNextStep: true };
    }
    if (isGreeting(question)) {
      return handleChatGreeting(question);
    }

    return { goToNextStep: true };
  },

};
