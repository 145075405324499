import styled from 'styled-components';
import { FileTextFilled } from '@ant-design/icons';

const FileIcon = styled(FileTextFilled)`
  font-size: 1.75rem;
  vertical-align: middle;
  padding: 0 0.2rem;
`;

export default FileIcon;
