import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

const FeedbackComment = styled(Title)`
  margin: 0 30px;

  &.ant-typography {
    color: rgba(0 0 0 / 45%);
    font-weight: 300;
  }
`;

export default FeedbackComment;
