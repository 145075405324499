import styled from 'styled-components';

export default styled.span`
  display: block;
  margin-top: 8px;

  a {
    color: ${({ theme }) => theme.primaryVariants?.[6]};

    &:hover {
      color: ${({ theme }) => theme.primaryVariants?.[4]};
    }
  }
`;
