import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import Compressor from 'compressorjs';
import UPLOAD_PROFILE_PICTURE from './mutations/uploadProfilePicture';
import uploadGCSSignedUrl from '../uploadGCSSignedUrl';

const useProfilePictureSubmit = function ({
  id,
  onProgress = () => {},
  onUpload = () => {},
  onError = () => {},
  onFinish = () => {},
}) {
  const [uploadProfilePicture] = useMutation(UPLOAD_PROFILE_PICTURE);

  const handleSubmit = useCallback(async (file) => {
    if (!file) return null;

    if (!/image\/*/.test(file.type)) {
      onError('Oops, you can only upload images.');
      return null;
    }

    onProgress('Uploading a new avatar');

    try {
      const blob = await new Promise((resolve, reject) => (
        new Compressor(file, {
          quality: 0.7,
          mimeType: 'image/jpeg',
          maxWidth: 512,
          maxHeight: 512,
          success: resolve,
          error: reject,
        })
      ));

      const {
        data: {
          uploadProfilePicture: {
            writeUrl,
            readUrl,
            maxBytes,
            acl,
          },
        },
      } = await uploadProfilePicture({ variables: { id } });

      if (blob.size > maxBytes) {
        throw new Error('The avatar you are trying to upload is too big');
      }

      await uploadGCSSignedUrl({
        contentType: 'image/jpeg',
        writeUrl,
        blob,
        maxBytes,
        acl,
      });

      await onUpload(readUrl);
      onFinish();
      return readUrl;
    } catch (err) {
      onError(err.message);
      return null;
    }
  }, [
    id,
    uploadProfilePicture,
    onError,
    onUpload,
    onFinish,
    onProgress,
  ]);

  return handleSubmit;
};

export default useProfilePictureSubmit;
