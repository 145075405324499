import { gql } from '@apollo/client';

export default gql`
  mutation DeleteChatMessage($id: ID!) {
    deleteChatMessage(id: $id) {
      id
      ... on TextMessage {
        text
      }
    }
  }
`;
