import { possibleRoles } from '@guuru/abilities-common';
import {
  MonitorOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

const AbilitiesActions = [
  {
    name: 'Read',
    value: 'read',
    icon: MonitorOutlined,
    color: '#52c41a',
  },
  {
    name: 'Create',
    value: 'create',
    icon: UploadOutlined,
    color: '#f0dd0e',
  },
  {
    name: 'Update',
    value: 'update',
    icon: EditOutlined,
    color: '#fd8a34',
  },
  {
    name: 'Delete',
    value: 'delete',
    icon: DeleteOutlined,
    color: '#f52525',
  },
];

const AbilityFilters = {

  action: {
    key: 'action',
    label: 'Action',
    filters: AbilitiesActions,
  },

  roles: {
    key: 'roles',
    label: 'Role',
    filters: Object.entries(possibleRoles).map(([key, name]) => (
      { name, value: key }
    )),
  },

  searchText: {
    key: 'searchText',
  },
};
export { AbilityFilters, AbilitiesActions };
