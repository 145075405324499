import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useChatSettings } from '@guuru/chat-web';
import { Col, Typography } from 'antd';
import Header from './Header';
import GoBackLink from './GoBackLink';

const { Title } = Typography;

const CommunityHeader = function () {
  const {
    data: {
      chatSettings: {
        id: partnerId,
        name,
      },
    },
  } = useChatSettings();

  const { search } = useLocation();

  return (
    <Header>
      <Col flex="1">
        <GoBackLink />
      </Col>
      <Col align="center">
        <Title level={5}>
          <Link to={`/${partnerId}/home${search}`} replace>{name}</Link>
        </Title>
      </Col>
      <Col flex="1" />
    </Header>
  );
};

export default CommunityHeader;
