import { Bot, BotSettings, FreshchatSettings } from '@guuru/constants-common';

const HELPER = {
  isExpertSomeBot: (expertId) => [
    BotSettings.BOT_UID,
    Bot.BOT_UID,
    FreshchatSettings.FRESHCHAT_UID,
  ].includes(expertId),
};

export default HELPER;
