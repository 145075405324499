import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Input,
  Row,
  Form,
} from 'antd';
import { expandEvent } from '@guuru/chat-events-web';
import { ThemeContext } from 'styled-components';
import SmartForm from './style';

const { TextArea } = Input;

const QuestionForm = function ({
  form,
  onSubmit,
  question = undefined,
  showSubmit,
  isMobile,
}) {
  const { t } = useTranslation();
  const { chatAlignment, setFullScreen } = useContext(ThemeContext);

  const expandSmartForm = (questionAsked) => {
    if (!questionAsked || !isMobile) return null;
    setFullScreen(true);
    return expandEvent();
  };

  return (
    <SmartForm
      name="basic"
      layout="vertical"
      onFinish={
        (values) => {
          onSubmit(values.question);
          expandSmartForm(values.question);
        }
      }
      form={form}
    >
      <Form.Item
        name="question"
        rules={[
          { required: true, message: t('chat:::fillQuestionSmartForm') },
          { min: 15, message: t('chat:::fillQuestionSmartForm') },
        ]}
        initialValue={question}
        validateTrigger="onSubmit"
        style={question ? { marginBottom: 0, width: '100%' } : { width: '100%' }}
      >
        <TextArea
          autoSize={{ minRows: 1, maxRows: 3 }}
          placeholder={t('chat:::fillQuestionSmartForm')}
          disabled={question}
          style={{
            resize: (question && 'none'),
            animation: 'none',
            transition: 'none',
            width: '100%',
          }}
        />
      </Form.Item>
      {showSubmit && (
        <Row justify={chatAlignment} hidden={question}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
          >
            {t('chat:::sendMessageSmartForm')}
          </Button>
        </Row>
      )}
    </SmartForm>
  );
};

QuestionForm.propTypes = {
  form: PropTypes.shape({
    submit: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  showSubmit: PropTypes.bool.isRequired,
  question: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};

export default QuestionForm;
