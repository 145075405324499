import { useCallback, useMemo, useState } from 'react';
import { useLiveQuery } from '@guuru/react-hooks';
import { newMessageNotificationEvent } from '@guuru/chat-events-web';
import usePageCounter from './usePageCounter';
import GET_CHAT_MESSAGES from '../queries/getChatMessages';
import NEW_CHAT_MESSAGE from '../subscriptions/newChatMessage';

const addMessageToCache = (previous, messages) => ({
  ...previous,
  chat: {
    ...previous.chat,
    messages: {
      ...previous.chat?.messages,
      edges: messages,
    },
  },
});

export default ({ chatId, isUser, reduxMessages }) => {
  const { updatePageCount, resetPageCount } = usePageCounter();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const hasUnreadMessages = unreadMessagesCount > 0;

  const {
    data: {
      chat: {
        status,
        partner = {},
        expert,
        messages: {
          edges: gqlMessages = [],
        } = {},
        acceptedAt,
        onboarding,
        rating,
      } = {},
    } = {},
    updateQuery,
  } = useLiveQuery(GET_CHAT_MESSAGES, NEW_CHAT_MESSAGE, {
    skip: !chatId,
    variables: {
      id: chatId,
    },
    queryOptions: {
      variables: {
        isVisibleByExpert: !isUser,
        isVisibleByUser: isUser,
      },
    },
    subscriptionOptions: {
      variables: {
        forExpert: !isUser,
        forUser: isUser,
      },
      onData: ({ data } = {}) => {
        if (data) {
          const { newChatMessage } = data.data;
          if (newChatMessage) {
            updateQuery((previous) => {
              const messages = previous.chat?.messages
                ? [...previous.chat.messages.edges]
                : [];
              messages.push({
                __typename: 'MessageEdge',
                node: newChatMessage,
              });

              return addMessageToCache(previous, messages);
            });

            // If the incoming message was sent by the current chat participant
            // it does not count as an unread message.
            //
            // eslint-disable-next-line no-underscore-dangle
            const isFromMe = newChatMessage.author.__typename === 'UserAuthor'
              ? isUser
              : !isUser;

            // eslint-disable-next-line no-use-before-define
            if (isFromMe || !isUnreadMessagesEnabled) return;

            setUnreadMessagesCount(unreadMessagesCount + 1);
            updatePageCount(unreadMessagesCount + 1);

            if (isUser) {
              newMessageNotificationEvent(unreadMessagesCount + 1);
            }
          }
        }
      },
    },
  });

  const isUnreadMessagesEnabled = useMemo(() => {
    if (!isUser) return true;
    return partner?.settings?.appearance?.isUnreadMessagesEnabled ?? false;
  }, [partner, isUser]);

  const markMessagesRead = useCallback(() => {
    if (hasUnreadMessages) {
      setUnreadMessagesCount(0);
      if (isUnreadMessagesEnabled) {
        resetPageCount();
      }
    }
  }, [
    hasUnreadMessages,
    isUnreadMessagesEnabled,
    resetPageCount,
  ]);

  const messages = useMemo(() => (
    chatId && (gqlMessages.length >= reduxMessages.length)
      ? gqlMessages
      : reduxMessages
  ), [chatId, gqlMessages, reduxMessages]);

  return {
    status,
    partner,
    expert,
    messages,
    acceptedAt,
    onboarding,
    rating,
    unreadMessagesCount,
    incrementUnreadMessages: (
      () => { setUnreadMessagesCount(unreadMessagesCount + 1); }
    ),
    markMessagesRead,
  };
};
