/**
* Google Analytics Constants
* All analytics page views and event names
*/

export const CHAT_FLOW = 'Chat Flow';
export const CHAT_MESSAGE = 'Chat Message';
export const BOT_MESSAGE = 'Bot Message';
export const NAVIGATION = 'Navigation';
export const MESSAGE_BAR = 'Message Bar';
export const RATE = 'Rate';
export const SUBMIT_INPUT = 'Submit Input';
export const SUBMIT_ATTACHMENT = 'Submit Attachment';
export const NPS = 'Selected NPS';
export const NPS_PROMPTED = 'NPS Prompted';
export const FEEDBACK_REQUEST_PROMPTED = 'Feedback Request Prompted';
export const COMMUNITY_INVITATION_PAGE = 'Community Invitation Page';
export const BOTTOM_SHEET = 'Bottom Sheet';
export const COMMUNITY_SPACE = 'Community Space';
