import { relayStylePagination } from '@apollo/client/utilities';

export default {
  Chat: {
    fields: {
      mentions: relayStylePagination(['@connection', ['key']]),
      messages: relayStylePagination([
        'orderBy', 'isVisibleByExpert', 'isVisibleByUser', '@connection', ['key'],
      ]),
      relatedChats: relayStylePagination(['@connection', ['key']]),
      requestedExperts: relayStylePagination(['@connection', ['key']]),
    },
  },
  Community: {
    fields: {
      experts: relayStylePagination(['@connection', ['key']]),
      mentions: relayStylePagination(['urls', '@connection', ['key']]),
    },
  },
  Expert: {
    fields: {
      allStatements: relayStylePagination(['partner', '@connection', ['key']]),
      chats: relayStylePagination([
        'period', 'status', 'filters', 'startDate', 'endDate', 'partnerId', 'billable', '@connection', ['key'],
      ]),
      news: relayStylePagination(['@connection', ['key']]),
      partners: relayStylePagination([
        'partnerId', 'hasPayments', 'period', '@connection', ['key'],
      ]),
      submissions: relayStylePagination([
        'filters', 'orderBy', '@connection', ['key'],
      ]),
    },
  },
  Partner: {
    fields: {
      balances: relayStylePagination(['@connection', ['key']]),
      cannedResponses: relayStylePagination(['prefix', '@connection', ['key']]),
      chats: relayStylePagination([
        'filters', 'startDate', 'endDate', 'orderBy', '@connection', ['key'],
      ]),
      discounts: relayStylePagination(['@connection', ['key']]),
      experts: relayStylePagination([
        'orderBy', 'filters', 'startDate', 'endDate', '@connection', ['key'],
      ]),
      members: relayStylePagination(['roles', '@connection', ['key']]),
      mentions: relayStylePagination(['filters', '@connection', ['key']]),
      news: relayStylePagination(['@connection', ['key']]),
      questions: relayStylePagination([
        'orderBy', 'filters', 'startDate', 'endDate', '@connection', ['key'],
      ]),
      submissions: relayStylePagination([
        'filters', 'orderBy', '@connection', ['key'],
      ]),
      subscriptions: relayStylePagination(['status', '@connection', ['key']]),
      surcharges: relayStylePagination(['@connection', ['key']]),
      translations: relayStylePagination(['filters', '@connection', ['key']]),
    },
  },
  PartnerAutomation: {
    fields: {
      intents: relayStylePagination([
        'isEnabled', 'filters', 'intentStatus', 'startDate', 'endDate', '@connection', ['key'],
      ]),
    },
  },
  Payouts: {
    fields: {
      experts: relayStylePagination(['@connection', ['key']]),
    },
  },
  Query: {
    fields: {
      abilities: relayStylePagination(['filters', '@connection', ['key']]),
      categories: relayStylePagination(['@connection', ['key']]),
      chats: relayStylePagination([
        'filters', 'startDate', 'endDate', 'orderBy', '@connection', ['key'],
      ]),
      experts: relayStylePagination([
        'filters', 'orderBy', '@connection', ['key'],
      ]),
      globalMembers: relayStylePagination(['roles', '@connection', ['key']]),
      globalNews: relayStylePagination(['@connection', ['key']]),
      globalTranslations: relayStylePagination(['filters', '@connection', ['key']]),
      intents: relayStylePagination([
        'isEnabled', 'filters', 'status', 'startDate', 'endDate', 'orderBy', '@connection', ['key'],
      ]),
      partners: relayStylePagination([
        'filters', 'orderBy', '@connection', ['key'],
      ]),
      questions: relayStylePagination([
        'orderBy', 'filters', 'startDate', 'endDate', '@connection', ['key'],
      ]),
    },
  },
};
