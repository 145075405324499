const ChatState = {
  created: 'created',
  assigned: 'assigned',
  open: 'open',
  closed: 'closed',
  rated: 'rated',
  handover: 'handover',
  missed: 'missed',
};

module.exports = ChatState;
