import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';

export default {
  validate() {
    return true;
  },
  * render(partnerId) {
    yield put({ type: types.CHAT_LOADED });
    ChatAPI.initChat(partnerId, []);

    return { goToNextStep: true };
  },
};
