import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { List } from 'antd';
import Option from '../Option';
import OptionIcon from './OptionIcon';
import OptionAvatar from './OptionAvatar';
import OptionListItem from './OptionListItem';
import TextMessage from '../TextMessage';

const OptionsList = function ({
  message,
  options,
  onSelection,
  showOptionChildren = true,
  languageCode = 'en',
  showOptions = true,
  orderKey = undefined,
  readOnly = false,
}) {
  const orderedOptions = useMemo(
    () => (orderKey ? orderBy(options, orderKey) : options),
    [options, orderKey],
  );
  return (
    <>
      <TextMessage>{message}</TextMessage>
      {showOptions
      && (
      <div className="message-options">
        <ul className="message-options-list">
          {orderedOptions.map((option) => (
            <div key={option.id}>
              {option?.titleExtra && (
                <div style={{ marginTop: '15px' }}>
                  <TextMessage>
                    {option.titleExtra}
                  </TextMessage>
                </div>
              )}
              <Option
                key={option.id}
                id={option.action ?? option.id}
                name={option.name || option.title || option.description}
                onClick={() => (!readOnly ? onSelection(option) : undefined)}
                locale={languageCode}
                $disabled={readOnly}
                $highlighted={option?.id === 'expertFallback'}
              >
                {showOptionChildren ? (
                  <OptionListItem description={option.description}>
                    <List.Item.Meta
                      avatar={option.icon ? (
                        <OptionAvatar>
                          <OptionIcon icon={option.icon} />
                        </OptionAvatar>
                      ) : undefined}
                      title={option.name}
                      description={option.description}
                    />
                  </OptionListItem>
                ) : null }
              </Option>
            </div>
          ))}
        </ul>
      </div>
      )}
    </>
  );
};

OptionsList.propTypes = {
  message: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
  ).isRequired,
  onSelection: PropTypes.func.isRequired,
  showOptionChildren: PropTypes.bool,
  languageCode: PropTypes.string,
  showOptions: PropTypes.bool,
  orderKey: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default OptionsList;
