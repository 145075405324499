import { useCallback } from 'react';
import { useChat, useLocalChat } from '@guuru/chat-web';
import { getCurrentUser } from '@guuru/auth-web';
import { errorCode, errorType } from '@guuru/graphql-common';
import { useApolloClient } from '@apollo/client';
import events from '@guuru/events-web';
import handleChatStatusChange
  from '../sagas/chatActions/handleChatStatusChange';

const { NOT_FOUND, FORBIDDEN } = errorType;

export default () => {
  const { currentChatId: chatId, hasCurrentChat } = useLocalChat();
  const client = useApolloClient();

  const onChatUpdate = useCallback(({ data: subscriptionData }, oldExpert) => {
    const { chatUpdated = {} } = subscriptionData?.data ?? {};
    const expertId = chatUpdated?.expert?.id;

    if (!oldExpert && expertId && expertId !== 'bot') {
      events.expertConnected(chatUpdated.relatedTimes?.effectiveResponseTime);
    }
    handleChatStatusChange({ chatId, ...chatUpdated });
  }, [chatId]);

  const {
    data: {
      chat: {
        expert,
      } = {},
    } = {},
  } = useChat({
    chatId: (getCurrentUser() && hasCurrentChat ? chatId : null),
    useSubscriptions: true,
    onChatUpdate: (payload) => onChatUpdate(payload, expert),
    onChatDelete: () => {
      window.location.reload();
    },
    onError: (e) => {
      if (errorCode(e) === NOT_FOUND) {
        window.location.reload();
        return;
      }
      if (errorCode(e) === FORBIDDEN) {
        client.close();
        return;
      }
      throw e;
    },
  });
};
