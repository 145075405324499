import React from 'react';
import PropTypes from 'prop-types';
import { Space, Tooltip, Typography } from 'antd';
import { FreshchatOutlined } from '@guuru/react-icon';
import { expertType } from '@guuru/react-types';

const { Text } = Typography;

const getExternalIcon = function (provider) {
  let icon;
  switch (provider) {
    case 'freshchat': {
      icon = <FreshchatOutlined />;
      break;
    }
    default:
  }
  if (!icon) {
    return null;
  }
  return <Tooltip title={provider}>{icon}</Tooltip>;
};

const ExpertName = function ({ expert = undefined, isExternal }) {
  if (!expert?.name) {
    return '<not available>';
  }
  const provider = expert?.provider || expert?.id;
  return (
    <Space size={4}>
      <Text>{expert?.name}</Text>
      {isExternal && getExternalIcon(provider)}
    </Space>
  );
};

ExpertName.propTypes = {
  expert: expertType,
  isExternal: PropTypes.bool.isRequired,
};

export default ExpertName;
