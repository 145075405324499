import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import Opinion from './Opinion';

const OpinionsList = function ({ mentions }) {
  return (
    <List
      itemLayout="horizontal"
      dataSource={mentions}
      renderItem={(mention) => (
        <Opinion mention={mention} />
      )}
    />
  );
};

OpinionsList.propTypes = {
  mentions: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

export default OpinionsList;
