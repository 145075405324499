import React from 'react';
import { Button, Result } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import ImageSvg from './Error_404.svg';

const PageNotFound = function () {
  return (
    <Result
      icon={(
        <Icon component={ImageSvg} style={{ fontSize: '300px' }} />
      )}
      title="Oops"
      subTitle="We can't seem to find the page you're looking for."
      extra={(
        <Button onClick={() => window.history.back()} type="primary">
          Back to home
        </Button>
    )}
    />
  );
};

export default PageNotFound;
