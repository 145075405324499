import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import CoinStack from './CoinStackOutlined.svg';

const CoinStackOutlined = function (props) {
  return (
    <Icon {...props} component={CoinStack} viewBox="10 10 180 180" />
  );
};

export default CoinStackOutlined;
