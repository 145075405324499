import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

const SubmissionsFilters = {
  partnerId: {
    key: 'partnerId',
  },
  searchText: {
    key: 'searchText',
  },
  score: {
    key: 'score',
    label: 'Score',
    type: 'score',
    getFilters: ({ partner }) => (
      partner?.submissions?.scores
        .map((value = 0) => ({ name: `${value}`, value }))
        .sort(({ value: a }, { value: b }) => a - b)
    ),
  },

  status: {
    key: 'status',
    label: 'Status',
    filters: [
      {
        value: 'approved',
        name: 'Approved',
        icon: CheckCircleOutlined
        ,
      },
      {
        value: 'pending',
        name: 'Pending',
        icon: QuestionCircleOutlined,
      },
      {
        value: 'rejected',
        name: 'Rejected',
        icon: CloseCircleOutlined
        ,
      },
    ],
  },
};

export default SubmissionsFilters;
