import styled, { css } from 'styled-components';
import { Row } from 'antd';

export default styled(Row)`
  ${({ onClick }) => onClick && css`
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  `}

  p {
    font-weight: 200;
    text-align: right;
    margin-bottom: 0;

    + p {
      margin-top: 1em;
    }
  }

  h1, h2 {
    font-weight: normal;
    font-size: 21px;
    opacity: 0.9;
    text-shadow: rgba(255 255 255 / 30%) 0 0 30px;
    margin-bottom: 0.2em;

    + p:first-of-type {
      margin-top: 2em;
    }
  }

  h2 {
    margin: 8px 0;
  }

  a {
    color: ${({ theme }) => theme.onSecondaryColor};
  }

  a:hover {
    text-decoration: underline;
  }

  img {
    width: inherit !important;
    height: inherit !important;
  }
`;
