import styled from 'styled-components';
import { Row } from 'antd';

const ReopenChatCtaTeaser = styled(Row)`
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 300;
  cursor: pointer;
  text-align: center;
`;

export default ReopenChatCtaTeaser;
