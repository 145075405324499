import ms from 'ms';

const matchingConfig = {
  retryMs: ms('45s'),
  expertSelection: [{
    status: 'integrations',
    includeOnlyExpertsInCategory: false,
  }, {
    status: 'aboveThreshold',
    includeOnlyExpertsInCategory: true,
  }, {
    status: 'aboveThreshold',
    includeOnlyExpertsInCategory: false,
  },
  ],
  onboardingSelection: [{
    status: 'onboarding',
    includeOnlyExpertsInCategory: true,
  }, {
    status: 'onboarding',
    includeOnlyExpertsInCategory: false,
  },
  ],

  // pre-compute thresholds
  ratingAboveThresholdWindow: 40,
  expertInactiveDays: 30,
};

export {
  // eslint-disable-next-line import/prefer-default-export
  matchingConfig,
};
