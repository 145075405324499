const deviceTypes = {
  mobile: {
    name: 'mobile',
  },
  desktop: {
    name: 'desktop',
    minWidthRes: 1280,
  },
  tablet: {
    name: 'tablet',
    minWidthRes: 740,
  },
};

const getDeviceType = (widthRes) => {
  if (widthRes >= deviceTypes.desktop.minWidthRes) {
    return deviceTypes.desktop.name;
  }
  if (widthRes >= deviceTypes.tablet.minWidthRes) {
    return deviceTypes.tablet.name;
  }
  return deviceTypes.mobile.name;
};

module.exports.DeviceTypes = deviceTypes;
module.exports.getDeviceType = getDeviceType;
