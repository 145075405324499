import styled from 'styled-components';
import { Select } from 'antd';

export const SelectInput = styled(Select)`
  margin-top: 5px;
  width: 50%;

  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 812px) {
    font-size: 1rem;
    width: 100% !important;
  }
`;

export const TargetOption = styled(Select.Option)`
  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 812px) {
    font-size: 1rem;
  }
`;
