import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import {
  flow, setInputType, types, useChatSettings,
} from '@guuru/chat-web';
import ChatMessageBubble from '../../ChatMessageBubble';
import ChatRenderText from '../Text';
import TextMessage from '../../TextMessage';
import OptionsList from '../../OptionsList';

const { INPUT_TYPE_RESTART } = flow;

const ChatRenderQuickActions = function ({ message, dispatch = () => {} }) {
  const { t } = useTranslation();
  const { data: { chatSettings } = {}, loading } = useChatSettings();

  const [showActions, setShowActions] = useState(true);

  const skipIfAdviceQuestion = (
    chatSettings?.quickActionsSettings?.quickActionSkipIfAdviceQuestion
  );

  const handleQuickActionSelection = useCallback((
    quickAction,
    autoSubmited = false,
  ) => {
    if (quickAction.action === 'requestQuestion' && skipIfAdviceQuestion) {
      setShowActions(false);
    }
    dispatch({
      type: types.CHAT_RUN_NEXT_STEP,
      processArgs: {
        quickAction,
        autoSubmited,
        createChatImmediately: skipIfAdviceQuestion,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!showActions) return null;

  if (loading) {
    return (
      <ChatRenderText
        message={{
          timestamp: message.createdAt,
          i18n: '',
          type: 'connecting',
        }}
      />
    );
  }

  const { quickActions } = message.metadata;

  if (quickActions.length === 1 && quickActions[0].action === 'requestQuestion') {
    handleQuickActionSelection(quickActions[0], true);
    return null;
  }

  const liveChatOnTop = (
    chatSettings?.quickActionsSettings?.quickActionRequestQuestionsTop
  );

  if (skipIfAdviceQuestion) {
    setInputType(INPUT_TYPE_RESTART);
  }

  if (!quickActions.length) {
    return (
      <ChatMessageBubble>
        <TextMessage>{message.i18n}</TextMessage>
      </ChatMessageBubble>
    );
  }

  const groups = groupBy(quickActions, (channel) => (
    channel.action === 'requestQuestion' ? 'liveChat' : 'general'
  ));
  const guurusBlockMsg = t('bot:::requestQuickActionSelectionGuurus');
  const liveChat = { message: guurusBlockMsg, quickActions: groups.liveChat };
  const general = { message: message.i18n, quickActions: groups.general };
  const quickActionsByType = liveChatOnTop
    ? [liveChat, general]
    : [general, liveChat];

  return (
    <ChatMessageBubble fullWidth>
      {quickActionsByType
        .filter(({ quickActions: groupQuickActions }) => (
          groupQuickActions?.length
        ))
        .map(({ message: groupMessage, quickActions: groupQuickActions }) => (
          <OptionsList
            key={groupMessage}
            message={groupMessage}
            options={groupQuickActions}
            onSelection={handleQuickActionSelection}
            orderKey="priority"
          />
        ))}
    </ChatMessageBubble>
  );
};

ChatRenderQuickActions.propTypes = {
  message: PropTypes.shape({
    createdAt: PropTypes.number,
    i18n: PropTypes.string,
    metadata: PropTypes.shape({
      quickActions: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.string,
      })),
    }),
  }).isRequired,
  dispatch: PropTypes.func,
};

export default ChatRenderQuickActions;
