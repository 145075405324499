import toArray from 'lodash/toArray';
import startCase from 'lodash/startCase';

const EMOJI_REGEX = /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])+?/g;

const HELPER = {
  validateSingleEmoji: (text = '') => {
    const input = text.trim();
    const textSize = toArray(input).length;
    if (textSize === 1) {
      const isEmoji = new RegExp(EMOJI_REGEX, 'g');
      if (input.match(isEmoji)) {
        return 'emoji';
      }
    }
    return 'text';
  },

  isValidRegExp: (url) => {
    let isValid = true;
    try {
      // eslint-disable-next-line no-new
      new RegExp(url);
    } catch (e) {
      isValid = false;
    }
    return isValid;
  },

  shortenText: (text = '', length = 140) => (
    text.replace(new RegExp(`^(.{${length}}[^s]*).*`, 's'), '$1 ...')
  ),

  convertToPhrase: (text) => (
    !text ? '' : startCase(text)
  ),
};

export default HELPER;
