const fileInitalValue = function (value) {
  return value
    ? {
      uid: value,
      name: value,
      status: 'done',
      url: value,
    }
    : null;
};

export default fileInitalValue;
