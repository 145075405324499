import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import events from '@guuru/events-web';

const addQuestion = (partnerId, question) => {
  const isForm = ChatAPI.storeRetrieveChannel(partnerId) === 'form';
  const message = ChatAPI.messages.createAnonymousTextMessage(
    question,
    false,
    false,
    isForm ? { messageStyle: 'smartForm' } : undefined,
  );
  ChatAPI.storeQuestion(partnerId, question);
  ChatAPI.pushPendingMessages(message);
  return message;
};

const storeQuestion = (partnerId, question) => {
  const message = addQuestion(partnerId, question);
  return put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
};

export default {
  validate() {
    return true;
  },

  * render(partnerId) {
    const questionFromPayload = ChatAPI.storeRetrieveQuestion(partnerId);
    if (questionFromPayload) {
      yield storeQuestion(partnerId, questionFromPayload);
      return { goToNextStep: true };
    }
    return { goToNextStep: false };
  },

  processResponse(partnerId, { text }) {
    events.questionSubmitted(text);
    return storeQuestion(partnerId, text);
  },

};
