import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { messageTypes } from '@guuru/constants-common';
import { ChatAPI } from '@guuru/api-web';
import { useChatSettings } from '@guuru/chat-web';
import {
  MESSAGE_TYPE_BUTTON,
  MESSAGE_TYPE_WAIT_FALLBACK,
} from '@guuru/constants-common/src/messageTypes';
import ChatRenderCategories from './renders/Categories';
import ChatRenderVisitorRole from './renders/VisitorRole';
import ChatRenderPartners from './renders/Partners';
import ChatRenderQuickActions from './renders/QuickActions';
import WaitFallback from './renders/WaitFallback';
import ChatRenderPredictionConfirmation from './renders/PredictionConfirmation';
import ChatRenderPredictionAnswer from './renders/PredictionAnswer';
import ChatRenderUserInfo from './renders/UserInfo';
import ChatRenderWaitTimeout from './renders/WaitTimeout';
import ChatRenderContact from './renders/Contact';
import ChatRenderAttachment from './renders/Attachment';
import ChatRenderLinkPreview from './renders/LinkPreview';
import ChatRenderEmoji from './renders/Emoji';
import ChatRenderText from './renders/Text';
import Delete from './renders/Delete';
import ChatAvatar from './ChatAvatar';
import MessageWrapper from './style';
import ChatRenderIntentSelection from './renders/IntentSelection';
import ChatRenderButton from './renders/Button';

const {
  MESSAGE_TYPE_CONTACT,
  MESSAGE_TYPE_ATTACHMENT,
  MESSAGE_TYPE_CATEGORY,
  MESSAGE_TYPE_WAIT_TIMEOUT,
  MESSAGE_TYPE_VISITOR_ROLE,
  MESSAGE_TYPE_PARTNER_SELECTION,
  MESSAGE_TYPE_QUICK_ACTION_SELECTION,
  MESSAGE_TYPE_SMS_FALLBACK,
  MESSAGE_TYPE_PREDICTION_ANSWER,
  MESSAGE_TYPE_PREDICTION_RESULT,
  MESSAGE_TYPE_USER_INFO,
  MESSAGE_TYPE_EMOJI,
  MESSAGE_TYPE_INTENT_SELECTION,
  MESSAGE_TYPE_WELCOME,
} = messageTypes;

const getFullWidth = (messageType, isBot, side, smartFormStyle) => {
  const isLeftSide = side === 'left';
  return (isBot && isLeftSide && !smartFormStyle)
    ? 'mod--fullWidth'
    : '';
};

const ChatMessage = function ({
  chatId = null,
  message,
  side,
  showProfileImage,
  showBotAvatar,
  customBotAvatar = null,
  readOnly = false,
  preview = false,
  partner = null,
  showDelete = false,
  dispatch = () => {},
  locale = null,
}) {
  const chatLocale = locale || ChatAPI.storeRetrieveLocale();
  const {
    smartFormStyle,
    chatAlignment,
    author: { __typename, photoUrl } = {},
  } = message || {};

  const {
    data: {
      chatSettings: {
        chatWidgetShowProfiles,
      } = {},
    } = {},
  } = useChatSettings();

  const isExpert = __typename === 'ExpertAuthor';
  const isBot = __typename === 'BotAuthor';
  const isHeroesFormat = chatWidgetShowProfiles
    && message?.type === MESSAGE_TYPE_WELCOME
    && !readOnly;

  const chatWrapperClassName = useMemo(() => {
    const showAvatar = (!showProfileImage && !smartFormStyle);
    const skipProfileImage = showAvatar ? 'skip-profile-image' : '';
    const withoutProfileImage = (
      (isExpert || (isBot && showBotAvatar)) && showAvatar
    ) ? 'without-profile-image' : '';
    const fullWidth = getFullWidth(message.type, isBot, side, smartFormStyle);
    const smartForm = smartFormStyle ? 'smart-form' : '';
    const bot = isBot ? 'bot' : '';
    return [
      'chat-message-wrapper',
      `mod--${side}`,
      fullWidth,
      skipProfileImage,
      withoutProfileImage,
      smartForm,
      bot,
    ].filter(Boolean).join(' ');
  }, [
    isBot,
    isExpert,
    message.type,
    showProfileImage,
    showBotAvatar,
    side,
    smartFormStyle,
  ]);

  const renderedMessage = useMemo(() => {
    switch (message.type) {
      case MESSAGE_TYPE_CATEGORY:
        return (
          <ChatRenderCategories
            message={message}
            locale={chatLocale}
            readOnly={readOnly}
            dispatch={dispatch}
          />
        );
      case MESSAGE_TYPE_VISITOR_ROLE:
        return <ChatRenderVisitorRole message={message} dispatch={dispatch} />;
      case MESSAGE_TYPE_PARTNER_SELECTION:
        return <ChatRenderPartners message={message} />;
      case MESSAGE_TYPE_QUICK_ACTION_SELECTION:
        return <ChatRenderQuickActions message={message} dispatch={dispatch} />;
      case MESSAGE_TYPE_BUTTON:
        return (
          <ChatRenderButton
            chatId={chatId}
            message={message}
            dispatch={dispatch}
            readOnly={readOnly}
          />
        );
      case MESSAGE_TYPE_SMS_FALLBACK:
      case MESSAGE_TYPE_WAIT_FALLBACK:
        return (
          <WaitFallback
            message={message}
            readOnly={readOnly}
            chatId={chatId}
          />
        );
      case MESSAGE_TYPE_PREDICTION_ANSWER:
        return <ChatRenderPredictionAnswer message={message} />;
      case MESSAGE_TYPE_PREDICTION_RESULT:
        return (
          <ChatRenderPredictionConfirmation
            message={message}
            readOnly={readOnly}
            preview={preview}
            dispatch={dispatch}
          />
        );
      case MESSAGE_TYPE_USER_INFO:
        return (
          <ChatRenderUserInfo
            chatId={chatId}
            message={message}
            readOnly={readOnly}
          />
        );
      case MESSAGE_TYPE_WAIT_TIMEOUT:
        return (
          <ChatRenderWaitTimeout
            message={message}
            chatId={chatId}
            readOnly={readOnly}
          />
        );
      case MESSAGE_TYPE_CONTACT:
        return (
          <ChatRenderContact
            message={message}
            readOnly={readOnly}
            chatId={chatId}
          />
        );
      case MESSAGE_TYPE_ATTACHMENT:
        return (
          <ChatRenderAttachment message={message} />
        );
      case 'link':
        return (
          <ChatRenderLinkPreview message={message} />
        );
      case MESSAGE_TYPE_EMOJI:
        return <ChatRenderEmoji message={message} />;
      case MESSAGE_TYPE_INTENT_SELECTION:
        return (
          <ChatRenderIntentSelection
            message={message}
            readOnly={readOnly}
            partnerId={partner?.id}
            dispatch={dispatch}
            locale={chatLocale}
          />
        );
      default:
        return <ChatRenderText message={message} tooltip={!smartFormStyle} />;
    }
  }, [
    message,
    chatLocale,
    readOnly,
    chatId,
    preview,
    partner,
    smartFormStyle,
    dispatch,
  ]);

  if (!renderedMessage) return null;

  return (
    <div className="row">
      <div className={smartFormStyle ? null : `col message-${side}`}>
        <MessageWrapper
          className={chatWrapperClassName}
          chatAlignment={chatAlignment}
        >
          <ChatAvatar
            url={photoUrl}
            isBot={isBot}
            isExpert={isExpert}
            showProfileImage={showProfileImage}
            customBotAvatar={customBotAvatar}
            isHeroesFormat={isHeroesFormat}
            isHidden={smartFormStyle
              || (isBot && !showBotAvatar)
              || [MESSAGE_TYPE_QUICK_ACTION_SELECTION].includes(message.type)}
          />
          {renderedMessage}
          {showDelete && (
            <Delete messageId={message.id} chatId={chatId} />
          )}
        </MessageWrapper>
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  chatId: PropTypes.string,
  message: PropTypes.shape({
    id: PropTypes.string,
    author: PropTypes.shape({
      id: PropTypes.string,
    }),
    type: PropTypes.string,
    __typename: PropTypes.string,
    smartFormStyle: PropTypes.bool,
    chatAlignment: PropTypes.string,
  }).isRequired,
  side: PropTypes.string.isRequired,
  showProfileImage: PropTypes.bool.isRequired,
  showBotAvatar: PropTypes.bool.isRequired,
  customBotAvatar: PropTypes.string,
  readOnly: PropTypes.bool,
  preview: PropTypes.bool,
  partner: PropTypes.shape({
    id: PropTypes.string,
  }),
  showDelete: PropTypes.bool,
  dispatch: PropTypes.func,
  locale: PropTypes.string,
};

export default React.memo(ChatMessage, (prev, next) => (
  prev.message.id === next.message.id
  && prev.showProfileImage === next.showProfileImage
  && prev.message.text === next.message.text
));
