import React from 'react';
import PropTypes from 'prop-types';
import { Card as AntCard } from 'antd';
import styled from 'styled-components';

const StyledCard = styled(AntCard)`
  .ant-card-body:has(.ant-collapse) {
    padding: 8px 16px;
  }
`;

const Card = function ({
  visible = true,
  white = true,
  children,
  ...props
}) {
  if (!visible) {
    return null;
  }
  return (
    <StyledCard
      {...props}
      className={white ? 'ant-card-empty-white' : undefined}
    >
      {children}
    </StyledCard>
  );
};

Card.propTypes = {
  visible: PropTypes.bool,
  white: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Card;
