import { useQuery } from '@apollo/client';
import { client } from '@guuru/graphql-web';
import i18n from '@guuru/translation-common';
import GET_TRANSLATIONS from './queries/getTranslations';

const setTranslations = (language, translations) => {
  const { expertAlias, communityAlias } = translations?.chat || {};
  if (expertAlias || communityAlias) {
    i18n.options.interpolation.defaultVariables = {
      expertAlias,
      communityAlias,
    };
  }
  Object.keys(translations).forEach((ns) => {
    i18n.addResourceBundle(language, ns, translations[ns]);
  });
};

const loadTranslations = async (locale, namespaces, partnerId) => {
  const { data: { translations } } = await client.query({
    query: GET_TRANSLATIONS,
    variables: {
      id: partnerId,
      locale,
      namespaces,
    },
  });
  namespaces.forEach((ns) => {
    i18n.addResourceBundle(locale, ns, translations[ns]);
  });
};

const useLoadTranslations = (locale, namespaces, partnerId) => {
  useQuery(GET_TRANSLATIONS, {
    variables: {
      id: partnerId,
      locale,
      namespaces,
    },
    onCompleted: ({ translations } = {}) => {
      namespaces.forEach((ns) => {
        i18n.addResourceBundle(locale, ns, translations[ns]);
      });
    },
  });
};

export {
  setTranslations,
  loadTranslations,
  useLoadTranslations,
};
