import { ChatAPI } from '@guuru/api-web';
import { client } from '@guuru/graphql-web';
import { i18n } from '@guuru/translation-web';
import { ChatStep } from '@guuru/constants-common';
import { ExpertsPriority } from '@guuru/expert-common';
import IS_ADVICE_QUESTION from './queries/isAdviceQuestion';

export default {
  validate() {
    const { priority } = ChatAPI.storeRetrieveParams();
    return ![
      ExpertsPriority.bot.value,
      ExpertsPriority.guurusOnly.value,
    ].includes(priority);
  },

  * render(partnerId) {
    const question = ChatAPI.storeRetrieveQuestion();
    const {
      data: {
        partner: {
          isAdviceQuestion,
        } = {},
      } = {},
    } = yield client.query({
      query: IS_ADVICE_QUESTION,
      variables: {
        partnerId,
        question,
        languageCode: i18n.language,
      },
    });

    if (isAdviceQuestion) {
      ChatAPI.storeChatPriority(ExpertsPriority.guurusOnly.value);
      return { goToStep: ChatStep.requestCategory };
    }

    return { goToNextStep: true };
  },
};
