import { useTranslation } from 'react-i18next';
import { ChatRowWrapper } from '@guuru/react-chat-messages';
import { Row, Space } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import RatingButtons from '../../ChatHeader/RatingButtons';
import { Rating, RatingCta } from './style';

const RatingPanel = function ({
  visible,
  chatId,
  rating = undefined,
  setOpen,
  onSubmit,
  expertName,
}) {
  const { t } = useTranslation();
  if (!visible) {
    return null;
  }
  return (
    <RatingCta justify="center">
      <Rating>
        <ChatRowWrapper>
          <Row type="flex" justify="end" style={{ padding: '12px 12px 0 0' }}>
            <CloseCircleOutlined onClick={() => setOpen(false)} />
          </Row>
          <Space
            direction="vertical"
            align="center"
            size={4}
            style={{ width: '100%' }}
          >
            <Space size={4}>{t('chat:::preRatingCta', { expertName })}</Space>
            <RatingButtons
              showEmojiText
              chatId={chatId}
              selectedRating={rating}
              onSubmit={onSubmit}
              width={50}
              eventLabel="Pre Rated CTA"
            />
          </Space>
        </ChatRowWrapper>
      </Rating>
    </RatingCta>
  );
};

RatingPanel.propTypes = {
  chatId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  expertName: PropTypes.string.isRequired,
  rating: PropTypes.string,
};

export default RatingPanel;
