const ExpertsPriority = {
  guurusOnly: {
    value: 'guurusOnly',
    label: 'Guurus Only',
    extra: 'Only Guurus will be requested for new conversations.',
    agentsWorkingHoursHelp: 'Hot-transfers to Agents will not be available.',
  },
  agentsOnly: {
    value: 'agentsOnly',
    label: 'Agents Only',
    extra: 'Only Agents will be requested for new conversations',
  },
  agentsLast: {
    value: 'agentsLast',
    label: 'Agents Last',
    extra: 'Agents will be requested only if no Guuru answers new conversations',
  },
  agentsFirst: {
    value: 'agentsFirst',
    label: 'Agents First',
    extra: 'Prioritize Agents in the first batch. Subsequent batches will be mixed with Guurus.',
  },
  mixed: {
    value: 'mixed',
    label: 'Mixed',
    extra: 'Agents will act as Guurus, they will be requested for new conversations using our rules',
  },
  bot: {
    value: 'bot',
    label: 'Bot',
    extra: 'Provide automatic answers based on your existing content',
  },
};

export const getIntentPriorities = () => [
  ExpertsPriority.guurusOnly,
  ExpertsPriority.agentsOnly,
  ExpertsPriority.agentsFirst,
  ExpertsPriority.bot,
];

export const getAgentsPriorities = (priority) => [
  ExpertsPriority.agentsFirst.value === priority
    ? ExpertsPriority[priority] : undefined,
  ExpertsPriority.agentsOnly,
  ExpertsPriority.agentsLast,
  ExpertsPriority.mixed,
].filter(Boolean);

export default ExpertsPriority;
