import styled from 'styled-components';
import { Col } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const ExpanderWrapper = styled(Col)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
