import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChatState } from '@guuru/constants-common';
import { Form } from 'antd';
import { SendButtonOutlined } from '@guuru/react-icon';
import { LoadingOutlined } from '@ant-design/icons';
import events from '@guuru/events-web';
import { botMessagesTypes } from '@guuru/chat-web';
import { useTranslation } from 'react-i18next';
import { FormItem, SearchInput } from '../../SearchInput';
import ChatRenderText from '../Text';
import GET_CHAT from './queries/getChatWaitFallback';
import PROVIDE_USER_EMAIL from './mutations/provideUserEmail';

const { waitFallbackRegistered } = botMessagesTypes;

const WaitFallback = function ({ chatId, message, readOnly = false }) {
  const { t } = useTranslation();
  const { email, text } = message;

  const { assigned, created } = ChatState;
  const [userEmail, setUserEmail] = useState();
  const [currentChatStatus, setCurrentChatStatus] = useState();
  const [form] = Form.useForm();
  const {
    data: {
      chat: {
        id,
        status,
      } = {},
    } = {},
    loading,
  } = useQuery(GET_CHAT, {
    variables: {
      id: chatId,
    },
    skip: readOnly,
  });

  useEffect(() => {
    setCurrentChatStatus(status);
  }, [status]);

  const [
    provideUserEmail, {
      error,
      loading: isSaving,
    },
  ] = useMutation(PROVIDE_USER_EMAIL);

  const confirmedEmail = userEmail || email;
  const isEmailConfirmed = !isSaving && confirmedEmail;

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      try {
        await provideUserEmail({
          variables: { chatId: id, email: values.email },
        });
        events.waitFallback(values.email);
        setUserEmail(values.email);
      } catch (err) {
        setUserEmail();
      }
    }).catch((err) => err);
  };
  if (isEmailConfirmed) {
    const numberConfirmed = {
      ...message,
      i18n: email ? text : t(waitFallbackRegistered, { email }),
    };
    return <ChatRenderText message={numberConfirmed} tooltip={false} />;
  }

  const disabled = readOnly
    || (!isEmailConfirmed && ![assigned, created].includes(currentChatStatus));

  const Icon = isSaving ? LoadingOutlined : SendButtonOutlined;

  const validation = t('bot:::messageTypeContactValidationEmail');
  const placeholder = t('bot:::messageTypeContactMessageEmail');

  return (
    <ChatRenderText message={message} tooltip={false} loading={loading}>
      {!disabled && (
        <Form
          form={form}
          initialValues={{ email: '' }}
          className="message-options"
        >
          <FormItem
            name="email"
            help={error && validation}
            rules={[{ required: true, message: validation, type: 'email' }]}
            validateStatus={error ? 'error' : undefined}
          >
            <SearchInput
              placeholder={placeholder}
              enterButton={<Icon />}
              onSearch={handleSubmit}
              disabled={disabled || isSaving}
            />
          </FormItem>
        </Form>
      )}
    </ChatRenderText>
  );
};

WaitFallback.propTypes = {
  chatId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    metadata: PropTypes.shape({
      placeholderMessage: PropTypes.string,
      validationMessage: PropTypes.string,
      fallbackRegisteredMessage: PropTypes.string,
    }),
    email: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
};

export default WaitFallback;
