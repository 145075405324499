import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Linkify from '@guuru/react-linkify';
import ChatMessageBubble from '../../ChatMessageBubble';
import { Paragraph } from './style';
import Typing from './Typing';

const Message = function ({ message, children = null }) {
  // Transforms message to html
  // some messages might contain \r\n or \n that must be converted
  const htmlMessage = useMemo(() => {
    const splitedMessages = [];
    const textMessage = message.i18n;
    const lineSplittedParagraphs = textMessage.split(/\r\n|\n|\r|\\n/);

    for (let i = 0; i < lineSplittedParagraphs.length; i += 1) {
      const paragraph = lineSplittedParagraphs[i];
      const newlinesSplittedParagraphs = paragraph.split('\n');
      newlinesSplittedParagraphs.forEach((simple) => {
        splitedMessages.push((
          <Paragraph key={`message_${i}`}>
            <Linkify text={simple} />
            {(message.type === 'typing' || message.type === 'connecting') && (
              <Typing center={message.type === 'typing'} />
            )}
          </Paragraph>
        ));
      });
    }
    return <div>{splitedMessages}</div>;
  }, [message]);

  return (
    <ChatMessageBubble message={message}>
      <span className="chat-message-text-message">
        {htmlMessage}
      </span>
      {children}
    </ChatMessageBubble>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    i18n: PropTypes.string,
    type: PropTypes.string,
    createdAt: PropTypes.number,
  }).isRequired,
  children: PropTypes.node,
};

export default Message;
