import React from 'react';
import PropTypes from 'prop-types';
import ChatMessageBubble from '../../ChatMessageBubble';
import FileIcon from './FileIcon';
import Preview from './Preview';
import MessageTooltip from '../../MessageTooltip';

const renders = {
  // eslint-disable-next-line react/prop-types
  image: ({ address, filename }) => (
    <a
      className="chat-message-inline-picture-zoom-in"
      target="_blank"
      href={address}
      rel="noopener noreferrer"
    >
      <img alt={filename} src={address} />
    </a>
  ),
  // eslint-disable-next-line react/prop-types
  audio: ({ address }) => (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls>
      <source src={address} type="audio/mpeg" />
      Your browser does not support the <code>audio</code> element.
      You can <a href={address}>download the audio</a> instead.
    </audio>
  ),
  // eslint-disable-next-line react/prop-types
  video: ({ address }) => (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video controls>
      <source src={address} type="video/mp4" />
      Your browser does not support the <code>video</code> element.
      You can <a href={address}>download the video</a> instead.
    </video>
  ),
  // eslint-disable-next-line react/prop-types
  fallback: ({ address, filename }) => (
    <div className="chat-message-text-message">
      <a target="_blank" href={address} rel="noopener noreferrer">
        <FileIcon />{filename || 'file'}
      </a>
    </div>
  ),
};

const ChatRenderAttachment = function ({ message, children = null }) {
  const { attachment } = message;
  const [type] = attachment.mimetype?.split('/') || [];
  const render = renders[type] || renders.fallback;
  const isMedia = ['video', 'image', 'audio'].includes(type);
  return (
    <MessageTooltip message={message}>
      <ChatMessageBubble borderless={isMedia} transparent={isMedia}>
        <Preview>
          {render({
            address: attachment.url,
            filename: attachment.filename,
          })}
        </Preview>
        {children}
      </ChatMessageBubble>
    </MessageTooltip>
  );
};

ChatRenderAttachment.propTypes = {
  message: PropTypes.shape({
    attachment: PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
      mimetype: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.node,
};

export default ChatRenderAttachment;
