export default {
  Expert: {
    fields: {
      inbox: {
        merge: false,
      },
      availability: {
        merge: false,
      },
    },
  },
};
