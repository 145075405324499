import { gql } from '@apollo/client';

export default gql`
  subscription WaitTimeoutMessage($id: ID!) {
    waitTimeoutMessage(id: $id, forExpert: false, forUser: true){
      text
      metadata
    }
  }
`;
