import { gql } from '@apollo/client';

export default gql`
  mutation SetUserIsTyping($id: ID!, $value: Boolean!) {
    setUserIsTyping(id: $id, value: $value) {
      id
      userIsTyping
    }
  }
`;
