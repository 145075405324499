import Linkify from 'linkify-it';

const linkify = new Linkify({
  fuzzyEmail: false,
  fuzzyLink: false,
  fuzzyIP: false,
})
  .add('mailto:', null)
  .add('ftp:', null);

const findURLs = function (
  text,
  { maxLength = 2048 } = {},
) {
  if (!linkify.pretest(text)) return [];

  return (linkify.match(text) || [])
    .map(({ url }) => url)
    .filter((url) => url.length <= maxLength);
};

const cleanUrl = (url) => url.split(/[?#]/)[0];

export { findURLs, cleanUrl };
