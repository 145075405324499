import React from 'react';
import PropTypes from 'prop-types';
import StickyMessage from '../../StickyMessage';
import HTMLMessage from '../../HtmlMessage';

const ChatRenderPredictionAnswer = function ({ message }) {
  return (
    <StickyMessage>
      <HTMLMessage message={message} />
    </StickyMessage>
  );
};

ChatRenderPredictionAnswer.propTypes = {
  message: PropTypes.shape({
    i18n: PropTypes.string,
  }).isRequired,
};

export default ChatRenderPredictionAnswer;
