import { gql } from '@apollo/client';

const GET_CHAT_RATING = gql`
  query GetChatRating($id: ID!) {
    chat(id: $id){
      id
      rating
      expert {
        id
        name
        photoUrl
      }
    }
  }
`;

export default GET_CHAT_RATING;
