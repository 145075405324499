import styled, { css } from 'styled-components';
import { Avatar } from 'antd';

const small = css`
  width: 14px;
  height: 14px;
  margin-right: 3px;
  margin-top: -3px;
`;

const SizedAvatar = styled(Avatar)`
  ${({ $size }) => ($size === 'small' ? small : null)}
`;

export default SizedAvatar;
