import styled from 'styled-components';
import Button from './Button';

export default styled(Button)`
  position: relative;
  bottom: 4px;

  > span {
    white-space: nowrap;
    position: absolute;
    padding: 0.5rem;
    font-size: 0.875rem;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ theme }) => theme.primaryVariants[3]};
    color: ${({ theme }) => theme.onSecondaryColor};
    border-radius: ${({ theme }) => theme.mediumRadius};
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(50 50 93 / 11%), 0 1px 3px rgba(0 0 0 / 8%);
    transition: background 0.25s, box-shadow 0.25s;

    &:hover {
      box-shadow: 0 1px 8px rgba(0 0 0 / 30%);
      background: ${({ theme }) => theme.primaryVariants[5]};
    }

    .anticon {
      margin-right: 0.25rem;
    }
  }
`;
