import { gql } from '@apollo/client';

export default gql`
  mutation AcceptBotAnswer($id: ID!) {
    acceptBotAnswer(id: $id) {
      id
      status
    }
  }
`;
