import cloneDeep from 'lodash/cloneDeep';
import lremove from 'lodash/remove';

const addResult = ({
  list = (value) => value,
  element = (value) => value,
  updateQuery = () => {},
  removeBeforeAdd,
} = {}) => (...arg) => {
  updateQuery((previous) => {
    const result = element(...arg);
    if (!result) return { ...previous };
    const newResult = cloneDeep(previous);
    if (removeBeforeAdd) {
      lremove(list(newResult), removeBeforeAdd);
    }
    list(newResult).unshift(result);
    return newResult;
  });
};

const removeResult = ({
  elementId = (value) => value,
  list = (value) => value,
  getId = (value) => value,
  updateQuery = () => {},
} = {}) => (...result) => {
  updateQuery((previous) => {
    const resultId = elementId(...result);
    if (!resultId) return { ...previous };
    const newResult = cloneDeep(previous);
    lremove(list(newResult), (elem) => getId(elem) === resultId);
    return newResult;
  });
};

export {
  addResult,
  removeResult,
};
