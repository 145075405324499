import React from 'react';
import { List, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Text } = Typography;

const OpinionItem = styled(List.Item.Meta)`
  .ant-list-item-meta-avatar {
    font-size: 1.5rem;
    align-self: center;
    padding: 11px;
  }

  .ant-list-item-meta-content {
    align-self: center;
  }
`;

const Opinion = function ({ mention }) {
  return (
    <List.Item>
      <OpinionItem
        avatar={<Link to={mention.url} target="_blank"><LinkOutlined /></Link>}
        title={<Text>{mention.headline}</Text>}
        description={mention.summary}
      />
    </List.Item>
  );
};

Opinion.propTypes = {
  mention: PropTypes.shape({
    url: PropTypes.string,
    summary: PropTypes.string,
    headline: PropTypes.string,
  }).isRequired,
};

export default Opinion;
