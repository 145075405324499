import { gql } from '@apollo/client';

const GET_CHAT = gql`
  query GetChatExpertHeader($id: ID!) {
    chat(id: $id){
      id
      status
      rating
      isAssignedToAgent
      expert {
        id
        name
        photoUrl
      }
      isAutomated {
        id
        action
      }
    }
  }
`;

export default GET_CHAT;
