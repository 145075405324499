import styled from 'styled-components';
import { Space } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const Contacts = styled(Space)`
  padding: 6px 0 0 8px;

  span {
    color: #737376;
  }

  .anticon-check {
    color: #52c41a;
  }
`;
