import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LocaleHelper } from '@guuru/helpers-common';
import { Space } from 'antd';

const Option = function ({
  id,
  name,
  onClick,
  locale = 'en',
  children = undefined,
  className = '',
  icon = undefined,
}) {
  const label = useMemo(() => (
    LocaleHelper.localize(name, locale)
  ), [name, locale]);

  const handleSelection = useCallback(() => (
    onClick(id, label)
  ), [onClick, id, label]);

  return (
    <div
      className={className}
      onClick={handleSelection}
      onKeyPress={handleSelection}
      role="button"
      tabIndex="-1"
    >
      <Space>
        {children ?? label}
        {icon ?? icon}
      </Space>

    </div>
  );
};

Option.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  name: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  locale: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
};

export default Option;
