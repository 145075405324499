import React from 'react';
import PropTypes from 'prop-types';
import { useSuspenseQuery } from '@apollo/client';
import { Space } from 'antd';
import styled from 'styled-components';
import events from '@guuru/events-web';
import ChatThanks from '../ChatThanks';
import ExpertFeedbackAvatar from '../../ExpertFeedbackAvatar';
import FeedbackForm from './FeedbackForm';
import FeedbackHeadline from './FeedbackHealine';
import GET_CHAT_FEEDBACK_FORM_INFO from './queries/getChatFeedbackFormInfo';

const ContentWrapper = styled(Space)`
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-bottom: 40px;
`;

const ChatRequestFeedback = function ({ chatId }) {
  const { useTrackEventOnce, requestFeedback } = events;
  const {
    data: {
      chat: {
        rating,
        expert,
        feedbackRequestTopics,
      } = {},
    } = {},
  } = useSuspenseQuery(GET_CHAT_FEEDBACK_FORM_INFO, {
    variables: { id: chatId },
  });

  useTrackEventOnce(
    () => requestFeedback(),
    [chatId],
  );

  if (!feedbackRequestTopics) {
    return <ChatThanks chatId={chatId} readOnly />;
  }

  return (
    <ContentWrapper direction="vertical" size={15}>
      <Space direction="vertical" size={25}>
        <ExpertFeedbackAvatar expertPhoto={expert?.photoUrl} />
        <FeedbackHeadline expertName={expert?.name} rating={rating} />
      </Space>
      <FeedbackForm
        chatId={chatId}
        topics={feedbackRequestTopics}
      />
    </ContentWrapper>
  );
};

ChatRequestFeedback.propTypes = {
  chatId: PropTypes.string.isRequired,
};

export default ChatRequestFeedback;
