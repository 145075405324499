import { gql } from '@apollo/client';

export default gql`
  query GetExpertProfile($id: ID!) {
    chat(id: $id){
      id
      partner {
        id
        name
      }
      expert {
        id
        name
        photoUrl
        languages
        partners {
          edges {
            node {
              id
            }
            bio
            rating
            joinedAt
          }
        }
      }
    }
  }
`;
