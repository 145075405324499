import React from 'react';
import PropTypes from 'prop-types';
import { useSuspenseQuery } from '@apollo/client';
import { useChatSettings } from '@guuru/chat-web';
import { useTranslation } from 'react-i18next';
import { Row, Space } from 'antd';
import GET_CHAT_NPS_RATING from './queries/getChatNPSRating';
import { Headline } from './style';
import NPSButtons from './NpsButtons';

const ChatNPSRate = function ({ chatId }) {
  const { t } = useTranslation();
  const {
    data: {
      chatSettings: {
        name: partnerName,
      } = {},
    } = {},
  } = useChatSettings();

  const {
    data: { chat: { npsRating } = {} } = {},
  } = useSuspenseQuery(GET_CHAT_NPS_RATING, { variables: { id: chatId } });

  return (
    <Row justify="center">
      {npsRating === null ? (
        <Space direction="vertical">
          <Headline>
            {t('chat:::rateNPSHeadline', { partnerName })}
          </Headline>
          <NPSButtons chatId={chatId} />
        </Space>
      ) : (
        <Headline>
          {t('chat:::rateNPSAnswered')}
        </Headline>
      )}
    </Row>
  );
};

ChatNPSRate.propTypes = {
  chatId: PropTypes.string.isRequired,
};

export default ChatNPSRate;
