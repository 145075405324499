import { gql } from '@apollo/client';

export default gql`
  fragment IntentSubscription on IntentSubscriptionInterface {
    id
    label
    action
    title
    titleExtra
    description
    score
    ... on ShowAnswerPrediction {
      subscriptionAnswer
      allowFallback
      subscriptionTarget
      subscriptionPriority
      subscriptionOptions {
        name
        option
      }
      subscriptionOptionsText
    }
    ... on RoutingPrediction {
      subscriptionTarget
      subscriptionPriority
      subscriptionOptions {
        name
        option
      }
      subscriptionOptionsText
    }
  }

  query GetPrediction(
    $partnerId: ID!,
    $question: String!,
    $languageCode: String!,
    $channel: String,
    $params: JSON,
  ) {
    partner(id: $partnerId) {
      id
      predict(
        question: $question,
        languageCode: $languageCode,
        channel: $channel,
        params: $params
      ) {
        intentSubscription {
          ...IntentSubscription
        }
        proposedIntent {
          id
          label
          score
          minimumPrecision
        }
        possibleIntents {
          ...IntentSubscription
        }
      }
    }
  }
`;
