import merge from 'lodash/merge';
import connections from '../../connections';
import typesMissingId from '../../types-missing-id.json';
import Expert from './Expert';
import Partner from './Partner';
import Query from './Query';
import JSON from './JSON';

export default merge(connections, {
  ...typesMissingId,
  ...JSON,
  ...Expert,
  ...Partner,
  ...Query,
});
