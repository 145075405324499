import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { types } from '@guuru/chat-web';
import ChatRenderText from '../Text';
import Option from '../../Option';

const ChatRenderVisitorRole = function ({ message, dispatch = () => {} }) {
  const roles = message.metadata?.roles || [];

  const handleRoleSelection = useCallback((id, label) => {
    dispatch({
      type: types.CHAT_RUN_NEXT_STEP,
      processArgs: {
        uid: id,
        displayName: label,
        question: message.i18n,
      },
    });
  }, [dispatch, message.i18n]);

  return (
    <ChatRenderText key={message.id} message={message} tooltip={false}>
      {roles.length > 0 && (
        <div className="message-options">
          <ul className="message-options-list">
            {roles.map((role) => (
              <Option
                key={role.uid}
                id={role.uid}
                name={role.displayName}
                onClick={handleRoleSelection}
              />
            ))}
          </ul>
        </div>
      )}
    </ChatRenderText>
  );
};

ChatRenderVisitorRole.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    i18n: PropTypes.string,
    metadata: PropTypes.shape({
      roles: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.array,
      ]),
    }),
  }).isRequired,
  dispatch: PropTypes.func,
};

export default ChatRenderVisitorRole;
