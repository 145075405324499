import { takeLatest } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import * as chatSaga from './chatSaga';
import runNextStep from './chatSteps';

export default function* mainWatcher() {
  yield takeLatest(types.CHAT_INIT, chatSaga.chatInitSaga);
  yield takeLatest(types.CHAT_NEW_TEXT, chatSaga.chatNewText);
  yield takeLatest(types.CHAT_NEW_QUESTION, chatSaga.chatNewQuestion);
  yield takeLatest(types.CHAT_RUN_NEXT_STEP, runNextStep);
  yield takeLatest(types.CHAT_FLOW_CONNECTION_ERROR, chatSaga.handleChatError);
}
