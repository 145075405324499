import styled from 'styled-components';
import { Col } from 'antd';

const QuoteSymbol = styled(Col)`
  &::before {
    font-weight: 800;
    font-size: 55px;
    font-family: "Times New Roman", serif;
    color: #D3D3D3;
    content: "\\201C";
    line-height: 50px;
  }
`;

export default QuoteSymbol;
