import styled from 'styled-components';
import { Row, Space } from 'antd';

export const RateHelp = styled.div`
  font-size: 12px;
  font-weight: 100;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const Numbers = styled(Row)`
  margin: 0 !important;
`;

export const Buttons = styled(Space)`
  width: 100%;
`;
