import { gql } from '@apollo/client';

export default gql`
  subscription UserIsTyping($chatId: ID!) {
    userIsTyping(id: $chatId) {
      id
      userIsTyping
    }
  }
`;
