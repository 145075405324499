import React from 'react';
import PropTypes from 'prop-types';
import Section from './Section';

const ChatCta = function ({ children, onClick, className = '' }) {
  return (
    <Section>
      <div className="container">
        <div className="row justify-content-center">
          <div
            role="button"
            className={className}
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="-1"
          >
            <span>{children}</span>
          </div>
        </div>
      </div>
    </Section>
  );
};

ChatCta.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ChatCta;
