import styled from 'styled-components';
import { Col as AntCol, Button as AntButton } from 'antd';
import Item from '../../../Option';

export const Option = styled(Item)`
  min-width: 12.5em;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Col = styled(AntCol)`
  @media only screen and (max-width: 576px) {
    flex: auto;
  }
`;

export const Button = styled(AntButton)`
  color: ${({ theme }) => theme.primaryColor};

  &&:hover {
    color: ${({ theme }) => theme.primaryVariants[3]};

    span {
      text-decoration: underline;
    }
  }
`;
