import React from 'react';
import {
  Col, Flex, Image, Layout, theme, Typography,
} from 'antd';
import { PartnerAvatar } from '@guuru/react-partner-logo';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useCommunitySettings } from '@guuru/community-web';
import Paragraph from '../Paragraph';

const { Title } = Typography;
const { useToken } = theme;

const Header = function () {
  const { partnerId, partnerName } = useCommunitySettings();
  const { t } = useTranslation();
  const { token } = useToken();

  return (
    <Layout.Header>
      <Flex align="center" gap={20} vertical style={{ paddingTop: 28 }}>
        <Flex gap={10}>
          <Image
            src="https://cdn.guuru.com/assets/logo/guuru.svg"
            style={{ width: '85px' }}
            preview={false}
          />
          <PlusOutlined style={{ color: '#000', fontSize: 16 }} />
          <PartnerAvatar id={partnerId} size={85} />
        </Flex>
        <Flex gap={15} vertical>
          <Col align="center">
            <Title level={1}>
              {`${t('pages:::introTitle')} `}
              <strong style={{ color: token.colorPrimary }}>
                {partnerName}
              </strong>
            </Title>
          </Col>
          <Col align="center">
            <Paragraph type="secondary">
              {t('pages:::introSubTitle')}
            </Paragraph>
          </Col>
        </Flex>
      </Flex>
    </Layout.Header>
  );
};

export default Header;
