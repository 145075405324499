import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import events from '@guuru/events-web';
import { errorCode, errorType } from '@guuru/graphql-common';
import RATE_CHAT_NPS from './mutations/rateChatNPS';
import { Buttons, Numbers, RateHelp } from './style';
import NpsButton from './NpsButton';

const buttons = Array.from({ length: 11 }, (_, number) => {
  if (number < 7) return { number, color: '#e44646' };
  if (number < 9) return { number, color: '#f4b414' };
  return { number, color: '#42c694' };
});

const NpsButtons = React.memo(({ chatId }) => {
  const { t } = useTranslation();
  const { useTrackEventOnce, rateNpsPrompted } = events;

  const [rateChatNPS] = useMutation(RATE_CHAT_NPS);
  const handleRateCtaClick = useCallback(async (rating) => {
    try {
      await rateChatNPS({ variables: { id: chatId, rating } });
    } catch (e) {
      if (errorCode(e) === errorType.FORBIDDEN) {
        window.location.reload();
        return;
      }
      throw e;
    }
    events.rateChatNps(rating);
  }, [chatId, rateChatNPS]);

  useTrackEventOnce(
    () => rateNpsPrompted(),
    [chatId],
  );

  return (
    <Buttons direction="vertical">
      <Numbers gutter={[8, 5]} justify="center">
        {buttons.map((button) => (
          <NpsButton
            key={button.number}
            number={button.number}
            color={button.color}
            onSubmit={handleRateCtaClick}
          />
        ))}
      </Numbers>
      <Row justify="space-around">
        <Col>
          <RateHelp>
            <b>0</b> - {t('chat:::rateNPSLowestRatingLabel')}
          </RateHelp>
        </Col>
        <Col>
          <RateHelp>
            <b>10</b> - {t('chat:::rateNPSMaxRatingLabel')}
          </RateHelp>
        </Col>
      </Row>
    </Buttons>
  );
});

NpsButtons.propTypes = {
  chatId: PropTypes.string.isRequired,
};

export default NpsButtons;
