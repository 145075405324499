import findLast from 'lodash/findLast';
import { useEffect, useState } from 'react';
import { BotSettings, Bot } from '@guuru/constants-common';
import { TimerHelper } from '@guuru/helpers-common';

const { BOT_UID } = BotSettings;

const excludedUIDS = [BOT_UID, Bot.BOT_UID];

const extractExpertMessages = (messages) => (
  messages.filter(({ node }) => (
    // eslint-disable-next-line no-underscore-dangle
    node.author.__typename === 'ExpertAuthor'
  ))
);

const extractLastVisitorMessage = (messages) => (
  findLast(messages, ({ node }) => (
    // eslint-disable-next-line no-underscore-dangle
    node.author.__typename === 'UserAuthor'
  ))
);

const countTotalCharacters = (messages) => (
  messages.map(({ node: { text } }) => text?.length || 1)
    .reduce((a, b) => a + b, 0)
);

const useCanRate = ({
  messages,
  acceptedAt,
  expert,
  isUser,
  isOpen,
  promptTimer,
  afterUserReplied,
  totalExpertMessages,
  totalExpertCharacters,
}) => {
  const [showRate, setShowRate] = useState(false);

  useEffect(() => {
    let timeout;
    if (
      acceptedAt
      && expert?.id
      && !excludedUIDS.includes(expert?.id)
      && isUser
    ) {
      const elapsedTime = TimerHelper.millesecondsSince(acceptedAt);
      timeout = setTimeout(() => {
        setShowRate(true);
      }, Math.max(promptTimer - elapsedTime, 0));
    }
    if (!acceptedAt || !expert?.id) {
      setShowRate(false);
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedAt, expert]);

  useEffect(() => {
    if (
      !showRate
      && acceptedAt
      && expert?.id
      && !excludedUIDS.includes(expert?.id)
      && isUser
    ) {
      const expertMessages = extractExpertMessages(messages);
      const lastVisitorMsg = extractLastVisitorMessage(messages);
      const [firstExpertMsg] = expertMessages;
      const userReplied = firstExpertMsg
        ? lastVisitorMsg?.node?.createdAt > firstExpertMsg?.node?.createdAt
        : false;
      const shouldShowRate = afterUserReplied
        ? userReplied
        : (
          userReplied && (expertMessages.length >= totalExpertMessages)
          && (countTotalCharacters(expertMessages) >= totalExpertCharacters)
        );

      if (shouldShowRate) {
        setShowRate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, acceptedAt, expert]);

  return isOpen && showRate;
};

export default useCanRate;
