const rePhoneNumber = new RegExp('[+(]{0,2}?(?:\\(\\d+\\))?[\\d\\s-]{5,25}\\d');

export const phoneNumber = (text, pos) => {
  const tail = text.slice(pos - 1);
  if (!rePhoneNumber.test(tail)) return null;

  const [match] = tail.match(rePhoneNumber);
  const numbersOnly = match.replace(/[\s-)(]/g, '');
  if (numbersOnly.length < 9 || numbersOnly.length > 15) return null;

  return {
    matchedLength: match.trim().length - 1,
    match,
    tail,
  };
};

export const phoneNumberValidator = (text, pos = 1) => {
  const hasPhoneNumber = phoneNumber(text, pos) || {};
  if (!hasPhoneNumber) {
    return 0;
  }
  const { matchedLength, match, tail } = hasPhoneNumber;
  if (!tail?.startsWith(match)) return 0;
  return matchedLength;
};
