import React from 'react';
import PropTypes from 'prop-types';
import { convertToHtml } from '@guuru/markdown-common';
import Markdown from './Markdown';

const MarkdownRender = function ({ content, style = undefined }) {
  return (
    <Markdown
      style={style}
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: convertToHtml(content) }}
    />
  );
};

MarkdownRender.propTypes = {
  content: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

export default MarkdownRender;
