import styled from 'styled-components';
import { Button, Upload as AntUpload } from 'antd';

export const Upload = styled(AntUpload)`
  .ant-upload-list {
    width: 62vw;
  }
`;

export const UploadButton = styled(Button)`
  margin-bottom: 5px;
  margin-top: 15px;

  .uploaded-file {
    margin-bottom: 20px !important;
  }

  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 812px) {
    font-size: 1rem;
    padding-bottom: 25px;
  }
`;
