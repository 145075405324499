export const errorMessage = ({ graphQLErrors = [], networkError } = {}) => {
  if (networkError) {
    return 'Oops, seems we are having some issues, support has been notified';
  }
  const [{ message } = {}] = graphQLErrors;
  return message;
};

export const errorCode = ({ graphQLErrors = [], networkError } = {}) => {
  if (networkError) {
    return networkError.name;
  }
  const [{ extensions } = {}] = graphQLErrors;
  return extensions?.code;
};

export const resultErrorCode = ({ errors = [] } = {}) => {
  const [{ extensions } = {}] = errors;
  return extensions?.code;
};

export const errorType = {
  BAD_USER_INPUT: 'BAD_USER_INPUT',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
};
