import styled from 'styled-components';

export const Headline = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
`;

export const GoBackLink = styled.a`
  &,
  :hover,
  :visited {
    display: block;
    color: ${({ theme }) => (theme.chatLayout ? theme.onSecondaryColor : theme.onLightColor)};
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    margin: 0;
    text-decoration: underline;
  }
`;
