import React from 'react';
import { Col, Row } from 'antd';
import TotalMembers from './TotalMembers';
import AverageRating from './AverageRating';
import TotalChats from './TotalChats';

const Stats = function () {
  return (
    <Row style={{ width: '100%' }} gutter={[8, 8]} justify="space-between">
      <Col md={8} align="center">
        <TotalMembers />
      </Col>
      <Col md={8} align="center">
        <TotalChats />
      </Col>
      <Col md={8} align="center">
        <AverageRating />
      </Col>
    </Row>
  );
};

export default Stats;
