import styled from 'styled-components';
import { Button as AntButton } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const NpsNumber = styled.span`
  font-size: 17px;
  font-weight: 300;
`;

export const Button = styled(AntButton)`
  background-color: #fafbf5 !important;
  border-color: transparent !important;
  padding: 0 !important;
  width: 40px !important;
  color: ${({ $color }) => $color} !important;
  box-shadow: 0 2px 0 rgba(5 145 255 / 10%);

  &:hover {
    background-color: #fafbf5;
    border-color: transparent;
    transition-duration: 0.05s;
    transform: scale(1.2);
    transform-origin: bottom center;
  }
`;
