import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import GuuruSvg from './GuuruFilled.svg';

const GuuruFilled = function (props) {
  return (
    <Icon component={GuuruSvg} {...props} />
  );
};

export default GuuruFilled;
