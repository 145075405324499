import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from 'antd';

const MessageTooltip = function ({ message: { createdAt }, children }) {
  return (
    <Tooltip title={moment(createdAt).format('HH:mm:ss')}>
      <span>{children}</span>
    </Tooltip>
  );
};

MessageTooltip.propTypes = {
  message: PropTypes.shape({
    createdAt: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default MessageTooltip;
