import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { notification, Space } from 'antd';
import { Emoji } from '@guuru/react-emoji';
import { ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';
import { localStorageHelper } from '@guuru/helpers-common';
import events from '@guuru/events-web';
import { useTranslation } from 'react-i18next';
import ChatCta from '../ChatCta';
import RatingPanel from './RatingPanel';

const ChatRateCta = function ({
  partner,
  chatId,
  rating = undefined,
  expert = undefined,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [
    active,
    setActive,
  ] = useState(!localStorageHelper.getObject(partner.id, 'preRatingDismissed'));
  const { useTrackEventOnce, preRateExpertCtaShown } = events;

  const isVisible = expert && active;

  useTrackEventOnce(
    () => (isVisible ? preRateExpertCtaShown() : {}),
    [isVisible],
  );

  if (!isVisible) {
    return null;
  }

  const close = (dismiss = true) => {
    setActive(false);
    if (dismiss) {
      localStorageHelper.setObject(partner.id, 'preRatingDismissed', true);
      events.preRateExpertCtaDismiss();
    } else {
      notification.open({
        message: t('chat:::thankYou'),
        placement: 'bottom',
        duration: 1.5,
        bottom: 50,
        closeIcon: null,
        style: { width: '220px', marginBottom: 0, textAlign: 'center' },
      });
    }
  };

  const expertName = expert?.name.split(' ')[0];
  return (
    <>
      <RatingPanel
        visible={open}
        chatId={chatId}
        expertName={expertName}
        rating={rating}
        setOpen={setOpen}
        onSubmit={() => close(false)}
      />
      <ChatCta
        onClick={() => {
          setOpen(!open);
          events.preRateExpertCtaPressed();
        }}
      >
        <Space align="baseline" size={6}>
          <ArrowUpOutlined />
          <span>{t('chat:::preRatingCta', { expertName })}</span>
          {rating
            ? (
              <Emoji
                type={rating}
                width={16}
                style={{ marginBottom: '-3px' }}
              />
            ) : (
              <CloseOutlined onClick={close} />
            )}
        </Space>
      </ChatCta>
    </>
  );
};

ChatRateCta.propTypes = {
  chatId: PropTypes.string.isRequired,
  partner: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  expert: PropTypes.shape({
    name: PropTypes.string,
  }),
  rating: PropTypes.string,
};

export default ChatRateCta;
