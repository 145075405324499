import { marked } from 'marked';

export default function (content) {
  const renderer = new marked.Renderer();
  renderer.link = function (href, title, text) {
    const link = marked.Renderer.prototype.link.call(this, href, title, text);
    return link.replace('<a', "<a target='_blank' ");
  };
  marked.setOptions({
    renderer,
    headerIds: true,
    mangle: false,
  });
  return marked(content).trim();
}
