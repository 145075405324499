import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { i18n } from '@guuru/translation-web';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import events from '@guuru/events-web';
import UPLOAD_CHAT_ATTACHMENT from './mutations/uploadChatAttachment';
import uploadGCSSignedUrl from '../uploadGCSSignedUrl';

const useAttachmentSubmit = function ({
  chatId,
  onStart = () => {},
  onProgress = () => {},
  onUpload = () => {},
  onError = () => {},
  onFinish = () => {},
  allowedTypes = [
    /application\/*/,
    /image\/*/,
    /text\/*/,
    /video\/mp4/,
    /audio\/mpeg/,
  ],
}) {
  const [uploadChatAttachment] = useMutation(UPLOAD_CHAT_ATTACHMENT);
  const { t } = useTranslation(null, { i18n });
  const handleSubmit = useCallback(async ({ target: { files: [file] } }) => {
    if (!file) return null;

    let blob = file;
    if (!allowedTypes.some((type) => type.test(file.type))) {
      onError('Oops, you can only upload images, videos and documents.');
      return null;
    }

    onStart();
    onProgress(t('chat:::uploadingFile'));

    try {
      if (/image\/*/.test(file.type)) {
        blob = await new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(file, {
            quality: 0.8,
            mimeType: file.type,
            maxWidth: 1200,
            maxHeight: 1200,
            success: resolve,
            error: reject,
          });
        });
      }

      const filename = file.name;
      const mimetype = file.type;

      const {
        data: {
          uploadChatAttachment: {
            writeUrl,
            readUrl,
            maxBytes,
            acl,
          },
        },
      } = await uploadChatAttachment({
        variables: {
          id: chatId,
          input: {
            filename,
            mimetype,
          },
        },
      });

      if (blob.size > maxBytes) {
        onError(t('chat:::uploadFileMaxSize'));
        return null;
      }

      events.attachmentSubmit(file.type);

      await uploadGCSSignedUrl({
        contentType: mimetype,
        writeUrl,
        blob,
        maxBytes,
        acl,
      });

      await onUpload(readUrl, filename, mimetype);
      onFinish();
      return readUrl;
    } catch (err) {
      onError(err.message);
      return null;
    }
  }, [
    chatId,
    uploadChatAttachment,
    allowedTypes,
    onStart,
    onError,
    onUpload,
    onFinish,
    onProgress,
    t,
  ]);

  return handleSubmit;
};

export default useAttachmentSubmit;
