import { gql } from '@apollo/client';

export default gql`
  query GetChatMessagesStatus($chatId: ID!) {
    chat(id: $chatId) {
      id
      status
    }
  }
`;
