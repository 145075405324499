import { gql } from '@apollo/client';

export default gql`
  mutation DeleteChatRating($id: ID!) {
    deleteChatRating(id: $id) {
      id
      status
      rating
    }
  }
`;
