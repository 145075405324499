import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CommentOutlined } from '@ant-design/icons';
import { GuuruOutlined } from '@guuru/react-icon';
import { useTranslation } from 'react-i18next';

const menuItems = [
  {
    key: 'chats',
    space: 'chat',
    icon: <CommentOutlined />,
  },
  {
    key: 'home',
    paths: ['guurus'],
    space: 'community',
    icon: <GuuruOutlined />,
  },
];

const useMenuItems = (spaces) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const selectedKey = useMemo(() => (
    menuItems.find(({ key, paths }) => (
      pathname.includes(key)
      || paths?.find((path) => pathname.includes(path))
    ))?.key || 'chats'
  ), [pathname]);

  const mainMenuItems = useMemo(() => (
    spaces.map((id) => ({
      ...menuItems.find(({ space }) => space === id),
      label: t(`chat:::${id}SpaceLabel`),
    }))
  ), [spaces, t]);

  return { selectedKey, mainMenuItems };
};

export default useMenuItems;
