import styled from 'styled-components';
import { Input } from 'antd';

const { Search } = Input;

export default styled(Search)`
  flex-grow: 1;
  font-size: 1rem;
  resize: none;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 90%;

  .ant-input-search-button {
    background-color: ${({ theme }) => theme.primaryVariants[7]};

    &:hover {
      background-color: ${({ theme }) => theme.primaryVariants[6]};
    }
  }
`;
