import React from 'react';
import PropTypes from 'prop-types';
import { UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AskAnotherQuestion from './AskAnotherQuestion';
import AskAnotherQuestionButton from './AskAnotherQuestionButton';
import AskAnotherQuestionWrapper from './AskAnotherQuestionWrapper';

const StartNewFreshChatInput = function ({
  isVisible,
  onAskNewQuestion = null,
}) {
  const { t } = useTranslation();

  if (!isVisible) {
    return null;
  }

  return (
    <AskAnotherQuestionWrapper>
      <AskAnotherQuestionButton onClick={onAskNewQuestion}>
        <AskAnotherQuestion>
          {t('askAnotherQuestion')}
          {' '}
          <UndoOutlined />
        </AskAnotherQuestion>
      </AskAnotherQuestionButton>
    </AskAnotherQuestionWrapper>
  );
};

StartNewFreshChatInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onAskNewQuestion: PropTypes.func,
};

export default StartNewFreshChatInput;
