import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { LoadMoreRow } from './style';

const LoadMore = function ({
  data = undefined,
  fetchMore,
  loading = false,
  style = undefined,
  size = undefined,
}) {
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const getNextPage = useCallback(async () => {
    if (isLoadingMore) return;
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        after: data?.pageInfo?.endCursor,
        isFirstPage: false,
      },
    });
    setIsLoadingMore(false);
  }, [data, fetchMore, isLoadingMore]);

  const hasNextPage = data?.pageInfo?.hasNextPage;
  if (!hasNextPage) {
    return null;
  }

  return (
    <LoadMoreRow type="flex" justify="center" style={style}>
      <Button
        disabled={isLoadingMore || loading}
        loading={isLoadingMore || loading}
        type="primary"
        icon={<DownloadOutlined />}
        onClick={getNextPage}
        size={size}
      >
        Load more
      </Button>
    </LoadMoreRow>
  );
};

LoadMore.propTypes = {
  data: PropTypes.shape({
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool,
    }),
  }),
  fetchMore: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  style: PropTypes.shape({}),
  size: PropTypes.string,
};

export default LoadMore;
