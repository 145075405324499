import React from 'react';
import {
  Col, ConfigProvider, Row, Statistic,
} from 'antd';
import PropTypes from 'prop-types';
import { CommentOutlined, LoginOutlined } from '@ant-design/icons';
import moment from 'moment/moment';

const Details = function ({ expert }) {
  const { joinedAt, conversationLevel } = expert;
  return (
    <ConfigProvider
      theme={{
        components: {
          Statistic: {
            titleFontSize: 16,
            contentFontSize: 15,
          },
        },
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title="Joined"
            value={moment(joinedAt).locale('en').fromNow()}
            prefix={<LoginOutlined />}
            valueStyle={{ textTransform: 'capitalize' }}
          />
        </Col>
        {!!conversationLevel && (
          <Col span={12}>
            <Statistic
              title="Customers helped"
              value={`${conversationLevel}+`}
              prefix={<CommentOutlined />}
            />
          </Col>
        )}
      </Row>
    </ConfigProvider>
  );
};

Details.propTypes = {
  expert: PropTypes.shape({
    joinedAt: PropTypes.number,
    conversationLevel: PropTypes.number,
  }).isRequired,
};

export default Details;
