import { gql } from '@apollo/client';

export default gql`
  query GetPersistedCommunityFeedbacks($partnerId: ID!) {
    community(id: $partnerId) {
      id
      bestFeedbackComments {
        comment
        expert {
          id
          externalId
          name
        }
      }
    }
  }
`;
