import styled from 'styled-components';
import { Row } from 'antd';

const RowWrapper = styled(Row)`
  flex-grow: 2;

  &.mod--user.mod--chat {
    margin-bottom: 30px;
  }

  .message-left,
  .message-right {
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
  }

  .message-left {
    flex-direction: row;
  }

  .message-right {
    flex-direction: row-reverse;
  }
`;

export default RowWrapper;
