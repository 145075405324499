import React from 'react';
import events from '@guuru/events-web';
import { useTranslation } from 'react-i18next';
import Link from '../Link';
import Paragraph from '../Paragraph';

const Help = function () {
  const { t, i18n: { language } } = useTranslation();

  const communityChatLink = `https://chat.guuru.com/guuru-ag?locale=${language}`;

  return (
    <Paragraph type="secondary">
      {`${t('pages:::help')} `}
      <Link
        to={communityChatLink}
        onClick={() => events.openCommunityChatSupport()}
        target="_blank"
      >
        {t('pages:::chat')}
      </Link>
    </Paragraph>
  );
};

export default Help;
