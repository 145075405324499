import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { expandEvent, shrinkEvent } from '@guuru/chat-events-web';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { ExpanderWrapper } from './style';

const ExpanderButton = function ({ hidden }) {
  const { isFullScreen, setFullScreen } = useContext(ThemeContext);

  if (hidden) {
    return null;
  }

  const Icon = isFullScreen ? FullscreenExitOutlined : FullscreenOutlined;
  const event = isFullScreen ? shrinkEvent : expandEvent;
  return (
    <ExpanderWrapper xs={2}>
      <Icon
        onClick={() => {
          setFullScreen(!isFullScreen);
          event();
        }}
        style={{
          fontSize: 20,
          color: isFullScreen ? 'white' : null,
          margin: '5px',
        }}
      />
    </ExpanderWrapper>
  );
};

ExpanderButton.propTypes = {
  hidden: PropTypes.bool.isRequired,

};

export default React.memo(ExpanderButton);
