import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Space,
  Typography,
} from 'antd';
import OptionsList from './OptionsList';

const { Text } = Typography;

const SmartFormPredictOptions = function ({
  message,
  showOptions = false,
  onSelect,
}) {
  const {
    metadata: {
      subscriptionOptions,
    } = {},
  } = message;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="center">
        <Text>{message.text}</Text>
      </Row>
      <OptionsList
        onSelect={onSelect}
        subscriptionOptions={subscriptionOptions}
        showOptions={showOptions}
      />
    </Space>
  );
};

SmartFormPredictOptions.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string,
    metadata: PropTypes.shape({}),
  }).isRequired,
  showOptions: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default SmartFormPredictOptions;
