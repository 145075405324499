import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { i18n } from '@guuru/translation-web';
import { Space } from 'antd';
import { FormItem } from '../../../SearchInput';
import StatusIcon from './StatusIcon';
import ContactTabs from './ContactTabs';
import TextInput from '../../../TextInput';

const Contacts = function ({
  contactType,
  contacts = null,
  saving,
  submited = false,
  email = null,
  phone = null,
  readOnly = false,
}) {
  const [customFields, setCustomFields] = useState({});
  const [contactTab, setContactTab] = useState('email');

  const handleContactTypeChange = (activeKey) => {
    setContactTab(activeKey);
  };

  const onChange = useCallback((inputName) => {
    setCustomFields({ ...customFields, [inputName]: true });
  }, [customFields]);

  const contact = useCallback((name, icon, confirmed, type = 'string') => {
    const label = name.charAt(0).toUpperCase() + name.slice(1);
    const text = i18n.t(`bot:::messageTypeContactMessage${label}`);
    const validation = i18n.t(`bot:::messageTypeContactValidation${label}`);
    const placeholder = i18n.t(`bot:::messageTypeContactPlaceholder${label}`);
    const fieldTouched = customFields[name];
    const required = contactType === name
      || (contactType === 'any' && (name === contactTab));

    return {
      key: name,
      label: (
        <Space>
          <StatusIcon
            isConfirmed={confirmed}
            isTouched={fieldTouched}
            icon={icon}
          />
          {text}
        </Space>
      ),
      children: (
        <FormItem
          help={i18n.t('bot:::inputContactHelpMessage')}
          name={name}
          rules={[
            {
              required,
              message: validation,
            }, {
              type,
              message: validation,
            },
          ]}
        >
          <TextInput
            placeholder={placeholder}
            disabled={saving || readOnly}
            onChange={() => onChange(name)}
          />
        </FormItem>
      ),
    };
  }, [
    contactTab,
    contactType,
    customFields,
    onChange,
    readOnly,
    saving,
  ]);

  const items = useMemo(() => {
    const tabs = [];

    if (['email', 'any'].includes(contactType)) {
      const isValid = submited && (email || (!email && contacts.email !== ''));
      tabs.push(contact('email', MailOutlined, isValid, 'email'));
    }

    if (['phone', 'any'].includes(contactType)) {
      const isValid = submited && (phone || (!phone && contacts.phone !== ''));
      tabs.push(contact('phone', PhoneOutlined, isValid, 'string'));
    }

    return tabs;
  }, [
    contact,
    contactType,
    contacts,
    email,
    phone,
    submited,
  ]);

  return (
    <ContactTabs
      defaultActiveKey={contactTab}
      onChange={handleContactTypeChange}
      tabBarGutter={14}
      items={items}
    />
  );
};

Contacts.propTypes = {
  contactType: PropTypes.string.isRequired,
  contacts: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
  saving: PropTypes.bool.isRequired,
  submited: PropTypes.bool,
  form: PropTypes.shape({
    submit: PropTypes.func,
  }).isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default Contacts;
