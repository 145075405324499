import styled from 'styled-components';

const Preview = styled.span`
  .chat-message-inline-picture-zoom-in:hover {
    cursor: zoom-in;
  }

  img,
  video {
    max-width: 100%;
  }

  audio {
    outline: inherit;
  }
`;

export default Preview;
