import React from 'react';
import PropTypes from 'prop-types';
import {
  IdcardOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { flow } from '@guuru/chat-web';

const INPUT_TYPES_ICONS = {
  [flow.INPUT_TYPE_NAME]: IdcardOutlined,
  [flow.INPUT_TYPE_EMAIL]: MailOutlined,
};

const LeftIcon = function ({ inputType = null }) {
  const Icon = INPUT_TYPES_ICONS[inputType] || null;
  return Icon && (<Icon style={{ color: '#737376' }} />);
};

LeftIcon.propTypes = {
  inputType: PropTypes.string,
};

export default LeftIcon;
