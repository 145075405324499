import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, Radio, Select } from 'antd';
import Linkify from '@guuru/react-linkify';
import { i18n } from '@guuru/translation-web';
import TextInput from '../../../TextInput';
import RadioOption from '../../../RadioOptionInput';
import ContactsExtra from './ContactsExtra';
import { FormItem } from '../../../SearchInput';
import { SelectInput } from '../../../SelectInput';
import NumberInput from '../../../NumberInput';
import CheckboxInput from '../../../CheckboxInput';

const { Paragraph } = Typography;

const getRules = function (field) {
  const rules = [];
  if (field.mandatory) {
    const message = i18n.t('chat:::requiredField');
    if (field.fieldType === 'checkbox') {
      rules.push({
        required: true,
        type: 'boolean',
        transform: (value) => (value || undefined),
        message,
      });
    } else if (field.fieldType === 'input') {
      rules.push({
        required: true,
        type: 'string',
        whitespace: true,
        message,
      });
    } else {
      rules.push({
        required: true,
        message,
      });
    }
  }
  if (field.fieldType === 'number') {
    const message = i18n.t('chat:::numericField');
    rules.push({ type: 'number', message });
  }
  return rules;
};

const getExtra = function (helpMessage) {
  if (!helpMessage) {
    return undefined;
  }
  return <Linkify text={helpMessage} />;
};

const CustomFields = function ({
  customFields = null,
  readOnly = false,
  selectedTarget = null,
}) {
  if (!customFields || readOnly) {
    return null;
  }
  const renderInputField = function (fieldKey, currentField) {
    const extra = getExtra(currentField.helpMessage);
    const rules = getRules(currentField);
    switch (currentField.fieldType) {
      case 'radio':
      case 'select': {
        const optionValues = Array.isArray(currentField?.fieldOptions)
          ? currentField?.fieldOptions
          : Object.values(currentField?.fieldOptions || {});
        return (
          <>
            { currentField.fieldType === 'radio' && (
              <Paragraph style={{ marginBottom: 0 }}>
                {currentField.field}
              </Paragraph>
            )}
            <FormItem name={fieldKey} extra={extra} rules={rules}>
              {currentField.fieldType === 'radio' ? (
                <Radio.Group>
                  {optionValues.map((optionValue, index) => (
                    <RadioOption
                      value={optionValue.field}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`optKey-${index}`}
                    >
                      {optionValue.field}
                    </RadioOption>
                  ))}
                </Radio.Group>
              ) : (
                <SelectInput allowClear placeholder={currentField.field}>
                  {optionValues.map((optionValue, index) => (
                    <Select.Option
                      value={optionValue.field}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`optKey-${index}`}
                    >
                      {optionValue.field}
                    </Select.Option>
                  ))}
                </SelectInput>
              )}
            </FormItem>
          </>
        );
      }
      case 'checkbox': {
        return (
          <>
            <Paragraph style={{ marginBottom: 0 }}>
              {currentField.field}
            </Paragraph>
            <FormItem
              name={fieldKey}
              valuePropName="checked"
              extra={extra}
              rules={rules}
            >
              <CheckboxInput />
            </FormItem>
          </>
        );
      }
      case 'number': {
        return (
          <FormItem name={fieldKey} extra={extra} rules={rules}>
            <NumberInput
              maxLength={17}
              placeholder={currentField.field}
              style={{ width: '100%' }}
              precision={0}
            />
          </FormItem>
        );
      }
      default:
        return (
          <FormItem name={fieldKey} extra={extra} rules={rules}>
            <TextInput placeholder={currentField.field} />
          </FormItem>
        );
    }
  };

  return (
    <ContactsExtra>
      {customFields
        .filter(({ target }) => (
          !target || Number(target) === Number(selectedTarget)
        ))
        .map((customOption) => {
          const currentFieldName = customOption.field;
          return (
            <FormItem key={currentFieldName}>
              {renderInputField(currentFieldName, customOption)}
            </FormItem>
          );
        })}
    </ContactsExtra>
  );
};

CustomFields.propTypes = {
  customFields: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,
  selectedTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withTranslation()(CustomFields);
