import React, { useMemo } from 'react';
import {
  node, bool, shape, string,
} from 'prop-types';
import { MessageBubble } from './style';

const ChatMessageBubble = function ({
  children,
  borderless = false,
  transparent = false,
  fullWidth = false,
  message = undefined,
}) {
  const {
    type = '',
    smartFormStyle = false,
  } = message || {};
  const loadingMessage = type === 'typing';
  const mods = useMemo(() => {
    let allClasses = 'mod--fullBubble';
    allClasses = borderless ? `${allClasses} mod--borderless` : allClasses;
    allClasses = transparent ? `${allClasses} mod--transparent` : allClasses;
    allClasses = fullWidth ? `${allClasses} mod--fullWidth` : allClasses;
    allClasses = smartFormStyle ? `${allClasses} mod--smartForm` : allClasses;
    return allClasses;
  }, [
    borderless, transparent, fullWidth, smartFormStyle,
  ]);

  return (
    <MessageBubble className={!(loadingMessage && smartFormStyle) ? `chat-message ${mods}` : null}>
      <div className="chat-message-text-section">
        {children}
      </div>
    </MessageBubble>
  );
};

ChatMessageBubble.propTypes = {
  children: node.isRequired,
  borderless: bool,
  transparent: bool,
  fullWidth: bool,
  message: shape({
    type: string,
    smartFormStyle: bool,
  }),
};

export default ChatMessageBubble;
