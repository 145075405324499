import styled from 'styled-components';

const AnswerText = styled.div`
  text-align: left;

  img {
    display: block;
    margin: 20px auto;
    max-width: 100%;
  }

  ul {
    padding-left: 25px;
  }

  p {
    white-space: pre-line;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .mod--smartForm .bot & {
    color: #000;
    font-size: 16px;
    font-weight: 300;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { AnswerText };
