import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default styled(Link)`
  && {
    color: ${({ theme }) => theme.primaryColor};

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    }
  }
`;
