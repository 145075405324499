import hex2rgb from './hex2rgb';

const THRESHOLD = 169;

const isColorBright = ([r, g, b]) => ((
  (r * 0.299) + (g * 0.587) + (b * 0.114)) > THRESHOLD
);

const getContrastingColor = function (colors) {
  const color = Array.isArray(colors) ? colors[5] : colors;
  const dark = Array.isArray(colors) ? colors[9] : '#424245';
  const light = '#fff';
  const rgbColor = hex2rgb(color);
  return isColorBright(rgbColor.split(',')) ? dark : light;
};

export default getContrastingColor;
