const MIN_CHATS_REVIEWED = 5;
const MAX_PERCENTAGE = 100;

module.exports = {
  MIN_CHATS_REVIEWED,
  MIN_CHATS_DONE: 5,
  MAX_PERCENTAGE,
  MIN_PERCENTAGE: MAX_PERCENTAGE / MIN_CHATS_REVIEWED,
  PROGRESS_BAR_WARNING_COLOR: '#FFA500',
  PROGRESS_BAR_EXCEPTION_COLOR: '#ff8162',
  PROGRESS_BAR_SUCCESS_COLOR: '#52c41a',
  PROGRESS_BAR_INPROGRESS_COLOR: '#2096f2',
  GOOD_RATING_PERCENTAGE: 0.8,
  BAD_RATING_PERCENTAGE: 0.75,
};
