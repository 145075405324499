import React from 'react';
import PropTypes from 'prop-types';
import { CommentOutlined } from '@ant-design/icons';
import { SmartFormIntentOption, Col, Row } from './style';
import ChatRenderText from '../Text';

const SmartFormIntentOptions = function ({
  intents,
  handleSelection,
  languageCode,
  message,
  showOptions,
}) {
  return (
    <Col xs={24} md={11}>
      <ChatRenderText note message={message} />
      {showOptions && intents.map(
        (intent) => (
          <div key={intent?.id?.toString()}>
            {intent?.titleExtra && (
              <Row>
                <ChatRenderText note message={{ i18n: intent.titleExtra }} />
              </Row>
            )}
            <Col key={intent?.id?.toString()}>
              <SmartFormIntentOption
                id={intent?.id?.toString()}
                name={intent?.title || intent?.description}
                locale={languageCode}
                onClick={() => handleSelection(intent)}
                $highlighted={intent?.id === 'expertFallback'}
                icon={intent?.id === 'expertFallback' && (
                  <CommentOutlined style={{ fontSize: '26px' }} />
                )}
              />
            </Col>
          </div>
        ),
      )}
    </Col>
  );
};

SmartFormIntentOptions.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    i18n: PropTypes.string.isRequired,
  }).isRequired,
  intents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelection: PropTypes.func.isRequired,
  languageCode: PropTypes.string.isRequired,
  showOptions: PropTypes.bool.isRequired,
};

export default SmartFormIntentOptions;
