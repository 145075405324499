import { combineReducers } from 'redux';
import chatReducer from './chatReducer';

// Combines all reducers to a single reducer function
const appReducer = combineReducers({
  chatReducer,
});
const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'REDUCER__SYSTEM_RESET_STORE') {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
