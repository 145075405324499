import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Col, Divider, Flex, Skeleton, Space, Typography,
} from 'antd';
import { Rating } from '@guuru/react-rating';
import Languages from '@guuru/react-languages';
import { useTranslation } from 'react-i18next';
import events from '@guuru/events-web';
import { useQuery } from '@apollo/client';
import { LocaleHelper } from '@guuru/helpers-common';
import { scaleRating } from '@guuru/rating-common';
import useTrackEventOnce from '@guuru/events-web/src/hooks/useTrackEventOnce';
import GET_EXPERT_PROFILE from './queries/getExpertProfile';

const { Text, Title } = Typography;

const ExpertProfile = function ({ chatId }) {
  const { t, i18n: { language } } = useTranslation();

  const {
    loading,
    data: {
      chat: {
        partner: { name } = {},
        expert: {
          id: expertId,
          name: expertName,
          photoUrl,
          languages,
          partners: {
            edges: [partner = {}] = [],
          } = {},
        } = {},
      } = {},
    } = {},
  } = useQuery(GET_EXPERT_PROFILE, {
    variables: {
      id: chatId,
    },
    nextFetchPolicy: 'standby',
  });

  useTrackEventOnce(() => {
    if (expertId) {
      events.expertProfileViewed();
    }
  }, [expertId]);

  const { joinedAt, rating, bio } = partner;
  const partnerName = LocaleHelper.localize(name, language);

  const profileDate = useMemo(() => (
    new Date(joinedAt).toLocaleString(language, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
  ), [joinedAt, language]);

  if (loading) {
    return (
      <Flex vertical justify="center">
        <Space align="center" size={16} direction="vertical">
          <Skeleton.Input active loading={loading} block />
          <Skeleton.Avatar active loading={loading} size={120} />
          <Flex />
        </Space>
        <Skeleton
          active
          block
          title={false}
          loading={loading}
          paragraph={{ rows: 4 }}
        />
      </Flex>
    );
  }

  return (
    <Flex vertical justify="center">
      <Space align="center" size={16} direction="vertical">
        <Rating rating={scaleRating(rating)} />
        <Avatar size={120} src={photoUrl} />
        <Text type="secondary">
          {t('chat:::communityMember', { partnerName })}
        </Text>
      </Space>
      <Space align="center" size={2} direction="vertical">
        <Title level={3}>{expertName}</Title>
        <Languages locales={languages} maxCount={3} />
        <Text>
          {t('chat:::memberSince')} {profileDate}
        </Text>
      </Space>
      {bio && (
        <Col align="center">
          <Divider />
          <Text italic>{bio}</Text>
          <Divider />
        </Col>
      )}
    </Flex>
  );
};

ExpertProfile.propTypes = {
  chatId: PropTypes.string.isRequired,
};

export default ExpertProfile;
