import { gql } from '@apollo/client';

export default gql`
  query GetTranslations($filters: [TranslationFilterType!]) {
    globalTranslations(filters: $filters, first: 25) {
      edges {
        node {
          id
          key
          value
        }
      }
    }
  }
`;
