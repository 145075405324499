import { types } from '@guuru/chat-web';

export const chatInitAction = (
  partnerId,
  chatId,
  chatControlToken,
  navigate,
  location,
  options,
) => ({
  type: types.CHAT_INIT,
  partnerId,
  chatId,
  chatControlToken,
  navigate,
  location,
  options,
});

export const chatNewText = (partnerId, chatId, {
  text,
  step,
  inputType,
  textType,
  channel = 'chat',
  skipAutomation = false,
}) => ({
  partnerId,
  chatId,
  text,
  step,
  inputType,
  textType,
  channel,
  skipAutomation,
  type: types.CHAT_NEW_TEXT,
});

export const chatNewQuestion = (partnerId, navigate, location, options) => ({
  type: types.CHAT_NEW_QUESTION,
  partnerId,
  navigate,
  location,
  options,
});

export const chatConnectionError = (partnerId) => ({
  type: types.CHAT_FLOW_CONNECTION_ERROR,
  partnerId,
});

export const chatNewQuickQuestion = ({ text, step }) => ({
  text,
  step,
  skipAutomation: true,
  type: types.CHAT_NEW_TEXT,
});
