import moment from 'moment';
import ms from 'ms';

const MONTH_NAMES = moment.months();
const [sun, ...monsat] = moment.weekdaysShort().map((d) => d.toLowerCase());
const days = [...monsat, sun];

const HELPER = {

  getTimeStopSinceTimestamp: (timestamp) => {
    const hoursSince = moment().diff(timestamp, 'hours');
    const minutesSince = moment().diff(timestamp, 'minutes');
    const secondsSince = moment().diff(timestamp, 'seconds');

    return {
      hours: hoursSince,
      minutes: minutesSince,
      seconds: secondsSince,
      timeStop: ms(Date.now() - timestamp),
      // timeStop: duration.format('hh:mm:ss'),
    };
  },

  getMonthLabel: (month) => MONTH_NAMES[month - 1],

  zeroPad: (num) => (`00${num}`).substr(-2, 2),

  clip: (num, min, max) => Math.max(min, Math.min(num, max)),

  getMinute: (num) => HELPER.clip(num % 100, 0, 60),

  getHour: (num) => HELPER.clip(Math.floor(num / 100), 0, 24),

  millesecondsSince: (timestamp) => moment().valueOf() - timestamp,

  prettyPrint: (hour) => (
    `${HELPER.zeroPad(HELPER.getHour(hour))}:${HELPER.zeroPad(HELPER.getMinute(hour))}`
  ),

  prettyPrintMinutes: (minutes) => {
    const fullHours = HELPER.zeroPad(Math.floor(minutes / 60));
    const partialHours = HELPER.zeroPad(minutes % 60);
    const hour = `${fullHours}${partialHours}`;
    return HELPER.prettyPrint(hour);
  },

  days,

  MONTH_NAMES,

  formatSeconds: (responseTime = 0) => {
    if (responseTime < 100) {
      return `${responseTime.toFixed(0)}s`;
    }
    return moment()
      .startOf('day')
      .seconds(responseTime.toFixed(0))
      .format('m[m]ss');
  },
};

export default HELPER;
