import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import ChatRenderText from '../Text';
import Option from '../../Option';

const ChatPredictOptions = function ({
  message,
  showOptions = false,
  onSelect,
}) {
  const {
    metadata: {
      subscriptionOptions,
    } = {},
  } = message;
  return (
    <Row style={{ width: '100%' }}>
      <div className="chat-message-wrapper">
        <ChatRenderText message={message} tooltip={false}>
          {showOptions && (
            subscriptionOptions.map(({ name, option }) => (
              <Option key={option} id={option} name={name} onClick={onSelect} />
            ))
          )}
        </ChatRenderText>
      </div>
    </Row>
  );
};

ChatPredictOptions.propTypes = {
  message: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
  showOptions: PropTypes.bool,
};

export default ChatPredictOptions;
