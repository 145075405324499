import React from 'react';
import { Button, Result } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import ImageSvg from './Error_403.svg';

const Forbidden = function () {
  return (
    <Result
      icon={(
        <Icon component={ImageSvg} style={{ fontSize: '300px' }} />
      )}
      title="Oops"
      subTitle="Access Forbidden"
      extra={(
        <Button onClick={() => window.history.back()} type="primary">
          Back to home
        </Button>
    )}
    />
  );
};

export default Forbidden;
