import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { flow } from '@guuru/chat-web';
import { AutoComplete, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import InnerLeftButtons from './InnerLeftButtons';
import ChatMessageSubmitButton from './ChatMessageSubmitButton';
import LeftIcon from './LeftIcon';

const mapResponsesToOptions = (responses) => (
  responses.map(({ command, response }) => ({
    value: response,
    label: (
      <div>
        <strong>/{command}</strong>
        <br />
        {response}
      </div>
    ),
  })));

const TextInput = function ({
  chatId = null,
  isVisible = false,
  isEnabled,
  isValid,
  isUser,
  text,
  noFocus = false,
  partnerId = '',
  onAttachmentSubmit,
  hasTextAction = null,
  inputType = '',
  cannedResponses = [],
  inputState = '',
  handleInputFocus = () => {},
  handleInput,
  submit,
  setText,
}) {
  const inputRef = useRef();
  const { t } = useTranslation();

  const handleSubmitClick = useCallback(() => {
    submit('Submit Button press', text);
  }, [submit, text]);

  useEffect(() => {
    const action = noFocus ? inputRef.current?.blur : inputRef.current?.focus;
    action?.();
  }, [chatId, noFocus]);

  if (!isVisible) return null;

  const handleSubmitSelect = (txt) => {
    setText(txt);
    submit('Submit Canned response', text);
  };

  const handlePressEnter = (event) => {
    event.preventDefault();
    submit('Enter press', text);
  };

  /** Handler for input text change */
  const handleChange = (newText) => {
    handleInput(newText, true);
  };

  const handleChangeEvent = (event) => {
    const { value } = event.target;
    return handleInput(value, true);
  };

  const getPlaceholder = () => {
    switch (inputType) {
      case flow.INPUT_TYPE_QUESTION:
        return t('chat:::placeholderQuestion');
      case flow.INPUT_TYPE_NAME:
        return t('chat:::placeholderName');
      case flow.INPUT_TYPE_EMAIL:
        return t('chat:::placeholderEmail');
      case flow.INPUT_TYPE_CHAT:
        return t('chat:::placeholderChat');
      default:
        return !isUser ? t('chat:::placeholderChat') : undefined;
    }
  };

  const getInput = () => {
    switch (inputType) {
      case flow.INPUT_TYPE_EMAIL:
        return (
          <Input
            autoFocus
            className="chat-input"
            placeholder={getPlaceholder()}
            value={text}
            onFocus={handleInputFocus}
            onPressEnter={handlePressEnter}
            onChange={handleChangeEvent}
            disabled={!!inputState}
            type="email"
            style={{ fontFamily: 'inherit' }}
          />
        );
      default:
        return (
          <>
            <InnerLeftButtons
              visible={inputType === flow.INPUT_TYPE_CHAT}
              handleInput={handleInput}
              text={text}
              inputRef={inputRef.current}
              partnerId={partnerId}
              hasTextAction={hasTextAction}
              onAttachmentSubmit={onAttachmentSubmit}
            />
            <AutoComplete
              autoFocus
              defaultActiveFirstOption
              className="chat-input"
              options={mapResponsesToOptions(cannedResponses)}
              onChange={handleChange}
              filterOption={false}
              onSelect={handleSubmitSelect}
              value={text}
              onFocus={handleInputFocus}
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 6 }}
                className="chat-input"
                disabled={!!inputState}
                onPressEnter={handlePressEnter}
                placeholder={getPlaceholder()}
                style={{ fontFamily: 'inherit' }}
                type="text"
                ref={inputRef}
              />
            </AutoComplete>
          </>
        );
    }
  };

  return (
    <>
      <LeftIcon inputType={inputType} />
      {getInput()}
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className="chat-input-actions"
      >
        <ChatMessageSubmitButton
          isEnabled={isEnabled}
          isValid={isValid}
          onClick={handleSubmitClick}
        />
      </div>
    </>
  );
};

TextInput.propTypes = {
  chatId: PropTypes.string,
  isVisible: PropTypes.bool,
  isEnabled: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isUser: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  noFocus: PropTypes.bool,
  partnerId: PropTypes.string,
  onAttachmentSubmit: PropTypes.func.isRequired,
  hasTextAction: PropTypes.func,
  inputType: PropTypes.string,
  cannedResponses: PropTypes.arrayOf(
    PropTypes.shape({
      response: PropTypes.string,
      command: PropTypes.string,
    }),
  ),
  inputState: PropTypes.string,
  handleInputFocus: PropTypes.func,
  handleInput: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  setText: PropTypes.func.isRequired,
};

export default TextInput;
