import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChatAttachButton from './ChatAttachButton';
import ChatEmojiButton from './ChatEmojiButton';

const InnerLeftButtons = function ({
  visible,
  text,
  inputRef = {},
  partnerId,
  handleInput,
  hasTextAction: HasTextAction = null,
  onAttachmentSubmit,
}) {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  if (!visible) return null;

  if (text.length > 20 && HasTextAction) {
    return <HasTextAction text={text} partnerId={partnerId} />;
  }

  const handleEmoji = (emoji) => {
    // eslint-disable-next-line no-param-reassign
    inputRef.value = text;
    const { textArea } = inputRef.resizableTextArea;
    const cursorPosition = textArea.selectionEnd;
    const start = inputRef.value.substring(0, textArea.selectionStart);
    const end = inputRef.value.substring(textArea.selectionStart);
    const newText = start + emoji + end;
    handleInput(newText, true);
    textArea.focus();
    textArea.selectionEnd = cursorPosition + emoji.length;
    setIsEmojiPickerOpen(false);
  };

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  return (
    <>
      <ChatAttachButton onAttachmentSubmit={onAttachmentSubmit} />
      <ChatEmojiButton
        onEmojiSelect={handleEmoji}
        onToggle={toggleEmojiPicker}
        opened={isEmojiPickerOpen}
      />
    </>
  );
};

InnerLeftButtons.propTypes = {
  visible: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  hasTextAction: PropTypes.func,
  onAttachmentSubmit: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
};

export default InnerLeftButtons;
