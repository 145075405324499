import React from 'react';
import { Button, Flex, Grid } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import ExpertsList from './ExpertsList';

const { useBreakpoint } = Grid;

const HomeExperts = function () {
  const { search } = useLocation();
  const { xs } = useBreakpoint();
  const { partnerId } = useParams();

  return (
    <>
      <ExpertsList
        first={xs ? 3 : 4}
        url={`/${partnerId}/guurus/`}
      />
      <Flex justify="center">
        <Link to={`/${partnerId}/guurus${search}`}>
          <Button type="primary" size="large" shape="round">
            View all members
          </Button>
        </Link>
      </Flex>
    </>
  );
};

export default HomeExperts;
