import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import { i18n } from '@guuru/translation-web';
import { delay as sleep } from '@guuru/helpers-common';
import { messageTypes } from '@guuru/constants-common';

const { MESSAGE_TYPE_VISITOR_ROLE } = messageTypes;
const requestVisitorRole = 'bot:::requestVisitorRole';

const addBotRequestVisitorRole = (
  question,
  roles,
  delay = true,
) => (
  sleep(delay ? ChatAPI.getBotEllipsisMessageDelay() : 0).then(() => {
    const message = ChatAPI.messages.createBotMessage(
      new Date().getTime(),
      question,
      MESSAGE_TYPE_VISITOR_ROLE,
      { roles },
      true,
      false,
    );
    if (Object.keys(roles).length === 0 && roles.constructor === Object) {
      ChatAPI.pushPendingMessages(message);
    }
    return message;
  })
);

const addVisitorRole = (partnerId, option) => {
  const message = ChatAPI.messages.createAnonymousTextMessage(
    option.displayName,
    true,
    false,
  );
  ChatAPI.pushPendingMessages(message);
  ChatAPI.storeVisitorRole(partnerId, option.uid);
  return message;
};

const prepareMessage = (partnerName) => {
  const requestMessage = i18n.t(requestVisitorRole, { partnerName });
  const leadRoleOption = i18n.t('bot:::leadRole');
  const userRoleOption = i18n.t('bot:::userRole');
  const message = {
    displayName: requestMessage,
    options: [
      {
        uid: 'lead',
        displayName: leadRoleOption,
        question: requestMessage,
      },
      {
        uid: 'user',
        displayName: userRoleOption,
        question: requestMessage,
      },
    ],
  };
  return message;
};

export default {
  validate(partnerId) {
    return !ChatAPI.storeRetrieveVisitorRole(partnerId);
  },
  * render(partnerId) {
    const { name } = yield ChatAPI.getChatSettings(partnerId);
    const message = prepareMessage(name);
    const requestVisitorRoleMessage = yield addBotRequestVisitorRole(
      message.displayName,
      message.options,
    );
    yield put({
      type: types.CHAT_NEW_TEXT_SUCCESS,
      message: requestVisitorRoleMessage,
    });
  },

  * processResponse(partnerId, option) {
    const message = yield addBotRequestVisitorRole(option.question, {}, false);
    yield put({
      type: types.CHAT_NEW_TEXT_REPLACE_LAST,
      message,
    });

    const responseMessage = yield addVisitorRole(partnerId, option);
    yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message: responseMessage });
  },
};
