import React from 'react';
import { bool, string } from 'prop-types';
import {
  BotAvatar,
  ExpertAvatar,
  HeroesAvatar,
} from '@guuru/react-chat-avatar';
import { Profile, ProfileSection } from './style';

const ChatAvatar = function ({
  url = null,
  isExpert,
  isBot,
  showProfileImage,
  customBotAvatar = null,
  isHeroesFormat = false,
  isHidden,
}) {
  if (isHidden) {
    return null;
  }

  return (
    <ProfileSection>
      <Profile $isHeroesFormat={isHeroesFormat}>
        {showProfileImage && (
          (isHeroesFormat && <HeroesAvatar />)
          || (isExpert && <ExpertAvatar url={url} />)
          || (isBot && <BotAvatar customBotAvatar={customBotAvatar} />)
        )}
      </Profile>
    </ProfileSection>
  );
};

ChatAvatar.propTypes = {
  url: string,
  isExpert: bool.isRequired,
  isBot: bool.isRequired,
  isHidden: bool.isRequired,
  showProfileImage: bool.isRequired,
  customBotAvatar: string,
  isHeroesFormat: bool,
};

export default ChatAvatar;
