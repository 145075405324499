import styled from 'styled-components';
import { Row } from 'antd';

export const Rating = styled(Row)`
  @keyframes show {
    from {
      transform: translateY(99%);
      opacity: 0.5;
    }
  }

  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.secondaryColor};
  border-top: ${({ theme }) => `2px solid ${theme.secondaryColor}`};
  width: 100%;
  height: 200px;
  animation: show 0.5s ease-in-out;

  .ant-col {
    padding: 0 8px;
  }

  .ant-row {
    width: 100%;
  }

  .ant-space,
  .anticon {
    font-size: 1.25rem;
    padding: 0.15rem 0;
  }
`;

export const RatingCta = styled(Row)`
  position: relative;
  bottom: 150px;
  height: 0;
  z-index: 2;
`;
