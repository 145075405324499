import * as Sentry from '@sentry/react';
import generateRandomId from './src/random-id';

const initSentry = function (dsn, {
  sampleRate = 0.25,
  tracesSampleRate = 0.05,
  ignoreErrors = [],
} = {}) {
  Sentry.init({
    dsn: `https://${dsn}`,
    environment: process.env.NODE_ENV,
    release: process.env.RELEASE_NAME,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [process.env.CORE_ENDPOINT.split('/').pop()],
    sampleRate,
    tracesSampleRate,
    ignoreErrors: [
      'ChunkLoadError',
      /AbortError/,
      /NS_ERROR_/,
      /ResizeObserver/,
      /TypeError: r is null/,
      /QuotaExceededError/,
      /The project's quota for this operation has been exceeded/,
      /Quota exceeded for quota/,
      /database connection is closing/,
      /closed database/,
      /IDBDatabase/,
      /Indexed Database/,
      /timeout, interrupted connection or unreachable host/,
      /Network\s?[Ee]rror/,
      /Non-Error promise rejection captured/,
      /InvalidStateError: Failed to start the audio device/,
      /Firebase: Error \(auth\/network-request-failed\)/,
      /Firebase: Error \(auth\/internal-error\)/,
      ...ignoreErrors,
    ],
  });
};

export { Sentry, initSentry, generateRandomId };
