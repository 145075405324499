import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';

const ConfirmedIcon = styled(CheckCircleOutlined)`
  color: ${(props) => (props.$isTouched ? '#faad14' : '#0b8235')};
`;

const StatusIcon = function ({
  icon: Icon,
  isConfirmed = false,
  isTouched = false,
}) {
  if (isConfirmed || isTouched) {
    return <ConfirmedIcon $isTouched={isTouched} />;
  }

  return <Icon />;
};

StatusIcon.propTypes = {
  icon: PropTypes.shape({}).isRequired,
  isTouched: PropTypes.bool,
  isConfirmed: PropTypes.bool,
};

export default styled(StatusIcon)`
  vertical-align: 0;
`;
