import React, { Children, Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, Typography, Space, Spin,
} from 'antd';

const { Text, Title } = Typography;

const CollapseForm = function ({
  children,
  bordered = false,
  defaultActiveKey = undefined,
  ...colapseProps
}) {
  const childArray = Children
    .toArray(children)
    .filter(({ props: { visible = true } = {} } = {}) => visible);

  return (
    <Collapse
      style={{ borderRadius: '0 0 4px 4px' }}
      bordered={bordered}
      {...colapseProps}
      expandIconPosition="end"
      defaultActiveKey={defaultActiveKey}
    >
      {childArray.filter((child) => child).map((child, key) => {
        const {
          props: {
            title, description, extra, disabled, collapseStyle,
          },
        } = child;
        return (
          <Collapse.Panel
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            header={(
              <Space direction="vertical" style={{ width: '80%' }}>
                <Title level={5} style={{ marginBottom: 0 }}>{title}</Title>
                <Text type="secondary" style={{ fontSize: 12 }}>
                  {description}
                </Text>
              </Space>
            )}
            extra={extra}
            collapsible={disabled}
            style={collapseStyle}
          >
            <Suspense fallback={<Spin />}>
              {child}
            </Suspense>
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

CollapseForm.propTypes = {
  defaultActiveKey: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  bordered: PropTypes.bool,
};

export default CollapseForm;
