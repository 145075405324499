const hex2rgb = function (hex) {
  if (hex?.startsWith('#')) {
    return hex2rgb(hex.substring(1));
  }

  if (hex?.length === 3) {
    return hex2rgb(hex.split('').map((c) => c + c).join(''));
  }

  if (hex?.length !== 6) {
    return '0,176,255';
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r},${g},${b}`;
};

export default hex2rgb;
