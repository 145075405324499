import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  flow as flowConstants,
  useChatMessagesSender,
  useIsVisible,
  useSoundEffect,
} from '@guuru/chat-web';
import { useDropzone } from 'react-dropzone';
import { useAttachmentSubmit } from '@guuru/react-upload-hooks';
import { ChatMessages, ChatRowWrapper } from '@guuru/react-chat-messages';
import { destroy, loading, warning } from '@guuru/react-message';
import ms from 'ms';
import { ChatState } from '@guuru/constants-common';
import ChatDropActive from './ChatDropActive';
import ChatHeader from './ChatHeader';
import RateSection from './ChatRateSection';
import ChatThankYouSection from './ChatThankYouSection';
import TermsAndConditions from './ChatTermsAndConditions';
import { ChatScrollAnchor, ChatView } from './style';
import ChatNewMessagesCta from './ChatNewMessagesCta';
import ChatRateCta from './ChatRateCta';
import ChatHeaderWrapper from './ChatHeaderWrapper';
import useScrollToBottom from './hooks/useScrollToBottom';
import useChatMessages from './hooks/useChatMessages';
import useCanRate from './hooks/useCanRate';

const ChatWidget = function ({
  showTermsAndConditions = false,
  children,
  isUser = false,
  chatId = null,
  reduxMessages = [],
  flow = {},
  showBotAvatar = true,
  customBotAvatar = null,
  baseMods = 'mod--chat',
  renderChatMessageBar,
  renderChatHeader = undefined,
  dispatch = () => {},
  alert = null,
}) {
  const {
    status,
    expert,
    partner,
    messages,
    acceptedAt,
    rating,
    unreadMessagesCount,
    markMessagesRead,
    incrementUnreadMessages,
  } = useChatMessages({
    chatId,
    isUser,
    reduxMessages,
  });
  const { sendAttachment } = useChatMessagesSender({ chatId, isUser });

  const {
    scrollToBottom,
    isNearBottom,
    setContainerRef,
    setScrollAnchorRef,
  } = useScrollToBottom();

  const isVisible = useIsVisible();
  const isLastMessageHidden = !isVisible || !isNearBottom;
  useSoundEffect(
    { when: unreadMessagesCount > 0 && isLastMessageHidden },
    [isLastMessageHidden, unreadMessagesCount],
  );

  useEffect(() => {
    if (isNearBottom && unreadMessagesCount > 0 && isVisible) {
      markMessagesRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNearBottom, unreadMessagesCount, isVisible]);

  const handleAttachmentSubmit = useAttachmentSubmit({
    chatId,
    onStart: scrollToBottom,
    onUpload: sendAttachment,
    onProgress: loading,
    onError: warning,
    onFinish: destroy,
  });

  const canRateData = {
    messages,
    acceptedAt,
    expert,
    isUser,
    isOpen: flow.step === flowConstants.STEP_CHAT
      && flow.inputType !== flowConstants.INPUT_TYPE_NONE,
  };

  const canRateHeader = useCanRate({
    ...canRateData,
    promptTimer: ms('1.5m'),
    afterUserReplied: true,
  });

  const canRateBottom = useCanRate({
    ...canRateData,
    promptTimer: ms('4m'),
    totalExpertMessages: 3,
    totalExpertCharacters: 250,
  });

  const onDrop = useCallback(([file]) => {
    handleAttachmentSubmit({ target: { files: [file] } });
  }, [handleAttachmentSubmit]);

  const mods = useMemo(() => (
    isUser ? `${baseMods} mod--user` : `${baseMods} mod--expert`
  ), [baseMods, isUser]);

  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    multiple: false,
    className: 'dropzone',
    disabled: !['created', 'assigned', 'open'].includes(status),
    onDrop,
  });

  return (
    <ChatView className={mods} {...getRootProps()}>
      <ChatHeaderWrapper
        isUser={isUser}
        header={renderChatHeader
          ? renderChatHeader()
          : <ChatHeader chatId={chatId} showRate={canRateHeader} />}
      />
      <ChatDropActive isDragActive={isDragActive} />
      <ChatRowWrapper>
        {alert}
      </ChatRowWrapper>
      <div ref={setContainerRef} className={`chat-content-section ${mods}`}>
        <TermsAndConditions visible={showTermsAndConditions} />
        <ChatMessages
          chatId={chatId}
          isUser={isUser}
          messages={messages}
          showBotAvatar={showBotAvatar}
          customBotAvatar={customBotAvatar}
          mods={mods}
          partner={partner}
          dispatch={dispatch}
        />
        <RateSection
          isUser={isUser}
          flowStep={flow.step}
          doBefore={() => incrementUnreadMessages()}
        />
        <ChatThankYouSection
          isUser={isUser}
          flowStep={flow.step}
          chatId={chatId}
          expert={expert}
          doBefore={() => incrementUnreadMessages()}
        />
        <ChatScrollAnchor
          id="chat-scroll-anchor"
          ref={setScrollAnchorRef}
          $setBackgroundColor={status === ChatState.closed}
        />
      </div>
      {!isNearBottom && unreadMessagesCount > 0 && (
        <ChatNewMessagesCta
          count={unreadMessagesCount}
          onClick={scrollToBottom}
        />
      )}

      {children}
      {canRateBottom && isNearBottom && partner?.id && (
        <ChatRateCta
          partner={partner}
          chatId={chatId}
          rating={rating}
          expert={expert}
        />
      )}
      {renderChatMessageBar({
        onAttachmentSubmit: handleAttachmentSubmit,
        onMessageSubmit: scrollToBottom,
      })}
    </ChatView>
  );
};

ChatWidget.propTypes = {
  showTermsAndConditions: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isUser: PropTypes.bool,
  isChatFlow: PropTypes.bool,
  chatId: PropTypes.string,
  reduxMessages: PropTypes.arrayOf(PropTypes.shape({})),
  flow: PropTypes.shape({
    step: PropTypes.string,
    inputType: PropTypes.string,
  }),
  showBotAvatar: PropTypes.bool,
  customBotAvatar: PropTypes.string,
  baseMods: PropTypes.string,
  renderChatMessageBar: PropTypes.func.isRequired,
  renderChatHeader: PropTypes.func,
  dispatch: PropTypes.func,
  alert: PropTypes.node,
};

export default ChatWidget;
