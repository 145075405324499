import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import SalesforceSvg from './SalesforceOutlined.svg';

const SalesforceOutlined = function (props) {
  return (
    <Icon component={SalesforceSvg} {...props} />
  );
};

export default SalesforceOutlined;
